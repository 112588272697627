<template>
  <app-layout>
    <Loading v-if="loadingActive" />
    <InfoDialog
      v-if="infoModalActive"
      :modal-message="modalMessage"
      v-on:close-modal="closeModal" />

    <div class="col-12 col-lg-6 mt-5">
      <div class="d-flex align-items-center rounded mt-2">
        <button
          class="btn fs-4 me-2"
          style="color: var(--primary)"
          @click="$router.back()">
          <i class="fas fa-chevron-left"> </i>
          <span class="ms-2">{{ $t("message.creat_new_table") }}</span>
        </button>
      </div>
      <form
        class="form"
        @submit.prevent="upload">
        <div class="row pt-5">
          <div class="col-12 col-lg-6 col-md-6">
            <div class="form-floating">
              <input
                type="text"
                required
                class="form-control"
                v-model="table.name"
                id="name-input"
                placeholder="enter shop name" />
              <label for="name-input">Name</label>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="form-floating">
              <select
                id="cate"
                class="form-select fs-6 rounded"
                style="
                  border-radius: var(--border-radius) !important;
                "
                v-model="table.category"
                aria-label=".form-select-sm example">
                <option
                  disabled>
                  Choose category
                </option>
                <option value="All">All</option>
                <option
                  :value="type.name"
                  v-for="type in tableCategories"
                  :key="type.id">
                  {{ type.name }}
                </option>
              </select>
              <label for="cate">Category</label>
            </div>
          </div>

          <div
            class="position-sticky shadow-sm bottom-0 mt-5"
            style="background-color: var(--background-color)">
            <button
              type="submit"
              class="btn w-100 my-2 text-white mx-auto"
              style="background-color: var(--primary)">
              {{ $t("message.confirm") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </app-layout>
</template>

<script>
  import db from "@/config/FirebaseInit";
  import Loading from "@/components/Loading";
  import InfoDialog from "@/components/InfoDialog";
  import AppLayout from "../../../components/AppLayout.vue";
  export default {
    components: { InfoDialog, Loading, AppLayout },
    data() {
      return {
        infoModalActive: false,
        modalMessage: "",
        confirmActive: false,
        loadingActive: false,
        table: {
          category: "",
          categoryType: "Free",
          categoryTypeId: "",
          id: "",
          name: "",
          startTime: "",
        },
      };
    },
    computed: {
      tableCategories() {
        return this.$store.getters.getTableCategories;
      },
      owner() {
        return this.$store.getters.getShopOwner;
      },
      currentDateTime() {
        const date = new Date();
        let time = new Intl.DateTimeFormat("en-US", {
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        }).format(date);
        return time;
      },
      shopInfo() {
        return this.$store.getters.getShopInfo;
      },
    },
    async mounted() {
      this.table.category = this.$route.params.category;
      this.$store.dispatch("getDateTime");
      this.$store.dispatch("getDay");
      this.$store.dispatch("getMonth");
      this.$store.dispatch("getYear");
      this.$store.dispatch("getShopInfo", this.owner);
    },
    methods: {
      closeModal() {
        this.infoModalActive = !this.infoModalActive;
        this.$router.back();
      },
      cancel() {
        this.confirmActive = !this.confirmActive;
      },
      confirm() {
        this.confirmActive = !this.confirmActive;
      },

      upload() {
        const tableRef = db
          .collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc();
        this.table.id = tableRef.id;
        tableRef.set(this.table);
        this.infoModalActive = true;
        this.modalMessage = "Created Successfully";
        setTimeout(()=>{
          this.$router.back();
        },30000)
      },
    },
  };
</script>
<style scoped>
  @media only screen and (max-width: 1030px) {
    .content-box {
      margin-top: 50px;
    }
  }
</style>
