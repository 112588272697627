import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/messaging";
import "firebase/compat/storage";
//import { enableIndexedDbPersistence } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDKQIHwerypNjkvJWjYF96O8jWZgOSri6c",
    authDomain: "flash-mall-ed921.firebaseapp.com",
    projectId: "flash-mall-ed921",
    storageBucket: "flash-mall-ed921.appspot.com",
    messagingSenderId: "833764220183",
    appId: "1:833764220183:web:509b8768f6c97733932b52",
    measurementId: "G-7SH7VFGLPM"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const messaging = firebase.messaging();
const db = firebase.firestore();
db.settings({
    cacheSizeBytes:firebase.firestore.CACHE_SIZE_UNLIMITED
})
db.enablePersistence().catch((err)=>{
    if (err.code == 'failed-precondition') {
        console.log('failed')
    } else if (err.code == 'unimplemented'){
        console.log('unimplemented')
    }
})

const storage = firebase.storage();
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
// enableIndexedDbPersistence(db)
//     .catch((err) => {
//         if (err.code == 'failed-precondition') {
//             console.log('failed')
//         } else if (err.code == 'unimplemented'){
//             console.log('unimplemented')
//         }
//});
export {timestamp, firebase, storage, messaging};
export  default db;