<template>
  <div>
    <Loading v-if="loadingActive"/>
    <InfoDialog v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
    <confirm-dialog :modal-message="modalMessage" v-if="confirmActive" v-on:confirm="confirm" v-on:cancel="cancel"/>

    <div class="container-fluid" style="background-color: var(--background-color);">
      <div class="row">
        <div class="col-lg-2 p-0"><AdminSidebar/></div>
        <div class="col-12 col-lg-10 mt-5">
          <div class="row">

            <div class="modal fade" style="z-index: 10000;" id="liveSessionModal"  tabindex="-1" aria-labelledby="liveSessionModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="liveSessionModalLabel">Create Live Session</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="enableLiveSession" autocomplete="off">
                      <label>Enter Live Session Name</label>
                      <input type="text" class="form-control" v-model="liveSession.name" placeholder="name">
                      <label class="mt-3">Facebook Live url (optional)</label>
                      <input type="text" class="form-control" v-model="liveSession.fb_url" placeholder="url">
                      <div class="d-none">
                        <label>Start</label>
                        <input type="time" class="form-control" v-model="liveSession.startTime" >
                        <label>End</label>
                        <input type="time" class="form-control" v-model="liveSession.endTime">
                      </div>
                      <button type="submit" class="btn btn-primary float-end my-3" data-bs-dismiss="modal">Confirm</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>


            <div class="modal fade" style="z-index: 10000;" id="liveSessionModal"  tabindex="-1" aria-labelledby="liveSessionModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="liveSessionModalLabel">Create Live Session</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="enableLiveSession" autocomplete="off">
                      <label>Enter Live Session Name</label>
                      <input type="text" class="form-control" v-model="liveSession.name" placeholder="name">
                      <label class="mt-3">Facebook Live url (optional)</label>
                      <input type="text" class="form-control" v-model="liveSession.fb_url" placeholder="url">
                      <div class="d-none">
                        <label>Start</label>
                        <input type="time" class="form-control" v-model="liveSession.startTime" >
                        <label>End</label>
                        <input type="time" class="form-control" v-model="liveSession.endTime">
                      </div>
                      <button type="submit" class="btn btn-primary float-end my-3" data-bs-dismiss="modal">Confirm</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="d-flex px-2 rounded align-items-center">
                <div class="d-flex justify-content-between rounded w-100 align-items-center p-1" style="color: var(--text-color);">
                  <div class="d-flex align-items-center">
                    <btn @click="$router.back()" class="btn btn--white btn-sm me-2" style="color: var(--text-color);"><i class="fas fa-chevron-left"> </i></btn>
                    <span class="fs-5 me-2">{{selectedSession?selectedSession.name : 'Live Histories'}}</span>
                    <div>
                      <span class="badge bg-danger rounded-pill" v-if="selectedSession.status==='start'"><i class="fas fa-podcast"></i> Streaming</span>
                      <span class="badge bg-warning rounded-pill" v-if="selectedSession.status==='pending'">Pending</span>
                      <span class="badge bg-dark rounded-pill" v-if="selectedSession.status === 'ended'">Ended</span>
                    </div>

                  </div>
                  <div>
                    <button type="button" style="color: var(--text-color);background-color: var(--btnLight);" class="btn btn-sm mx-1 my-2" data-bs-toggle="modal" data-bs-target="#liveSessionModal">
                      <i class="fas fa-plus"></i> Create Session
                    </button>
                    <button class="btn btn-sm btn-danger" v-if="selectedSession.status === 'pending'" @click="deleteSession"><i class="fas fa-trash"></i> </button>

                  </div>
                 </div>
                <div class="session-pop-up shadow-sm p-2 rounded "  v-if="toggleClick" >
                  <button class="btn my-2" style="color: var(--text-color);" @click="this.toggleClick = !this.toggleClick"><i class="fas fa-times"></i> </button>
                  <li v-for="(session, index) in filterSessions" :key="index" class="justify-content-between my-1 list-unstyled rounded p-1 text-start" @click="showSessionProducts(session)">
                    <span class="text-truncate" style="width: 100px;">{{session.name}}</span>

                    <div class="d-flex justify-content-between align-items-center">
                      <span class="badge bg-danger rounded-pill" v-if="session.status==='start'">Streaming</span>
                      <span class="badge bg-dark rounded-pill" v-if="session.status==='ended'">Ended</span>
                      <span class="badge bg-warning rounded-pill" v-if="session.status==='pending'">Pending</span>
                      <span> <i class="fas fa-calendar ms-2"></i>{{session.date.toDate().toLocaleDateString()}}</span>
                    </div>

                  </li>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card-body table-responsive">


                <div class="d-flex justify-content-between align-items-center mb-1" >
                  <input type="text" v-model="search" @click="sessionInputToggle" placeholder="Choose Session" class="form-control form-select form-control-sm w-25">

                  <div v-if="selectedSession!==''" class=" fw-bold d-flex align-items-center" >
                    <div class="d-flex align-items-center" style="background-color: var(--light);border-radius: var(--border-radius);">


                      <div class="m-0 p-0">
                        <router-link :to="{name:'ProductListsForLive',params: {session_id:this.selectedSession.id}}" class="btn btn-sm ms-3 d-flex justify-content-center align-items-center" style="color: var(--text-color);background-color: var(--btnLight);"><i class="fas fa-plus-circle"></i>Add Products</router-link>
                      </div>
                      <button class="btn btn-primary btn-sm m-3" @click="startLive" v-if="selectedSession.status==='ended'" aria-label="end session" ><i class="fas fa-rotate"></i> Restart</button>
                      <button class="btn btn-success btn-sm m-3" @click="startLive" v-if="selectedSession.status==='pending'" aria-label="restart session"><i class="fas fa-circle-play"></i> Start</button>
                      <button class="btn btn-danger btn-sm m-3" @click="endLive" v-if="selectedSession.status==='start'" aria-label="stop session"><i class="fas fa-circle-stop"></i> Stop</button>
                    </div>

                  </div>
                  <div v-if="selectedSession!==''" class="d-flex flex-column p-1"  style="background-color: var(--light);border-radius: var(--border-radius);">
                    <label for="fb" class="form-label mb-1"><i class="fas fa-link"></i> Facebook Live Link</label>
                    <form @submit.prevent="updateFbUrl">
                      <div class="d-flex">
                        <input type="text" id="fb" required class="form-control form-control-sm" placeholder="facebook live url" v-model="selectedSession.fb_url">
                        <button class="btn btn-sm btn-warning ms-1">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
                <table class="table table-hover table-bordered">
                  <thead>
                  <tr>
                    <th class="text-nowrap">#</th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Photos</th>
                    <th class="text-nowrap">Sell Price</th>
                    <th class="text-nowrap">Stocks</th>
                    <th class="text-nowrap">Discount(%)</th>
                    <th class="text-nowrap">Control</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(product,index) in products" class="align-middle" :key="product.id">
                    <td class="text-nowrap">{{index+1}}</td>
                    <td class="text-nowrap">{{product.itemName}}</td>
                    <td class="text-nowrap">
                      <img :src="product.images[0]" class="rounded border border-light border-3 shadow-sm" style="width:50px; height:50px; object-fit: cover;" alt="">
                    </td>
                    <td class="text-nowrap">{{product.buyPrice}}</td>
                    <td class="text-nowrap">{{product.liveSaleCount}}</td>
                    <td class="text-nowrap">{{product.discount}}</td>
                    <td class="text-nowrap">
                      <router-link :to="{name:'ProductDetail', params:{product_id:product.id}}" class="btn btn-primary btn-sm mx-1"><i class="fas fa-info-circle"></i></router-link>
                      <button type="button" class="btn btn-warning btn-sm mx-1" @click="increaseStock(product)" data-bs-toggle="modal" data-bs-target="#liveCountModal">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn btn-sm btn-danger mx-1" @click="remove(product)"><i class="fas fa-trash"></i></button>
                      <!-- Button trigger modal -->
                      <div class="modal fade" style="z-index: 10000;" id="liveCountModal"  tabindex="-1" aria-labelledby="liveCountModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="liveCountModalLabel">{{selectedProduct.itemName}}, Stock: {{selectedProduct.stock}}</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <label class="form-label">Count</label>
                              <input type="text" class="form-control" v-model="selectedProduct.liveSaleCount" placeholder="Enter count">
                              <label class="form-label mt-3">Discount (%)</label>
                              <input type="text" class="form-control " v-model="selectedProduct.discount" placeholder="Enter discount (optional)">
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="save">Save changes</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db, {timestamp} from "@/config/FirebaseInit";
import Loading from "@/components/Loading";
import InfoDialog from "@/components/InfoDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import AdminSidebar from "@/components/AdminSidebar";
export default {
 // props:['session_id'],
  components: {ConfirmDialog, InfoDialog, Loading, AdminSidebar},
  data(){
    return{
      search:'',
      selectedProduct:'',
      removeProduct:'',
      selectedSession:'',
      toggleClick:false,
      liveSessionData:[],
      products:[],
      checkedLiveSession:false,

      confirmActive:false,
      modalActive:false,
      confirmStatus:'',
      modalMessage:'',
      loadingActive:false,


      liveSession: {
        name:'',
        url:'default',
        fb_url:'',
        startTime: '',
        endTime: '',
        status: 'pending',
        date:'',
      },
    }
  },
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },
    filterSessions(){
      if(this.search!==""){
        return this.liveSessionData.filter((session)=>{
          return session.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase());
        });

      }else {
        return this.liveSessionData;
      }
    },
  },
  async mounted() {
    this.$store.dispatch('getCurrentShop');
    await db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection('liveSession')
        .orderBy("date","desc")
        .onSnapshot((snapshot) => {
          this.liveSessionData = [];
          snapshot.forEach(doc=>{
            this.liveSessionData.push(doc.data());
          });
        });

    if(this.$route.params.session_id!==''){
      await db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection('liveSession')
          .doc(this.$route.params.session_id)
          .onSnapshot((snapshot) => {
            this.selectedSession = snapshot.data();
            db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.$route.params.session_id).collection('liveProducts').onSnapshot(snapshot => {
              this.products = [];
              snapshot.forEach(doc=>{
                this.products.push(doc.data())
              })
            })
          });

    }
    /*await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSaleProducts').onSnapshot(snapshot => {
      snapshot.forEach(doc=>{
        if(!this.products.some((result)=>result.id === doc.id)) {
          this.products.push(doc.data())
        }
      })

    })*/
   /* await db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection('liveSession')
        .onSnapshot((snapshot) => {
          snapshot.forEach(doc=>{
            this.liveSessionData.push(doc.data());
          });
          /!*db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.liveSessionData[0].id).collection('liveProducts').onSnapshot(snapshot => {
            this.products = [];
            snapshot.forEach(doc=>{
              this.products.push(doc.data())
            })
          })*!/

        });*/
  },
  methods:{
    deleteSession(){
      this.loadingActive = true
      db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).delete().then(()=>{
        this.selectedSession = '';
        this.loadingActive = false;
        this.modalActive =true;
        this.modalMessage = 'Deleted Successfully';
        this.$router.push({name:'LiveHistory', params:{session_id:''}});
      })

    },

    async updateFbUrl(){
      if(this.selectedSession.fb_url!==''){
        this.loadingActive = true;
        await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).update({fb_url: this.selectedSession.fb_url}).then(()=>{
          console.log('update ok')
          this.loadingActive = false;
        })
      }
    },
    remove(product){
      this.removeProduct = product;
      this.confirmActive = true;
      this.confirmStatus = 'delete';
      this.modalMessage="Are you sure to delete?"
    },
    async save(){
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).collection('liveProducts').doc(this.selectedProduct.id).update({
        'liveSaleCount':this.selectedProduct.liveSaleCount,
        'discount':this.selectedProduct.discount
      }).then(()=>{
        // console.log('count'+this.selectedProduct.liveSaleCount,'discount'+this.selectedProduct.discount);
      });
    },
    increaseStock(product){
      this.selectedProduct = product;
    },
    async sessionInputToggle(){
      this.toggleClick = !this.toggleClick;
    },
    async enableLiveSession(){
      if(this.liveSession.name !== ''){
        this.loadingActive = true;
        const liveSessionRef = await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc();
        this.liveSession.id = liveSessionRef.id;
        this.liveSession.date = timestamp;
        this.selectedSession = this.liveSession;
        console.log(this.selectedSession)
        await liveSessionRef.set(this.liveSession).then(()=>{
          this.getSessionProducts();
          this.liveSession.name ='';
          this.liveSession.fb_url='';
          this.loadingActive = false;
          this.modalActive = true;
          this.modalMessage = 'Live Session success';
        });
        this.$router.push({name:"LiveHistory", params:{session_id:this.liveSession.id}})

      }

    },
    getSessionProducts(){
      this.$router.push({name:'LiveHistory',params:{session_id:this.selectedSession.id}})
      db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).collection('liveProducts').onSnapshot(snapshot => {
        this.products = [];
        snapshot.forEach(doc=>{
          this.products.push(doc.data())
        })
      })
    },

    showSessionProducts(session){
      this.selectedSession = session;
      this.toggleClick = !this.toggleClick;
      this.search='';
      this.getSessionProducts();
      /*this.$router.push({name:'LiveHistory',params:{session_id:session.id}})
      db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(session.id).collection('liveProducts').onSnapshot(snapshot => {
        this.products = [];
        snapshot.forEach(doc=>{
          this.products.push(doc.data())
        })
      })*/
    },

    closeModal(){
      this.modalActive = !this.modalActive;
    },
    cancel(){
      this.confirmActive = !this.confirmActive;
    },
    async confirm(){
      this.confirmActive = !this.confirmActive;
      if(this.confirmStatus === 'end'){
        this.loadingActive = true;
        await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).update({status: 'ended'}).then(()=>{
          this.modalActive = true;
          this.modalMessage="Live Session Ended";
          this.$router.push({name:"ProductListsForLive"})
          this.loadingActive = false;
        });
      }else if(this.confirmStatus === 'start'){
        this.loadingActive = true;
        let streamingLive = [];
        if(this.checkedLiveSession){
          console.log(true)
          await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').where('status','==','start').get().then((snapShot)=>{
            snapShot.forEach(doc=>{
              streamingLive.push(doc.data())
            });
            db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(streamingLive[0].id).update({
              status:'ended'
            }).then(()=>{
                console.log("ended previous live");
                db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).update({status: 'start'}).then(()=>{
                  this.modalActive=true;
                  this.modalActive=true;
                  console.log('status have started')
                  this.modalMessage="Live Session is Started now!";
                  this.$router.replace({name:"ProductListsForLive"})
                  this.loadingActive = false;
                });
            })
          })
        }else {
          console.log(false)
          await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).update({status: 'start'}).then(()=>{
            this.modalActive=true;
            this.modalMessage="Live Session is Started now!";
            this.$router.replace({name:"ProductListsForLive"})
            this.loadingActive = false;
          });
        }

      }else {
        this.loadingActive = true;
        await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.selectedSession.id).collection('liveProducts').doc(this.removeProduct.id).delete().then(()=>{
          this.loadingActive = false;
        })
      }


    },
    endLive() {
      this.confirmStatus ='end';
      this.modalMessage = "Are you sure to end?"
      this.confirmActive = true;
    },
    startLive(){
      if(this.liveSessionData.some(session=>session['status']==='start')){
        this.checkedLiveSession = true;
        console.log(this.checkedLiveSession);
        this.modalMessage = "You have to end the previous Live Sale";
        this.confirmActive = !this.confirmActive;
        this.confirmStatus = 'start';
      }else {
          this.checkedLiveSession = false;
          this.checkedLiveSession = '';
          this.modalMessage = "Are you sure to Start now!"
          this.confirmActive = !this.confirmActive;
          this.confirmStatus = 'start';
        }

    }
  }
}
</script>

<style scoped>
thead, tbody{
  border: 1px solid var(--light) !important;
  color: var(--dark);
}
.session-pop-up{
  background-color: var(--side-bar-bg);
  position: fixed;
  color: var(--text-color);
  overflow-y: scroll;
  width: 20%;
  height: 400px;
  left: 340px;
  top: 155px;
}
.session-pop-up li{
  background-color: var(--light);
  cursor: pointer;
}
tr{
  color: var(--text-color);
}
.session-pop-up li:hover{
  background-color: var(--softPrimary);
}
.search-box{
  width: 100%;
  background-color: var(--light);
  padding: 5px;
}
.search-box input{
  width: 200px;
}
.content-box{
  margin-top: 15px;
}
@media only screen and (max-width: 1900px) {
  .session-pop-up{
    left: 260px;
  }
}
@media only screen and (max-width: 1030px) {
  .session-pop-up{
    left: 10px;
    top: 100px;
  }
  .content-box {
    background-color: var(--light);
    margin-top: 60px;
  }
  .search-box input{
    width: 100%;
  }
  .session-pop-up{
    width: 95%;
  }
}
</style>