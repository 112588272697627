<template >
  <AdminNavigation/>
  <div class="container-xl pt-5" >


  </div>

</template>

<script>
import { inject } from 'vue'

import AdminNavigation from "@/components/AdminNavigation";
export default {
  components:{AdminNavigation},
  inject: ['messaging'],
  mounted () {
    console.log('Firebase cloud messaging object', this.$messaging)
  },
  //composition api
  setup() {
    const messaging = inject('messaging')

    console.log('Firebase cloud messaging object', messaging);
    const token = messaging.getToken({ vapidKey:'BJMMGbfQ4hOe2acXMe11F99lGUArrGheO2nXLAFzs1D3Oh2MCuJCkWPiawTRR4h_roDqBaiqJ6NLVo5o2gu5mXo' })
    console.log('FCM token', token)
  }

}
</script>

<style scoped>


</style>