<template>
  <Loading v-if="loadingActive"/>
  <InfoDialog v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
  <div class="bg-body">
    <div class="container-fluid" v-if="this.owner" style="background-color: var(--background-color); color: var(--text-color)!important;">
      <div class="row">
<!--
        <div class="modal fade" style="z-index: 10000;" id="liveSessionModal"  tabindex="-1" aria-labelledby="liveSessionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="liveSessionModalLabel">Create Live Session</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="enableLiveSession" autocomplete="off">
                  <label>Enter Live Session Name</label>
                  <input type="text" class="form-control" v-model="liveSession.name" placeholder="name">
                  <label class="mt-3">Facebook Live url (optional)</label>
                  <input type="text" class="form-control" v-model="liveSession.fb_url" placeholder="url">
                  <div class="d-none">
                    <label>Start</label>
                    <input type="time" class="form-control" v-model="liveSession.startTime" >
                    <label>End</label>
                    <input type="time" class="form-control" v-model="liveSession.endTime">
                  </div>
                  <button type="submit" class="btn btn-primary float-end my-3" data-bs-dismiss="modal">Confirm</button>
                </form>
              </div>
            </div>
          </div>
        </div>
-->
        <div class="col-lg-2 p-0">
          <AdminSidebar />
        </div>
        <div class="col-12 col-lg-10 mx-auto table-responsive mt-5">
          <div class="d-flex justify-content-between align-items-center shadow-sm rounded mt-2 mb-2">
            <div class="fs-3 align-items-center">
              <div class="d-flex align-items-center">
                <button @click="$router.back()" class=" btn fs-5" style="color: var(--text-color);"><i class="fas fa-chevron-left"> </i>
                </button>
                <span class="ms-1">Products for Live Sale</span>
<!--                <button type="button" style="color: var(&#45;&#45;text-color);background-color: var(&#45;&#45;btnLight);" class="btn btn-sm mx-1 my-2" data-bs-toggle="modal" data-bs-target="#liveSessionModal">
                  <i class="fas fa-plus"></i> Create Session
                </button>-->

              </div>
            </div>

            <div class="">
              <router-link :to="{name:'LiveHistory',params:{session_id:''}}" class="btn btn-sm mx-2  my-2" style="color: var(--text-color);background-color: var(--btnLight);"><i class="fas fa-list"></i></router-link>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center rounded px-2" style="background-color: var(--light);" v-if="liveSessionData">
            <div class="d-flex align-items-center">
              <span class="fs-5 me-1">
              Current Session: {{liveSessionData.name}}
            </span>
              <span class="badge bg-danger rounded-pill" v-if="liveSessionData.status === 'start'">Streaming</span>
              <span class="badge bg-dark rounded-pill" v-if="liveSessionData.status === 'ended'">Ended</span>
              <span class="badge bg-warning rounded-pill" v-if="liveSessionData.status === 'pending'">Streaming</span>

            </div>
            <button @click="manageCurrentLive" class="btn btn-sm my-2" style="background-color: var(--btnLight);" v-if="liveSessionData">Manage</button>
          </div>
          <div class="card-body table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th class="text-nowrap">#</th>
                <th class="text-nowrap">Barcode</th>
                <th class="text-nowrap">Name</th>
                <th class="text-nowrap">Photos</th>
                <th class="text-nowrap">Buy Price</th>
                <th class="text-nowrap">Sell Price</th>
                <th class="text-nowrap">Stocks</th>
                <th class="text-nowrap">Control</th>
<!--                <th class="text-nowrap">Created At</th>-->
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product,index) in products" class="align-middle" :key="product.id">
                <td class="text-nowrap">{{index+1}}</td>
                <td class="text-nowrap">{{product.barcode}}</td>
                <td class="text-nowrap">{{product.itemName}}</td>
                <td class="text-nowrap">
                  <img :src="product.images[0]" class="rounded border border-light border-3 shadow-sm" style="width:50px; height:50px; object-fit: cover;" alt="">

                  <div class="row">

                    <!--                  <div class="col-3 col-lg-3 col-md-3" v-for="(img,index) in product.images" :key="index">
                                      </div>-->
                  </div>
                </td>
                <td class="text-nowrap">{{product.buyPrice}}</td>
                <td class="text-nowrap">{{product.sellPrice}}</td>
                <td class="text-nowrap">{{product.stock}}</td>
                <td class="text-nowrap">
                  <router-link :to="{name:'ProductDetail', params:{product_id:product.id}}" class="btn btn-primary btn-sm mx-1"><i class="fas fa-info-circle"></i></router-link>
                  <!--                <router-link :to="{name:'ProductEdit', params:{product_id:product.id}}" class="btn btn-warning btn-sm mx-1"><i class="fas fa-edit"></i></router-link>
                                  <button class="btn btn-sm btn-danger mx-1"><i class="fas fa-trash"></i></button>-->
                  <!-- Button trigger modal -->
                  <button type="button" v-if="showLiveBtn" class="btn btn-sm mx-1" style="background-color: var(--btnLight);" @click="selectForLive(product)" :disabled="liveProducts.some(liveProduct=>liveProduct['id']===product.id)" data-bs-toggle="modal" data-bs-target="#liveCountModal">
                    <i class="fas fa-plus"></i>
                  </button>
                  <!-- Modal -->
                  <div class="modal fade" style="z-index: 10000;" id="liveCountModal"  tabindex="-1" aria-labelledby="liveCountModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="liveCountModalLabel">
                            Add Product To Live session
                          </h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" v-if="selectForLiveProduct!==''">
                          <div class="d-flex align-items-center">
                            <img src="../../assets/flash/1.png" v-if="selectForLiveProduct.images.length<0" width="100" height="100" alt="">
                            <img :src="selectForLiveProduct.images[0]" v-else width="100" height="100" alt="">
                            <div class="ms-2 d-flex flex-column">
                              <span>
                              {{selectForLiveProduct.itemName}}
                            </span>
                              <span>
                              Stock: {{selectForLiveProduct.stock}}
                            </span>
                            </div>

                          </div>
                          <form @submit.prevent="liveSale">
                            <label class="form-label mt-2">Add count</label>
                            <input type="text" required class="form-control" v-model="liveCount" placeholder="Enter count">
                            <label class="form-label mt-3">Add discount (%)</label>
                            <input type="text" class="form-control " v-model="liveDiscount" placeholder="Enter discount (optional)">
                            <button type="submit" class="btn my-3" style="background-color: var(--btnLight);" data-bs-dismiss="modal">Confirm</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <!--                <td class="text-nowrap text-start">
                  <i class="fas fa-calendar m-1"></i>
                  <span>{{product.time.toDate().toLocaleDateString()}}</span><br>
                  <i class="fas fa-clock mx-1"></i>
                  <span>{{product.time.toDate().toLocaleTimeString()}}</span>
                </td>-->
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>


</template>

<script>
// import axios from "axios";

import db, {timestamp} from "../../config/FirebaseInit";
import Loading from "../..//components/Loading";
import InfoDialog from "../../components/InfoDialog";
import AdminSidebar from "@/components/AdminSidebar";
export default {
  components: {Loading, InfoDialog,AdminSidebar},
  data(){
    return{
      modalActive:false,
      modalMessage:'',
      loadingActive:false,
      liveCount:'',
      liveDiscount:0,
      products:[],
      selectForLiveProduct:'',
      liveProducts:[],
      showLiveBtn:false,
      /*liveSession: {
        name:'',
        url:'default',
        fb_url:'',
        startTime: '',
        endTime: '',
        status: 'pending',
        date:'',
      },*/
      liveSessionData:'',
      liveSessionId:'',
    }
  },
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },
    currentDateTime(){
      return this.$store.getters.getDateTime;
    },
    currentDay(){
      return this.$store.getters.getDay;
    },
    currentMonth(){
      return this.$store.getters.getMonth;
    },
    currentYear(){
      return this.$store.getters.getYear;
    }
  },
  async mounted() {
    this.$store.dispatch("getDateTime");
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getYear");
    // this.loadingActive = true;
    await this.$store.dispatch('getCurrentShop');
    db.collection(this.owner.city).doc(this.owner.shopId).collection('products').onSnapshot(snapshot => {
      this.products = [];
      snapshot.forEach(doc=>{
        this.products.push(doc.data());
      })
    });
    //Fetching LiveSession Data from db where status = created
    if(this.$route.params.session_id!==''){
      await db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection('liveSession')
          .doc(this.$route.params.session_id)
          .get().then((snapshot) => {
        this.liveSessionData = snapshot.data();
        this.showLiveBtn = true;
        this.liveSessionId = this.$route.params.session_id;
        this.getLiveProducts();

      });
    }else {
      await db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection('liveSession')
          .where('status','==','start')
          .onSnapshot((snapshot) => {
            const result = [];
            snapshot.forEach(doc=>{
              result.push(doc.data())
            });
            this.liveSessionData = result[0];
            this.showLiveBtn = true;
            this.liveSessionId = this.liveSessionData.id
            this.getLiveProducts();
            /*db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.liveSessionData.id).collection('liveProducts').onSnapshot(snapshot => {
              this.liveProducts = [];
              snapshot.forEach(doc=>{
                //console.log('live products',doc.data())
                this.liveProducts.push(doc.data())
              })
            })*/
          });
    }



  },
  methods:{
    async getLiveProducts(){
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc(this.liveSessionId).collection('liveProducts').onSnapshot(snapshot => {
        this.liveProducts = [];
        snapshot.forEach(doc=>{
          //console.log('live products',doc.data())
          this.liveProducts.push(doc.data())
        })
      })
    },
    closeModal(){
      this.modalActive = !this.modalActive;
    },
    selectForLive(product){
      this.liveCount = '';
      this.liveDiscount =0;
      this.selectForLiveProduct = product;
    },
    /*async enableLiveSession(){
      if(this.liveSession.name !== ''){
        this.loadingActive = true;
        const liveSessionRef = await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveSession').doc();
        this.liveSession.id = liveSessionRef.id;
        this.liveSession.date = timestamp;
        await liveSessionRef.set(this.liveSession).then(()=>{
          this.$router.push({name:"ProductListsForLive", params:{session_id:this.liveSession.id}})
          this.liveSessionData = this.liveSession;
          this.liveSessionId = this.liveSessionData.id
          this.getLiveProducts();
          this.liveSession.name ='';
          this.liveSession.fb_url='';
          this.loadingActive = false;
          this.modalActive = true;
          this.modalMessage = 'Live Session success';
          this.showLiveBtn = true;
        });
      }

    },*/
    //
    manageCurrentLive(){
      this.$router.push({name:'LiveHistory',params:{session_id:this.liveSessionData.id}});

      /*if(this.liveSessionData.status=='created'){
        this.loadingActive = true;
        db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection('liveSession')
            .doc(this.liveSessionData.id)
            .update({status:'pending'})
            .then(()=>{
              this.loadingActive = false;
              this.$router.push({name:'liveControl'});
            }).catch(error=>{
          console.log(error.message);
        })
      }else {
        this.$router.push({name:'liveControl'});
      }*/
    },
    liveSale(){
     if(this.liveCount!==''){
       if(this.liveDiscount===''){
         this.selectForLiveProduct.discount = '0';
       }else {
         this.selectForLiveProduct.discount=this.liveDiscount.toString();
       }
       if(this.selectForLiveProduct.stock>this.liveCount){
         this.selectForLiveProduct.time = timestamp;
         this.selectForLiveProduct.day = this.currentDay;
         this.selectForLiveProduct.dateTime = this.currentDateTime;
         this.selectForLiveProduct.month = this.currentMonth;
         this.selectForLiveProduct.year = this.currentYear;
         const item = {'liveSaleCount':this.liveCount.toString(), ...this.selectForLiveProduct};
         //console.log(item)
         this.loadingActive = true;
         db.collection(this.owner.city)
             .doc(this.owner.shopId)
             .collection('liveSession')
             .doc(this.liveSessionData.id)
             .collection('liveProducts')
             .doc(this.selectForLiveProduct.id).set(item).then(()=>{
           this.loadingActive = false;
           this.modalActive = true;
           this.modalMessage='Added to Live Sales';
         }).catch(error=>{
           this.loadingActive = false;
           this.modalMessage = error.message;
           this.modalActive = true;
         })
       }else {
         this.modalActive=true;
         this.modalMessage = "Sorry, Product count is greater than the stock!";
       }
     }else {
       this.modalActive=true;
       this.modalMessage = "Please Fill Product count";
     }
    }
  }

}
</script>

<style scoped>
.content-box{
  margin-top: 20px;
}
tr{
  color: var(--text-color);
}
@media only screen and (max-width: 1030px) {

  .content-box {
    margin-top: 50px;
  }
}
</style>