<template>
  <div class="modal">
    <div class="modal-card shadow-lg rounded">
      <div class="card-body" >
        <div class="d-flex justify-content-between px-1 align-items-center rounded" style="color: var(--text-color);" >
          <span class="fs-3" id="exampleModalLabel"><i class="fas fa-cart-shopping"></i> {{$t('message.cart_items')}}</span>
          <button class="btn fs-3" style="color: var(--text-color);" @click="closeModal"><i class="fas fa-times"></i></button>
        </div>

        <div class="cart-item-box mt-1" v-if="showCarts">
          <div class="row">
            <div class="col-12 p-0 mx-auto" v-for="order in orders" :key="order.productId">
              <div class="card my-1" style="background-color: var(--white);border-bottom: 2px solid var(--light) !important;">
                <div class="card-body" style="color: var(--text-color);">
                  <div class="row">
                    <div class="col-4 col-md-2 d-flex align-items-center">
                      <img :src="order.image" class="img-fluid shadow-sm" style="width: 100px; height: 100px;" alt="">
                    </div>
                    <div class="col-8 col-md-6">
                      <div class="d-flex ms-1 w-100 text-start my-1 flex-column">
                        <span>{{order.itemName}}</span>
                        <div class="d-md-flex justify-content-between align-items-center">
                          <div>
                            <span v-if="order.discount!=='0'">Price:{{order.sellPrice-order.sellPrice*(order.discount/100)}} Ks</span>
                            <span v-else>{{order.sellPrice}} Ks</span>
                          </div>

                          <div class="d-flex my-2 align-items-center">
                            <button class="btn btn-sm" style="background-color: var(--btnLight);" @click="decreaseCount(order)">-</button>
                            <span class="mx-3">{{order.count}}</span>
                            <!--                          <input class="form-control mx-1" min="1" oninput="this.value = Math.abs(this.value)" type="number" v-model="order.count"/>-->
                            <button class="btn btn-sm" style="background-color: var(--btnLight);" @click="increaseCount(order)">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="text-md-end float-md-end d-flex flex-md-column justify-content-between align-items-end">
                        <div class="d-flex flex-column">
                          <span>{{ $t('message.cost') }}</span>
                          <span style="color: var(--primary)" v-if="order.discount!=='0'">{{$t('message.cost')}}: {{order.sellPrice-order.sellPrice*(order.discount/100)*order.count}} Ks</span>
                          <span style="color: var(--primary)" v-else>{{order.sellPrice*order.count}} Ks</span>
                        </div>
                        <div class="d-flex justify-content-start align-items-center p-0 m-0">
                          <span class="text-decoration-underline text-danger" style="cursor:pointer;" :id="order.productId" @click="removeFromCart(order.productId)"><i class="fas fa-ban"></i> Remove</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-item-box" v-else>
          <!--          Notify box-->
          <div class="col-12 col-lg-6 mx-auto" v-if="showNextProcess">
            <div class="card p-1 ">
              <div class="car-body text-center">
                <i class="fas fa-bell fa-2x" style="color: var(--primary);"></i>
                <p class="fs-6" style="color: var(--secondary);"> {{$t('message.these_rur_info')}}</p>
                <div class="d-flex flex-column text-start mx-auto">
                  <span class="rounded p-1 mt-1"><i class="fas fa-location-dot"></i> {{user.address}}</span>
                </div>
                <div class="d-flex p-1 rounded mt-3 align-items-center ">
                  <button class="btn w-50 mx-auto" style="background-color: var(--btnLight);" @click="showUpdateInfobox">{{$t('message.update_again')}}</button>
                  <!-- <button class="btn w-50" style="background-color: var(&#45;&#45;btnLight);" @click="yesOption">Yes</button>-->
                </div>
              </div>
            </div>
          </div>
          <!--          Update Box -->
          <div class="col-12 col-lg-6 mx-auto" v-if="showUpdateBox">

            <div class="card p-1">
              <div class="car-body text-center">
                <i class="fas fa-bell fa-2x" style="color: var(--primary);"></i>
                <p class="fs-6" style="color: var(--secondary);">{{$t('message.update_info')}}</p>
                <div class="d-flex flex-column text-start m-3">
                  <span class="alert alert-warning text-black text-center" v-if="warningActive">{{warningMessage}}</span>

                  <label class="mt-3">{{ $t('message.address') }}</label>
                  <input type="text" class="form-control" v-model="updateAddress">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mx-auto" v-if="showPromoBox">
            <div class="card p-1">
              <div class="car-body">
                <hr/>
                <p class="fw-bold fs-2 my-3">{{$t('message.total_price')}} - {{ totalPrice }}Ks</p>
                <p class="fw-bold text-success" v-if="checkoutItem.discount>0" >Discount:{{ checkoutItem.discount }}Ks</p>
                <p class="alert alert-warning text-black text-center" v-if="warningActive">{{warningMessage}}</p>
                <hr/>
                <p class="fs-6">{{$t('message.promo_code')}} ?</p>
                <div class="d-flex p-1 rounded mt-3 align-items-center ">
                  <input type="text" v-model="userPromoCode" class="form-control" placeholder="xxx">
                  <button class="btn ms-1" style="background-color: var(--btnLight); width: 150px;" @click="addPromo" :disabled="!userPromoCode.length>0">
                    <div class="spinner-border spinner-border-sm d-inline-block" v-if="btnLoadingActive"></div>
                    <span v-else>{{$t('message.check')}}</span>
                  </button>
                  <!--                  <button class="btn w-50" style="background-color: var(&#45;&#45;btnLight);" @click="yesOption">Yes</button>-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6 mt-3 mx-auto"  v-if="loadingActive">
            <!--            Loading Box-->
            <div class="loading mt-3">
              <div class="card border-0 shadow-none bg-white p-4 text-center" >
                <Vue3Lottie :animationData="loadingCart" :height="150" :width="150" />
                <span class="mt-2 fw-bold">Processing...</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6 mt-3 mx-auto px-2" >
            <!--            Success Box-->
            <div class="mx-auto mt-3 py-5 text-center shadow-none" v-if="successActive">
              <Vue3Lottie :animationData="orderSuccess" :height="200" :width="200" />

              <span class="fs-4">{{$t('message.order_success')}} </span>
            </div>
          </div>
        </div>

        <div class="floating-btn rounded" :hidden="loadingActive">
          <div class="w-75 d-flex" v-if="successActive">
            <button class="btn mx-auto my-3" style="background-color: var(--btnLight);" @click="closeModal" v-if="successActive">{{$t('message.check_order')}}</button>
          </div>
          <div class="w-75 d-flex p-0" v-else>
            <button class="btn mx-auto w-50 text-light my-3" style="background-color: var(--primary);" :disabled="!orders.length>0" @click="nextOrderProcess" v-if="showCarts"><span class="me-3">{{ $t('message.next') }}</span><i class="fas fa-chevron-right"></i></button>
            <button class="btn mx-auto me-2 w-50 text-light my-3" style="background-color: var(--primary);" @click="previousOrderProcess" data-bs-dismiss="modal" v-if="!showCarts"><i class="fas fa-chevron-left"></i><span class="ms-3">{{ $t('message.previous') }}</span></button>
            <button class="btn mx-auto me-2 w-50 text-light my-3" style="background-color: var(--primary);" @click="nextToDelivery" data-bs-dismiss="modal" v-if="!showCarts && !showUpdateBox"><span class="me-3">{{ $t('message.order_now') }}</span> </button>
            <button class="btn mx-auto w-50 me-2 text-light my-3" style="background-color: var(--primary);" @click="nextAfterUpdate" data-bs-dismiss="modal" v-if="showUpdateBox"><span class="me-3">{{ $t('message.order_now') }}</span> </button>
            <!--            <button class="btn mx-auto w-50 me-2 text-light my-3" style="background-color: var(&#45;&#45;primary);" :disabled="!orders.length>0" @click="checkOut" data-bs-dismiss="modal" v-if="showOrderButton">Order Now</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const loadingCart = require("../assets/lotties/loading-cart.json");
const orderSuccess = require("../assets/lotties/OrderSuccess.json")

import db, {timestamp} from "@/config/FirebaseInit";
export default {
  name:"AddToCart",
  data(){
    return{
      orderSuccess,
      loadingCart,
      totalPrice:0,
      orderIdToRoute:'',
      allTotal:[],
      deliveryServices:[],
      payment_methods:[],
      promoDiscount:'',
      promoTotal:'',

      modalMessage:'',
      showPromoBox:false,
      promotionValid:false,
      btnLoadingActive:false,
      showPaymentToggle:false,
      showCityToggle:false,
      externalFeeRange:false,

      successActive:false,
      loadingActive:false,
      warningActive:false,
      warningMessage:'',
      showCarts:true,
      showUpdateBox:false,
      //showDeliveryBox:false,
      showNextProcess:false,
      showOtherDeliInput:false,
      showOrderButton:false,
      selectedCity:'',
      selectedPaymentType:'',
      selectedDeliveryName:'',
      selectedDelivery:'',
      selectedDeliveryFeeRange:'',
      updatePhone:'',
      updateAddress:'',
      userPromoCode:'',
      promotion:'',
      currentShop:'',
      checkoutItem: {
        'orderUserId':'',
        'orderStatus':'pending',
        'id':'',
        'countList':[],
        'nameList':[],
        'sellPriceList':[],
        'buyPriceList':[],
        'productIdList':[],
        'itemCodeList':[],
        'images':[],
        'acceptedList':[],
        'time':'',
        'day':'',
        'statusDay':'',
        'statusDateTime':'',
        'month':'',
        'year':'',
        'dateTime':'',
        'discount':"0",
        'promoCode':"0",
        'tax':"0",
        'totalPrice':'',
        'vouncherId':'',
        'paymentType':'',
        'transactionImg':'default',
        'deliveryServiceId':'',
        'deliveryServiceName':'',
        'cityToDeliver':'',
        'deliveryFeeRange':'',
      }

    }
  },
  computed:{

    cartInfo(){
      return this.$store.getters.getCartInfo;
    },
    user(){
      return this.$store.getters.getCurrentUser;
    },
    orders(){
      return this.$store.getters.getCartItems;
    },
    cities(){
      return this.$store.getters.getCities;
    },
    today(){
      return this.$store.getters.getDay;
    },
    thisMonth(){
      return this.$store.getters.getMonth;
    },
    thisYear(){
      return this.$store.getters.getYear;
    },
    dateTime(){
      return this.$store.getters.getDateTime;
    }
  },
  mounted() {
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getDateTime");
    this.$store.dispatch("getYear");
    this.updateAddress = this.user.address;
    db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).get().then(snapshot=>{
      this.currentShop = snapshot.data();
    })
  },

  methods:{
    //radio click
    showDeliRangeByCity(city){
      this.showCityToggle = !this.showCityToggle;
      this.selectedCity = city;
      if(this.selectedDelivery!=='' && this.selectedCity!==''){
        if(this.selectedDelivery.availableCities.includes(this.selectedCity)){
          this.selectedDeliveryFeeRange = this.selectedDelivery.feeRange;
        }else {
          this.selectedDeliveryFeeRange = this.selectedDelivery.externalFeeRange;
        }
      }
      // console.log(this.selectedDeliveryFeeRange)
    },

    choosePaymentType(type){
      this.selectedPaymentType =  type;
      this.showPaymentToggle = !this.showPaymentToggle;
    },
    closeInfoModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    async nextAfterUpdate(){
      if(this.updateAddress!==''){
        this.showUpdateBox = false;
        this.loadingActive = true;
        await db.collection('users').doc(this.user.id).update({
          'address':this.updateAddress
        }).then(()=>{
          console.log('update ok')
          this.confirm();
          //this.showDeliveryBox = true;
          this.showUpdateBox = false;
          this.showOrderButton = true;
        })
      }else {
        this.warningActive = true;
        this.warningMessage = this.$t('message.update_info_warning');
        setTimeout(()=>{
          this.warningActive = false;
        },5000)
      }
    },
    nextToDelivery(){
      if(this.user.phone!=='' || this.user.otherPhone!==''){
        //this.showDeliveryBox = true;
        this.showNextProcess = false;
        this.showPromoBox = false;
        //this.showOrderButton = true;
        this.confirm();
      }else {
        this.warningMessage = this.$t('message.update_info_warning');
        setTimeout(()=>{
          this.warningActive = false;
        },5000)
      }
    },
    showUpdateInfobox(){
      this.showNextProcess = false;
      this.showUpdateBox = true;
    },

    nextOrderProcess(){
      this.showCarts = !this.showCarts;
      this.showNextProcess = true;
      this.checkoutItem.orderUserId=this.user.id;
      this.showPromoBox = true;
      const orderDB = db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('orders').doc();
      this.checkoutItem.id= orderDB.id;

      this.checkoutItem.month=this.thisMonth;
      this.checkoutItem.day = this.today;
      this.checkoutItem.year = this.thisYear;
      this.checkoutItem.dateTime = this.dateTime;
      this.checkoutItem.statusDay = this.today;
      this.checkoutItem.statusDateTime = this.dateTime;
      this.checkoutItem.time = timestamp;

      for(let i =0; i<this.orders.length; i++){
        this.checkoutItem.nameList.push(this.orders[i].itemName);
        if(this.orders[i].discount !== '0'){
          let discountSellPrice = 0;
          discountSellPrice = this.orders[i].sellPrice-(this.orders[i].sellPrice*(this.orders[i].discount/100));
          this.checkoutItem.sellPriceList.push(discountSellPrice.toString());
          this.totalPrice+=discountSellPrice * parseInt(this.orders[i].count);
        }else{
          this.checkoutItem.sellPriceList.push(this.orders[i].sellPrice.toString());
          this.totalPrice+=parseInt(this.orders[i].sellPrice ) * parseInt(this.orders[i].count);
        }
        this.checkoutItem.buyPriceList.push(this.orders[i].buyPrice);
        this.checkoutItem.countList.push(this.orders[i].count.toString());
        this.checkoutItem.images.push(this.orders[i].image);
        this.checkoutItem.itemCodeList.push(this.orders[i].itemCode);
        this.checkoutItem.productIdList.push(this.orders[i].productId);
        this.checkoutItem.acceptedList.push('available');
      }
      // console.log(this.checkoutItem)
    },

    getToday(){
      const date = new Date();
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return day+"-"+month+"-"+year;
    },

    addPromo(){
      this.btnLoadingActive = true;
      db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('promotion').where('code','==',this.userPromoCode).onSnapshot(snapShot=>{
        if(snapShot.size>0){ //check whether there is promotion related with code or not
          snapShot.forEach(doc=>{
            this.promotion=doc.data();
          });
          //Get the used data to check the user is already used the code or not
          db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('promotion').doc(this.promotion.id).collection('used').onSnapshot(snapshot => {
            let promotionUser = [];
            snapshot.forEach(doc=>{
              promotionUser.push(doc.data())
            });
            if(snapshot.size === promotionUser.length){ //do other steps when looping complete
              if(!promotionUser.some(user=>user['customerId']===this.user.id)) {
                let today = new Date(this.getToday());
                let startDate = new Date(this.promotion.startDate);
                let expireDate = new Date(this.promotion.endDate);
                if(this.totalPrice>this.promotion.minimumAmount){
                  if(expireDate >= today && startDate <= today ){
                    this.promotionValid = true;
                    if(this.promotion.status === 'Price'){
                      this.checkoutItem.discount=this.promotion.discount+"";
                      this.btnLoadingActive = false;
                    }else {
                      let discount = this.totalPrice * (this.promotion.discount/100);
                      this.checkoutItem.discount=discount.toString();
                      this.btnLoadingActive = false;
                    }
                  }else {
                    this.promotionValid = false;
                    this.btnLoadingActive = false;
                    this.warningActive = true;
                    this.warningMessage = this.$t('message.sry_code_expire');
                    setTimeout(()=>{
                      this.warningActive = false;
                    },5000);
                  }
                }else {
                  this.promotionValid = false;
                  this.infoModalActive = true;
                  this.btnLoadingActive = false;
                  this.warningActive = true;
                  this.userPromoCode = '';
                  this.warningMessage = this.$t('message.sry_purchase_least')+this.promotion.minimumAmount+" Ks";
                  setTimeout(()=>{
                    this.warningActive = false;
                  },5000)
                }
              }else {
                this.promotionValid = false;
                this.btnLoadingActive = false;
                this.warningActive = true;
                this.warningMessage = this.$t('message.sry_code_already');
                setTimeout(()=>{
                  this.warningActive = false;
                },5000);
              }
            }

          });
        }else {
          this.promotionValid = false;
          this.infoModalActive = true;
          this.btnLoadingActive = false;
          this.warningActive = true;
          this.warningMessage =this.$t('message.sry_cant_promo');
          this.userPromoCode ='';
          setTimeout(()=>{
            this.warningActive = false;
          },5000)
        }
      });

    },
    previousOrderProcess(){
      this.checkoutItem.nameList=[];
      this.checkoutItem.countList=[];
      this.checkoutItem.sellPriceList=[];
      this.checkoutItem.buyPriceList=[];
      this.checkoutItem.itemCodeList=[];
      this.checkoutItem.acceptedList=[];
      this.checkoutItem.productIdList=[];
      this.checkoutItem.images=[];
      this.totalPrice = 0;

      this.showCarts = true;
      this.showUpdateBox = false;
      //this.showDeliveryBox = false;
      this.showOrderButton = false;
      this.showNextProcess = false;
      // console.log(this.checkoutItem)
    },
    updateInfo(){
      db.collection('users').doc(this.user.id).update({
        'address':this.updateAddress,
        'otherPhone':this.updatePhone
      }).then(()=>{
        console.log('update ok');
        //this.showDeliveryBox = !this.showDeliveryBox;
      })
    },
    closeModal(){
      this.$emit("close-cart");
     // this.$router.push({name:'CustomerOrderDetails', params:{order_id:this.orderIdToRoute}});
    },
    removeFromCart(productId){
      /*console.log(productId)
      */
      db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(productId).delete().then(()=>{
        console.log('item removed')
        this.$store.commit("filterCart", productId)
        //console.log(this.orders)
      });
    },
    increaseCount(order){
      (order.count)++;

    },
    decreaseCount(order){
      if(order.count>1){
        order.count--;
      }
    },

    getRandomStr(){
      const chars = "1234567890";
      let result = '';
      for ( let i = 0; i < 8; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    checkOut(){
      if(this.user.phone!=='' || this.user.otherPhone!==''){
        //this.showDeliveryBox = true;
        //this.showNextProcess = false;
        //this.showOrderButton = true;
        this.confirm();
      }else {
        this.warningMessage = this.$t('message.update_info');
        setTimeout(()=>{
          this.warningActive = false;
        },5000)
      }
      /* if(this.selectedDelivery==='' && this.selectedPaymentType==='' && this.selectedCity===''){
         this.warningActive = true;
         this.warningMessage = "Please fill all the requirements!"
         setTimeout(()=>{
           this.warningActive = false;
         },5000)
       }else {
         //this.showDeliveryBox = false;
         this.confirm();
       }*/

    },

    async confirm(){
      // this.loadingActive = true;
      /* const checkoutItem = {
         'orderUserId':this.user.id,
         'orderStatus':'pending',
         'id':'',
         'countList':[],
         'nameList':[],
         'sellPriceList':[],
         'buyPriceList':[],
         'productIdList':[],
         'itemCodeList':[],
         'images':[],
         'acceptedList':[],
         'time':'',
         'day':'',
         'year':'',
         'discount':"0",
         'promoCode':"0",
         'tax':"0",
         'totalPrice':'',
         'vouncherId':'',
         'paymentType':this.selectedPaymentType,
         'transactionImg':'default',
         'deliveryServiceId':this.selectedDelivery.id || '',
         'deliveryServiceName':this.selectedDeliveryName,
         'cityToDeliver':this.selectedCity,
         'deliveryFeeRange':this.selectedDeliveryFeeRange
       }*/
      this.loadingActive = true;
      this.checkoutItem.totalPrice = this.totalPrice.toString();
      if(this.promotionValid){
        const useDb = db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('promotion').doc(this.promotion.id).collection('used').doc();
        useDb.set({
          'createdAt':timestamp,
          'customerId':this.user.id,
          'customerName':this.user.name,
          'id':useDb.id,
          'image':this.user.photo
        }).then(()=>{
          db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('orders').doc(this.checkoutItem.id).set(this.checkoutItem)
              .then(()=>{
                console.log('owner order added')
                let recordRef = db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('orders').doc(this.checkoutItem.id).collection('records').doc();
                recordRef.set({
                  'statusDay':this.today,
                  'statusDateTime':this.dateTime,
                  'orderStatus':'pending',
                  'id':recordRef.id,
                  'time':timestamp,
                }).then(()=>{
                  console.log('owner order record added');
                  db.collection('users').doc(this.user.id).collection('orders').doc(this.checkoutItem.id).set({'shopId':this.cartInfo.shopId,'shopCity':this.cartInfo.city,'shopName':this.currentShop.name, ...this.checkoutItem}).then(()=>{
                    console.log('user order added')
                    let userRecordRef = db.collection('users').doc(this.user.id).collection('orders').doc(this.checkoutItem.id).collection('records').doc();
                    userRecordRef.set({
                      'statusDay':this.today,
                      'statusDateTime':this.dateTime,
                      'orderStatus':'pending',
                      'id':userRecordRef.id,
                      'time':timestamp,
                    }).then(()=>{
                      console.log('user order record added')
                      this.removeCart();
                      this.orderIdToRoute = this.checkoutItem.id;
                      this.loadingActive = false;
                      this.showPromoBox = false;
                      this.showUpdateBox = false;
                      this.showNextProcess = false;
                      this.successActive = true;
                    })

                  }).catch(error=>{
                    console.log(error.message)
                  });
                })

              }).catch(error=>{
            console.log(error.message);
          });
        });
      }else {
        db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('orders').doc(this.checkoutItem.id).set(this.checkoutItem)
            .then(()=>{
              console.log('owner order added')
              let recordRef = db.collection(this.cartInfo.city).doc(this.cartInfo.shopId).collection('orders').doc(this.checkoutItem.id).collection('records').doc();
              recordRef.set({
                'statusDay':this.today,
                'statusDateTime':this.dateTime,
                'orderStatus':'pending',
                'id':recordRef.id,
                'time':timestamp
              }).then(()=>{
                console.log('owner order record added');
                db.collection('users').doc(this.user.id).collection('orders').doc(this.checkoutItem.id).set({'shopId':this.cartInfo.shopId,'shopCity':this.cartInfo.city,'shopName':this.currentShop.name, ...this.checkoutItem}).then(()=>{
                  console.log('user order added')
                  let userRecordRef = db.collection('users').doc(this.user.id).collection('orders').doc(this.checkoutItem.id).collection('records').doc();
                  userRecordRef.set({
                    'statusDay':this.today,
                    'statusDateTime':this.dateTime,
                    'orderStatus':'pending',
                    'id':userRecordRef.id,
                    'time':timestamp,
                  }).then(()=>{
                    console.log('user order record added')
                    this.removeCart();
                    this.orderIdToRoute = this.checkoutItem.id;
                    this.loadingActive = false;
                    this.showPromoBox = false;
                    this.showUpdateBox = false;
                    this.showNextProcess = false;
                    this.successActive = true;
                  })

                }).catch(error=>{
                  console.log(error.message)
                });
              })

            }).catch(error=>{
          console.log(error.message);
        });
      }

    },
    removeCart(){
      db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').get().then((snapshot)=>{
        snapshot.forEach(doc=>{
          doc.ref.delete();
        });
        db.collection('users').doc(this.user.id).collection('cart').doc('shop').delete().then(()=>{
          this.$store.state.cart_items=[];
          this.loadingActive = false;
          this.infoModalActive = true;
          this.modalMessage = this.$t('message.order_success');
        })
      })
    },
  },

}
</script>

<style scoped>
.modal{
  top:0;
  z-index: 20000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.51);
  backdrop-filter: blur(40px);
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-card{
  width: 80%;
  background-color: var(--white) !important;
  border-radius: var(--border-radius) !important;

}
.cart-item-box{
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.floating-btn{
  background-color: var(--side-bar-bg);
  position: sticky;
  border-radius: var(--border-radius) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-circle {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid var(--primary);
  border-bottom: 10px solid var(--primary);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin ease 1000ms  infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media screen and (max-width: 1000px){
  .modal-card{
    width: 100%;
  }
  .cart-item-box {
    height: 70vh;
  }

}
@media screen and (max-width: 380px){
  .modal-card{
    width: 100%;
  }
  .cart-item-box {
    height: 60vh;
  }

}


</style>