<template>
  <AppLayout>
    <Loading v-if="loadingActive" />
    <InfoDialog
      v-if="infoModalActive"
      :modal-message="modalMessage"
      @close-modal="closeModal"
    />
    <ConfirmDialog
      :modal-message="modalMessage"
      v-if="confirmActive"
      @confirm="confirm"
      @cancel="cancel"
    />
    <ProductSelectModal
      :selectedProduct="selectedProduct"
      :owner="owner"
      :selectedRelatons="selectedRelations"
      v-if="showDialog"
      @confirm-modal="confirmItem"
      @close-modal="closeDialog"
    />

    <div class="row mx-1 p-1" style="overflow-y: hidden">
      <!--          search box-->
      <div
        class="col-12 col-lg-5 col-md-6"
        style="
          background-color: var(--light);
          border-radius: var(--border-radius);
        "
      >
        <div
          class="d-flex flex-column rounded p-1"
          style="background-color: var(--light)"
        >
          <!--              <div class="d-flex justify-content-between d-none">
                          <div class="dropdown">
                            <button class="btn align-items-center dropdown-toggle" style="background-color: var(&#45;&#45;white);color: var(&#45;&#45;text-color);" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-filter"></i>
                              <span v-if="selectedSearchType === 'itemCode'">{{$t('message.itemCode')}}</span>
                              <span v-if="selectedSearchType === 'barcode'">{{$t('message.barcode')}}</span>
                              <span v-if="selectedSearchType === 'itemName'">{{$t('message.itemName')}}</span>
                            </button>
                            <ul class="dropdown-menu" style="z-index: 5000;" aria-labelledby="dropdownMenuButton1">
                              <li><a class="dropdown-item" @click="selectSearchType('barcode')">{{ $t('message.barcode') }}</a></li>
                              <li><a class="dropdown-item" @click="selectSearchType('itemCode')">{{ $t('message.itemCode') }}</a></li>
                              <li><a class="dropdown-item" @click="selectSearchType('itemName')">{{ $t('message.itemName') }}</a></li>
                            </ul>
                          </div>
                          <button class="btn btn-sm d-lg-none" style="background-color: var(&#45;&#45;softPrimary);" @click="showSelectedBoxHandler"><i class="fas fa-cart-plus"></i> <span class="badge rounded-circle" style="background-color: var(&#45;&#45;btnLight);">{{selectedSellProduct.length}}</span></button>
                        </div>-->
          <div
            class="d-flex align-items-end p-1"
            style="
              background-color: var(--background-color);
              border-radius: var(--border-radius);
            "
          >
            <!--                <button class="btn" style="color: var(&#45;&#45;text-color);"  v-if="products.length>0" @click="clearSearch"><i class="fas fa-times"></i></button>-->
            <button class="btn" style="color: var(--text-color)">
              <i class="fas fa-search"></i>
            </button>
            <!--                <input type="text" ref="otherSearch" autofocus v-model="search" v-if="selectedSearchType!=='barcode'" @input="searchProductFromServer" :placeholder="$t('message.search')" class="form-control " @click="showSearchBoxHandler">-->
            <input
              type="text"
              ref="barcodeSearch"
              autofocus
              v-model="search"
              @keyup.enter="searchByBarcode"
              :placeholder="$t('message.search')"
              class="form-control"
              @click="showSearchBoxHandler"
            />
            <!--@click="showSearchProduct"-->
          </div>
        </div>

        <!--            Cateogry -->
        <div class="category-box">
          <span
            class="badge rounded-circle float-end text-dark mb-1"
            style="background-color: var(--softPrimary)"
            >{{
              selectedSellProduct.length > 0 ? selectedSellProduct.length : ""
            }}</span
          >
          <ul class="text-start pb-3 d-block list-unstyled border-0">
            <li
              class="d-inline-block mx-1 category-list"
              :class="selectedCategory === 'All' ? 'category-list-active' : ''"
              style="background-color: var(--light)"
              @click="selectCate('All')"
            >
              <span class="mx-2"> All</span>
            </li>
            <li
              v-for="(category, index) in productCategories"
              style="background-color: var(--light)"
              :key="index"
              class="category-list d-inline-block"
              :class="
                selectedCategory === category.name ? 'category-list-active' : ''
              "
              @click="selectCate(category.name)"
            >
              <span class="mx-2">{{ category.name }}</span>
            </li>
          </ul>
        </div>

        <div class="ms-1">
          <div class="row product-box">
            <div
              class="col-4"
              v-for="(product, index) in filterProducts.product"
              :key="index"
              @click="selectForCart(product)"
            >
              <div class="product-card text-center">
                <div class="">
                  <img
                    :src="product.images[0]"
                    v-if="product.images.length > 0"
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: var(--border-radius) !important;
                    "
                    alt=""
                  />
                  <img
                    src="../../assets/flash/1.png"
                    v-else
                    style="
                      opacity: 0.3;
                      width: 120px;
                      height: 120px;
                      border-radius: var(--border-radius) !important;
                    "
                    alt=""
                  />
                </div>
                <div
                  class="badge d-flex justify-content-between align-items-center"
                  style="
                    position: absolute;
                    top: 5px;
                    border-radius: var(--border-radius);
                    background-color: var(--secondary);
                    right: 5px;
                  "
                >
                  <span class="fw-bold">{{ product.stock }} Pcs</span>
                </div>
                <div
                  class="ms-2 p-1 text-start"
                  style="border-radius: var(--border-radius)"
                >
                  <div class="d-flex text-start flex-column">
                    <div
                      class="d-flex justify-content-between align-items-start"
                    >
                      <span class="fw-bold text-truncate">{{
                        product.itemName
                      }}</span>
                      <!-- <i class="fas fa-plus-circle"></i>-->
                    </div>
                    <span
                      style="font-size: 12px; color: var(--text-color-light)"
                      >{{ product.barcode }}</span
                    >
                    <span class="fw-bold" style="color: var(--primary)"
                      >{{ product.sellPrice }} Ks</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row py-2 border-top me-1">
            <pagination
              :totalPages="pageCount"
              :perPage="perPage"
              :currentPage="currentPage"
              :pages="allProducts"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
      <!--          selected box-->
      <div
        class="col-12 col-lg-7 col-md-6 text-center rounded"
        v-if="showSelectedBox"
      >
        <div
          class="d-flex rounded align-items-center justify-content-between py-2 px-2"
          style="background-color: var(--light)"
        >
          <div class="d-flex">
            <select
              class="form-select"
              style="background-color: var(--white); color: var(--text-color)"
              v-model="selectedSelectionIndex"
              @change="changeSelection"
              aria-label="Default select example"
            >
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
              <option value="4">Four</option>
              <option value="5">Five</option>
            </select>
          </div>
          <span class="w-50">{{ $t("message.selected_items") }}</span>

          <button
            type="button"
            style="background-color: var(--primary)"
            :disabled="selectedSellProduct.length <= 0"
            class="btn btn-sm text-white d-flex d-none d-lg-block"
            @click="submitSale"
          >
            <!--                data-bs-toggle="modal" data-bs-target="#exampleModal"-->
            {{ $t("message.submit_sales") }}
          </button>
        </div>
        <!--             TODO-->
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex mt-2">
            <div
              class="p-1"
              style="
                background-color: var(--light);
                border-radius: var(--border-radius);
              "
            >
              <button
                class="btn btn-sm me-2"
                :class="gridView === 'true' ? 'active-btn' : ''"
                @click="setViewOption('true')"
              >
                <i class="fas fa-grip"></i>
              </button>
              <button
                class="btn btn-sm"
                :class="gridView === 'false' ? 'active-btn' : ''"
                @click="setViewOption('false')"
              >
                <i class="fas fa-border-all"></i>
              </button>
            </div>
            <button
              class="btn btn-sm btn-danger my-1 ms-2"
              @click="clearCartItems"
            >
              <i class="fa fa-filter-circle-xmark"></i>
              <span class="ms-2">{{ $t("message.clear_all") }}</span>
            </button>
          </div>
          <div>
            <span class="text-end">{{ $t("message.total") }}</span
            ><span v-if="shopInfo.tax !== '0'">
              + tax {{ shopInfo.tax }} %
            </span>
            <span class="text-success">= {{ totalForShow }} Ks</span>
          </div>
        </div>
        <div class="row selected-box" v-if="gridView === 'true'">
          <div
            class="col-12 col-lg-6 col-md-6"
            v-for="(order, index) in selectedSellProduct"
            :key="index"
          >
            <div
              class="card p-0"
              style="
                background-color: var(--side-bar-bg) !important;
                padding: 4px !important;
              "
            >
              <div
                class="card-body m-0"
                style="background-color: var(--background-color) !important"
              >
                <div class="d-flex">
                  <img
                    :src="order.images[0]"
                    v-if="order.images.length > 0"
                    style="
                      object-fit: cover;
                      border-radius: var(--border-radius);
                      width: 120px;
                      height: 120px;
                      border: 2px solid var(--light);
                    "
                    alt=""
                  />
                  <img
                    src="../../assets/flash/1.png"
                    v-else
                    style="width: 120px; height: 120px"
                    alt=""
                  />
                  <div class="d-flex text-start flex-column ms-1 w-100">
                    <div
                      class="d-flex justify-content-between align-items-start"
                      style="height: 50px; font-size: 13px"
                    >
                      <p class="text-wrap fw-bold">{{ order.itemName }}</p>
                      <button
                        class="btn btn-sm btn-sm"
                        :id="order.id"
                        @click="removeFromCart(order, index)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <span style="color: var(--primary)"
                      >{{ order.sellPrice }} Ks</span
                    >
                    <!--                         <span>Stock : {{order.stock}}</span>
                                            <span>Count: {{order.count}}</span>
                                                                  <span style="color: var(--primary)">{{order.stock}} pcs</span>
                                                                  <span>count : {{order.count}}</span>
                                                                  <span>Back Number: {{order.backNumber}}</span>-->
                    <div
                      class="d-flex w-100 mt-1 rounded justify-content-between align-items-center p-1"
                      style="
                        background-color: var(--light);
                        border-radius: var(--border-radius);
                      "
                    >
                      <button
                        class="btn btn-sm text-white"
                        style="background-color: var(--secondary)"
                        @click="decreaseCount(order)"
                      >
                        -
                      </button>
                      <!--                          <span class="mx-2">{{order.frontNumber}} {{order.frontUnit}}</span>-->
                      <input
                        class="form-control mx-1 w-50"
                        type="number"
                        min="1"
                        style="background-color: var(--background-color)"
                        v-model="order.frontNumber"
                        @input="countChangeInput(order)"
                      />
                      <button
                        class="btn btn-sm text-white"
                        style="background-color: var(--secondary)"
                        @click="increaseCount(order)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 m-0 p-0">
            <div
              class="d-flex d-lg-none p-1"
              style="
                position: fixed;
                bottom: 0;
                background-color: var(--background-color);
              "
            >
              <button
                class="btn w-25 me-2"
                style="border: 2px solid var(--softPrimary)"
              >
                Cancel
              </button>
              <button
                style="background-color: var(--primary)"
                :disabled="selectedSellProduct.length <= 0"
                class="btn w-75 text-white"
                @click="submitSale"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                {{ $t("message.submit_sale") }}
              </button>
            </div>
          </div>
        </div>
        <div class="row selected-box" v-else>
          <div class="col-12">
            <!--                {{order}}-->
            <div class="table-responsive p-0 mt-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>*</th>
                    <th
                      class="text-truncate text-start"
                      style="max-width: 250px"
                    >
                      {{ $t("message.itemName") }}
                    </th>
                    <th>{{ $t("message.sell_price") }}</th>
                    <th>{{ $t("message.unit") }}</th>
                    <th>{{ $t("message.qty") }}</th>
                    <th>{{ $t("message.cost") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, index) in selectedSellProduct"
                    :key="index"
                  >
                    <td>
                      <button
                        class="btn btn-sm btn-sm text-danger"
                        :id="order.id"
                        @click="removeFromCart(order, index)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                    <td class="d-none">
                      <img
                        :src="order.images[0]"
                        v-if="order.images.length > 0"
                        style="
                          object-fit: cover;
                          border-radius: var(--border-radius);
                          width: 50px;
                          height: 50px;
                          border: 2px solid var(--light);
                        "
                        alt=""
                      />
                      <img
                        src="../../assets/flash/1.png"
                        v-else
                        style="width: 50px; height: 50px"
                        alt=""
                      />
                    </td>
                    <td
                      class="text-truncate text-start"
                      style="max-width: 250px"
                    >
                      {{ order.itemName }}
                    </td>
                    <td>{{ order.sellPrice }} Ks</td>
                    <td>{{ order.frontUnit }}</td>
                    <td class="text-center">
                      <div
                        class="d-flex rounded justify-content-between align-items-center p-1"
                        style="width: 150px"
                      >
                        <button
                          class="btn btn-sm text-white"
                          style="background-color: var(--secondary)"
                          @click="decreaseCount(order)"
                        >
                          -
                        </button>
                        <!-- <span class="mx-2">{{order.frontNumber}}</span>-->
                        <input
                          class="form-control mx-1"
                          @focus="$event.target.select()"
                          style="background-color: var(--background-color)"
                          v-model="order.frontNumber"
                          @input="countChangeInput(order)"
                        />
                        <button
                          class="btn btn-sm text-white"
                          style="background-color: var(--secondary)"
                          @click="increaseCount(order)"
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      {{
                        (
                          parseInt(order.frontNumber) *
                          parseInt(order.sellPrice)
                        ).toLocaleString()
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 m-0 p-0">
            <div
              class="d-flex w-100 d-lg-none p-1"
              style="
                position: fixed;
                bottom: 0;
                background-color: var(--background-color);
              "
            >
              <button
                class="btn w-25 me-2"
                style="border: 2px solid var(--softPrimary)"
              >
                Cancel
              </button>
              <button
                style="background-color: var(--primary)"
                :disabled="selectedSellProduct.length <= 0"
                class="btn w-75 text-white"
                @click="submitSale"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                {{ $t("message.submit_sale") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import html2canvas from "html2canvas";
import db from "../../config/FirebaseInit";
import ConfirmDialog from "@/components/ConfirmDialog";
import InfoDialog from "@/components/InfoDialog";
import Loading from "@/components/Loading";
import ProductSelectModal from "@/components/ProductSelectModal";
import "lodash/debounce";
import { debounce } from "lodash";
import Pagination from "@/components/Pagination";
import AppLayout from "@/components/AppLayout";
export default {
  components: {
    AppLayout,
    Loading,
    ConfirmDialog,
    InfoDialog,
    ProductSelectModal,
    Pagination,
  },
  data() {
    return {
      //pagination
      currentPage: 0,
      perPage: 20,
      //end pagination

      showCart: false,
      showTableVoucher: false,
      infoModalActive: false,
      modalMessage: "",
      loadingActive: false,
      confirmActive: false,

      //modal
      showDiscountInput: false,
      showPromotionInput: false,
      showDiscountPriceInput: false,

      showDiscountBox: true,
      showPaymentBox: true,
      showVoucherBox: true,
      showCustomerBox: true,

      selectedSellProductContainer: 1,
      newDiscount: "",
      newDiscountPrice: "",
      newPromotion: "",
      showDialog: false,

      products: [],
      tax: 0,
      //search data
      search: "",
      searchCode: "",
      searchProduct: null,
      showSelectedBox: true,
      showSearchBox: true,

      //selected products list
      // selectedSellProduc:[],
      totalPrice: 0,
      originalProduct: "",
      showSearch: false,

      showAlert: false,
      alertMessage: "",
      discountType: "price",

      cashier: [],
      // productCategories:[],

      //searched product
      product: {
        name: "",
        images: [],
        buyPrice: "",
        sellPrice: "",
        barcode: "",
        itemCode: "",
      },
      selectedCategory: "All",
      selectedSearchType: "",
      selectedSelectionIndex: "",

      //for count*price of each order
      costPerItem: [],
      casherCode: "",

      //Relations
      relation: [],
      selectedProduct: "",
      frontNumber: 1,
      frontUnit: "Pcs",
      selectedRelation: "",
      selectedRelations: [],

      pcsList: [],
      orignailStockList: [],
      customers: [],
      searchCustomer: "",
      selectedSellProduct: [],
      // selectedSearchType:'barcode',
      //final order confirm obj
      checkoutItem: {
        countList: [],
        nameList: [],
        sellPriceList: [],
        buyPriceList: [],
        productIdList: [],
        itemCodeList: [],
        productUnitList: [],
        casherCode: "",
        sellType: "offlineSell",
        id: "",
        time: "",
        day: "",
        year: "",
        month: "",
        customerAddress: "",
        customerName: "",
        customerPhone: "",
        customerId: "",
        dateTime: "",
        discount: "0",
        promoCode: "0",
        tax: "0",
        totalPrice: "0",
        cashReceived: "0",
        credit: "0",
        change: "0",
        vouncherId: "",
        paymentMethod: "",
        paymentId: "",
        note: "",
      },
      showPaymentToggle: false,
      showCustomerAddBox: false,
      customerAlert: false,
      voucherStyle: "",
      // paymentMethods:[],
      // filterProducts:[],
      selectedPaymentType: "",
      payAmount: "",
      selectedCustomer: "",
      limit: 20,
      showWarning: true,
      warningMessage: "",
      sellNowActive: false,
      searchedCustomerList: [],
      customer: {
        name: "",
        id: "",
        image: "",
        debt: "0",
        address: "",
        phone: "",
        month: "",
        day: "",
        year: "",
        time: "",
      },
      timeout: null,
      allProducts: [],
    };
  },
  computed: {
    // allProducts(){
    //    return this.$store.getters.getAllProducts
    //   // return this.$store.state.products.lists;
    //
    // },.
    productCategories() {
      return this.$store.getters.getProductCategories.filter(
        (cate) => cate.name !== "All"
      );
    },

    filterProducts() {
      let filterProduct = [];
      if (this.search !== "") {
        filterProduct = this.allProducts.filter(
          (pro) =>
            pro.barcode.toLowerCase().includes(this.search.toLowerCase()) ||
            pro.itemName.toLowerCase().includes(this.search.toLowerCase())
        );
        return {
          product: filterProduct.slice(
            this.offsetCount,
            this.offsetCount + this.perPage
          ),
          prodCount: filterProduct.length,
        };
      } else if (this.selectedCategory !== "All") {
        filterProduct = this.allProducts.filter(
          (pro) => pro.type === this.selectedCategory
        );
        return {
          product: filterProduct.slice(
            this.offsetCount,
            this.offsetCount + this.perPage
          ),
          prodCount: filterProduct.length,
        };
      } else {
        return {
          product: this.allProducts.slice(
            this.offsetCount,
            this.offsetCount + this.perPage
          ),
          prodCount: this.allProducts.length,
        };
      }
    },
    pageCount() {
      return Math.ceil(this.filterProducts.prodCount / this.perPage);
    },
    offsetCount() {
      return this.currentPage * this.perPage;
    },
    gridView() {
      return localStorage.getItem("gridView");
    },
    totalForShow() {
      let tot = 0;
      if (this.selectedSellProduct.length > 0) {
        this.selectedSellProduct.forEach((order) => {
          tot += parseInt(order.sellPrice) * parseInt(order.frontNumber);
        });
      }
      if (this.shopInfo.tax !== "0") {
        tot += parseInt((tot * (parseInt(this.shopInfo.tax) / 100)).toFixed(0));
      }
      return tot;
    },
    /*selectedSellProduct(){
      return this.$store.getters.getSelectedSellProduct;
    },*/

    selectedSellProductLists() {
      return this.$store.getters.getSelectedSellProductLists;
    },

    showName() {
      return localStorage.getItem("showCustomerName");
    },
    showLogo() {
      return localStorage.getItem("showLogoImage");
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods;
    },
    showPhone() {
      return localStorage.getItem("showCustomerPhone");
    },
    showRefund() {
      return localStorage.getItem("showRefund");
    },
    cashierAcc() {
      return this.$store.getters.getCashierAcc;
    },

    owner() {
      return this.$store.getters.getShopOwner;
    },

    shopInfo() {
      return this.$store.getters.getShopInfo;
    },

    today() {
      return this.$store.getters.getDay;
    },
    thisMonth() {
      return this.$store.getters.getMonth;
    },
    thisYear() {
      return this.$store.getters.getYear;
    },
    dateTime() {
      return this.$store.getters.getDateTime;
    },
  },

  async mounted() {
    this.$store.dispatch("getShopInfo", this.owner);
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getDateTime");
    this.$store.dispatch("getYear");
    this.$store.dispatch("getPaymentMethods", this.owner);
    this.$store.dispatch("getCashierAcc", this.owner);
    this.$store.dispatch("getProductCategories", this.owner);
    // this.$store.dispatch("getAllProducts", this.owner);

    if (!localStorage.getItem("selectedSelection")) {
      console.log(true);
      localStorage.setItem("selectedSelection", 1);
    }
    db.collection(this.owner.city)
      .doc(this.owner.shopId)
      .collection("products")
      .orderBy("itemName", "asc")
      .onSnapshot((snapshot) => {
        // const result = [];
        this.allProducts = [];
        snapshot.forEach((doc) => {
          this.allProducts.push(doc.data());
        });

        if (window.innerWidth < 500) {
          this.showSelectedBox = false;
          this.showDiscountBox = false;
          this.showCustomerBox = false;
        }

        if (localStorage.getItem("selectedProductSearchType")) {
          this.selectedSearchType = localStorage.getItem(
            "selectedProductSearchType"
          );
          //
          // this.focusInput();
        }

        if (localStorage.getItem("voucherStyle")) {
          this.voucherStyle = localStorage.getItem("voucherStyle");
        }

        this.warningMessage = this.$t("message.enter_pay_amount");

        if (localStorage.getItem("selectedSelection")) {
          this.selectedSelectionIndex =
            localStorage.getItem("selectedSelection");
        } else {
          this.selectedSelectionIndex = 1;
        }

        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("sellProducts")
          .where("day", "==", this.today)
          .where("casherCode", "==", this.cashierAcc.accountCode)
          .onSnapshot((snapshot) => {
            this.sellItems = [];
            snapshot.forEach((doc) => {
              this.sellItems.push(doc.data());
            });
          });

        if (this.paymentMethods.length > 0) {
          this.paymentMethods.forEach((doc) => {
            if (doc.paymentType === "Cash") {
              this.selectedPaymentType = doc;
              this.checkoutItem.paymentMethod =
                this.selectedPaymentType.paymentType;
            }
          });
        }

        if (this.selectedSellProductLists.length > 0) {
          this.changeSelection();
          //TODO
          /*this.selectedSellProduct.forEach(order=>{
            db.collection(this.owner.city)
                .doc(this.owner.shopId)
                .collection('products').doc(order.id).onSnapshot(snapshot=>{
              this.orignailStockList.push(parseInt(snapshot.data().stock));
              //alert(this.orignailStockList[0])
    
            })
          });*/
        }

        this.$refs.barcodeSearch.focus();
        //// console.log(result)
      });
  },
  methods: {
    selectCate(name) {
      this.selectedCategory = name;
      this.currentPage = 0;
    },
    onPageChange(page) {
      // console.log(page)
      this.currentPage = page;
    },
    focusInput() {
      //// console.log(this.selectedSearchType)
      this.$refs.barcodeSearch.focus();

      // if(this.selectedSearchType === 'barcode'){
      // }
    },
    confirmItem() {
      // this.$refs.otherSearch.focus()
      this.$refs.barcodeSearch.focus();

      //TODO
      /*this.selectedSellProduct.forEach(order=>{
        db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection('products').doc(order.id).onSnapshot(snapshot=>{
          this.orignailStockList.push(parseInt(snapshot.data().stock))
        })
      })*/
    },

    changeSelection() {
      this.selectedSellProduct = [];
      localStorage.setItem("selectedSelection", this.selectedSelectionIndex);
      if (
        this.selectedSellProductLists.some(
          (p) => p.index === localStorage.getItem("selectedSelection")
        )
      ) {
        let index = this.selectedSellProductLists.findIndex(
          (p) => p.index === localStorage.getItem("selectedSelection")
        );
        // console.log('selection index',index);
        this.selectedSellProduct =
          this.selectedSellProductLists[index].selection;
        // console.log('selected sell product',this.selectedSellProduct);
      }
    },
    setViewOption(value) {
      localStorage.setItem("gridView", value);
      this.gridView = value;
      window.location.reload();
    },

    showSearchBoxHandler() {
      if (window.innerWidth < 500) {
        this.showSearchBox = true;
        this.showSelectedBox = false;
      }
    },
    showSelectedBoxHandler() {
      if (window.innerWidth < 500) {
        this.showSelectedBox = true;
        this.showSearchBox = false;
      }
    },
    clearCartItems() {
      this.$store.commit(
        "clearSelectedSellProduct",
        localStorage.getItem("selectedSelection")
      );
      window.location.reload();
    },
    selectSearchType(type) {
      this.focusInput();
      localStorage.setItem("selectedProductSearchType", type);
      if (this.selectedSearchType) {
        this.selectedSearchType = localStorage.getItem(
          "selectedProductSearchType"
        );
      }
    },
    /* focusNext(e) {
       const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
       const index = inputs.indexOf(e.target);

       if (index < inputs.length) {
         inputs[index + 1].focus();
       }
     },*/
    clearSearch() {
      this.focusInput();

      this.products = [];
      this.search = "";
    },

    searchProductFromServer: debounce(function (e) {
      if (e.target.value !== "") {
        //orderBy(this.selectedSearchType).startAt(this.search).endAt(this.search+"~")
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("products")
          .where("itemName", ">=", e.target.value)
          .where("itemName", "<=", e.target.value + "\uf8ff")
          .onSnapshot((snapshot) => {
            this.products = [];
            snapshot.docs.filter((e) => {
              const isDuplicate = this.products.includes(e.id);
              if (!isDuplicate) {
                this.products.push(e.data());
                return true;
              }
              return false;
            });
          });
      } else {
        this.products = [];
      }
    }, 500),

    searchByBarcode() {
      this.search = this.search.replace(/\s/g, "");
      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("products")
        .where("barcode", "==", this.search)
        .onSnapshot((snapshot) => {
          this.products = [];
          this.selectedProduct = "";
          this.selectedRelations = [];
          this.selectedProduct = snapshot.docs[0].data();
          this.products.push(this.selectedProduct);
          this.showDialog = true;
          this.search = "";
          this.focusInput();
        });
    },

    resetPayment() {
      this.checkoutItem.cashReceived = "0";
      this.checkoutItem.credit = "0";
      this.checkoutItem.change = "0";
      this.payAmount = "";
      this.showWarning = true;
      this.warningMessage = this.$t("message.enter_pay_amount");
      this.sellNowActive = false;
    },

    resetDiscount() {
      this.sellNowActive = false;
      this.checkoutItem.totalPrice =
        parseInt(this.checkoutItem.totalPrice) +
        parseInt(this.checkoutItem.discount);
      if (
        parseInt(this.checkoutItem.cashReceived) >
        parseInt(this.checkoutItem.totalPrice)
      ) {
        this.checkoutItem.change =
          parseInt(this.checkoutItem.cashReceived) -
          parseInt(this.checkoutItem.totalPrice);
      } else {
        this.checkoutItem.credit =
          parseInt(this.checkoutItem.totalPrice) -
          parseInt(this.checkoutItem.cashReceived);
      }
      this.checkoutItem.discount = "0";
      this.newDiscountPrice = "";
      this.newDiscount = "";
    },

    choosePaymentType(type) {
      this.selectedPaymentType = type;
      this.checkoutItem.paymentMethod = type.paymentType;
      this.checkoutItem.paymentId = type.id;
      this.showPaymentToggle = !this.showPaymentToggle;
    },

    getSelectedRelation() {
      if (this.frontUnit !== "Pcs") {
        this.selectedRelation = this.selectedRelations.filter((relation) => {
          return relation.frontUnit === this.frontUnit;
        });
      } else {
        this.selectedRelation = [];
      }
    },
    //selected products
    async selectForCart(product) {
      this.selectedProduct = "";
      this.selectedRelations = [];
      this.selectedProduct = product;
      this.showDialog = true;
      /*await db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(product.id).collection('relation').onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          this.selectedRelations.push(doc.data())
        });
        this.search='';
      })*/
    },

    addToCart(product) {
      let pcsNumber = 1;
      let relationSellPrice = "";
      //If there is relation in the selected product, get the backnumber and sell price
      if (this.selectedRelation.length > 0) {
        pcsNumber = parseInt(this.selectedRelation[0].backNumber);
        relationSellPrice = parseInt(this.selectedRelation[0].sellPrice);
      } else {
        relationSellPrice = product.sellPrice;
      }
      const sameId = this.selectedSellProduct.findIndex((item) => {
        return item.id === product.id;
      });

      if (
        this.selectedSellProduct.some(
          (e) => e.id === product.id && e.frontUnit === this.frontUnit
        )
      ) {
        this.selectedSellProduct.forEach((item) => {
          if (item.frontUnit === this.frontUnit && item.id === product.id) {
            let newStock = this.frontNumber * pcsNumber;
            if (product.stock !== "") {
              if (product.stock >= newStock) {
                // console.log('stock exist and count is less then stock');
                this.selectedSellProduct.forEach((p) => {
                  if (p.id === product.id) {
                    p.stock = parseInt(p.stock) - newStock;
                    // console.log('update count', p.id)
                    // this.$store.commit("updateSelectedSellProduct", newStock,product.id)
                  }
                });
                // this.$store.state.selectedSellProduct[sameId].stock = parseInt(this.selectedSellProduct[sameId].stock)-newStock;
                item.frontNumber =
                  parseInt(item.frontNumber) + parseInt(this.frontNumber);
                item.count += item.backNumber * this.frontNumber;
                this.searchProduct = null;
                this.frontNumber = 1;
                this.frontUnit = "Pcs";
                this.selectedRelations = [];
                this.selectedRelation = "";
                // console.log('same id and same unit', item);
              } else {
                this.searchProduct = null;
                this.frontNumber = 1;
                this.frontUnit = "Pcs";
                this.selectedRelations = [];
                this.selectedRelation = "";
                // console.log('out of stock');
                this.infoModalActive = true;
                this.modalMessage = this.$t("message.out_of_stock");
              }
            } else {
              item.frontNumber =
                parseInt(item.frontNumber) + parseInt(this.frontNumber);
              item.count += item.backNumber * this.frontNumber;
              this.searchProduct = null;
              this.frontNumber = 1;
              this.frontUnit = "Pcs";
              this.selectedRelations = [];
              this.selectedRelation = "";
            }
          }
        });
      } else {
        if (product.stock !== "") {
          if (product.stock >= this.frontNumber * pcsNumber) {
            let newStock = 0;
            // this.selectedSellProduct.stock = product.stock -((this.frontNumber*pcsNumber));
            if (this.selectedSellProduct.some((p) => p.id === product.id)) {
              newStock =
                parseInt(this.selectedSellProduct[sameId].stock) -
                this.frontNumber * pcsNumber;
              this.selectedSellProduct.forEach((p) => {
                if (p.id === product.id) {
                  p.stock = newStock;
                  //// console.log('update count', p.id)
                  // this.$store.commit("updateSelectedSellProduct", newStock,product.id)
                }
              });
              //// console.log('new same id item stock: ',this.selectedSellProduct[sameId].id);
              //// console.log(this.selectedSellProduct[sameId]);
            } else {
              newStock = parseInt(product.stock) - this.frontNumber * pcsNumber;
            }
            let myProduct = {
              frontNumber: this.frontNumber,
              count: pcsNumber * this.frontNumber,
              frontUnit: this.frontUnit,
              backNumber: pcsNumber,
              sellPrice: relationSellPrice,
              itemCode: product.itemCode,
              barcode: product.barcode,
              id: product.id,
              type: product.type,
              images: product.images,
              itemName: product.itemName,
              stock: newStock,
              buyPrice: product.buyPrice,
              description: product.description,
              color: product.color,
              size: product.size,
              weight: product.weight,
              rating: product.rating,
              discount: product.discount,
            };
            this.$store.commit("SET_SELECTED_SELL_PRODUCT", myProduct);
            this.searchProduct = null;
            this.frontNumber = 1;
            this.frontUnit = "Pcs";
            this.selectedRelations = [];
            this.selectedRelation = "";
            //// console.log('different id and different unit');
          } else {
            this.searchProduct = null;
            this.frontNumber = 1;
            this.frontUnit = "Pcs";
            this.selectedRelations = [];
            this.selectedRelation = "";
            //// console.log('out of stock');
            this.infoModalActive = true;
            this.modalMessage = this.$t("message.out_of_stock");
          }
        } else {
          let myProduct = {
            frontNumber: this.frontNumber,
            count: pcsNumber * this.frontNumber,
            frontUnit: this.frontUnit,
            backNumber: pcsNumber,
            sellPrice: relationSellPrice,
            itemCode: product.itemCode,
            barcode: product.barcode,
            id: product.id,
            type: product.type,
            images: product.images,
            itemName: product.itemName,
            stock: "",
            buyPrice: product.buyPrice,
            description: product.description,
            color: product.color,
            size: product.size,
            weight: product.weight,
            rating: product.rating,
            discount: product.discount,
          };
          this.$store.commit("SET_SELECTED_SELL_PRODUCT", myProduct);
          this.searchProduct = null;
          this.frontNumber = 1;
          this.frontUnit = "Pcs";
          this.selectedRelations = [];
          this.selectedRelation = "";
          //// console.log('different id and different unit',this.selectedSellProduct);
        }
      }
      if (this.selectedSellProduct.length > 0) {
        this.selectedSellProduct.forEach((order) => {
          this.totalPrice +=
            parseInt(order.sellPrice) * parseInt(order.frontNumber);
        });
      }
    },

    removeFromCart(order, index) {
      this.focusInput();

      //// console.log('count',order.count);
      let count = parseInt(order.count);
      this.selectedSellProduct.forEach((product) => {
        if (product.id === order.id) {
          product.stock += order.count;
        }
      });
      this.$store.commit("updateSelectedSellProduct", count, order.id);
      this.$store.commit("removeSelectedSellProductByIndex", {
        index: localStorage.getItem("selectedSelection"),
        payload: index,
      });
    },
    //method for input change
    countChangeInput(order) {
      // console.log('order', order);
      let originalStock = 0;
      db.collection(this.shopInfo.city)
        .doc(this.shopInfo.id)
        .collection("products")
        .doc(order.id)
        .get()
        .then((snapshot) => {
          originalStock = parseInt(snapshot.data().stock);
          // console.log('original stock', originalStock);
          if (order.frontNumber !== "") {
            let count =
              parseInt(order.backNumber) * parseInt(order.frontNumber);
            // console.log('count',count);
            if (originalStock >= count) {
              this.selectedSellProduct.forEach((product) => {
                //update stock for all same id items
                if (product.id === order.id) {
                  product.count = count;
                }
              });

              /* let tempStock = order.stock;
             if(order.stock>order.count){
               order.stock = order.stock-order.count;
             }else {
               order.count=0;
               order.stock = tempStock;
             }*/

              // console.log(order)
            } else {
              this.modalMessage = "Out of Stock";
              this.infoModalActive = true;
              order.frontNumber = 1;
            }
          } else {
            order.frontNumber = "";
          }
        });
    },

    increaseCount(order) {
      this.focusInput();

      const index = this.selectedSellProduct.findIndex((item) => {
        return item.id === order.id && item.frontUnit === order.frontUnit;
      });
      if (order.frontUnit !== "Pcs") {
        if (
          this.selectedSellProduct.some(
            (product) =>
              product.id === order.id && product.frontUnit === order.frontUnit
          )
        ) {
          //same id and same unit
          if (order.stock !== "") {
            //for stock exist
            if (this.selectedSellProduct[index].stock >= order.backNumber) {
              this.selectedSellProduct[index].frontNumber++; //increase front number +1
              this.selectedSellProduct[index].count +=
                this.selectedSellProduct[index].backNumber; //increase count for selected item
              this.selectedSellProduct.forEach((product) => {
                //update stock for all same id items
                if (product.id === order.id) {
                  product.stock -= order.backNumber;
                }
              });
            } else {
              this.infoModalActive = true;
              this.modalMessage = this.$t("message.out_of_stock");
            }
          } else {
            this.selectedSellProduct[index].frontNumber++;
            this.selectedSellProduct[index].count +=
              this.selectedSellProduct[index].backNumber;
          }
        }
      } else {
        if (
          this.selectedSellProduct.some(
            (product) =>
              product.id === order.id && product.frontUnit === order.frontUnit
          )
        ) {
          if (order.stock !== "") {
            if (this.selectedSellProduct[index].stock > 0) {
              this.selectedSellProduct.forEach((product) => {
                if (product.id == order.id) product.stock--;
              });
              //// console.log('stock', this.selectedSellProduct[index].stock)
              this.selectedSellProduct[index].frontNumber++;
              this.selectedSellProduct[index].count++;
            } else {
              this.infoModalActive = true;
              this.modalMessage = this.$t("message.out_of_stock");
            }
          } else {
            this.selectedSellProduct[index].frontNumber++;
            this.selectedSellProduct[index].count++;
          }
        }
      }
    },

    decreaseCount(order) {
      this.focusInput();

      const sameIndexAndID = this.selectedSellProduct.findIndex((item) => {
        return item.id === order.id && item.frontUnit === order.frontUnit;
      });
      if (order.frontUnit !== "Pcs") {
        if (
          this.selectedSellProduct.some(
            (product) =>
              product.id === order.id && product.frontUnit === order.frontUnit
          )
        ) {
          this.selectedSellProduct[sameIndexAndID].frontNumber--;
          this.selectedSellProduct[sameIndexAndID].count -= order.backNumber;
          if (this.selectedSellProduct[sameIndexAndID].stock !== "") {
            if (this.selectedSellProduct[sameIndexAndID].frontNumber !== 0) {
              this.selectedSellProduct.forEach((product) => {
                if (product.id === order.id) {
                  product.stock +=
                    this.selectedSellProduct[sameIndexAndID].backNumber;
                }
              });
            } else {
              let lastCount =
                this.selectedSellProduct[sameIndexAndID].backNumber;
              this.selectedSellProduct.forEach((product) => {
                if (product.id === order.id) {
                  product.stock += lastCount;
                }
              });
              // this.selectedSellProduct.splice(sameIndexAndID, 1);
              // this.$store.commit('removeSelectedSellProductByIndex', sameIndexAndID);
              this.$store.commit("removeSelectedSellProductByIndex", {
                index: localStorage.getItem("selectedSelection"),
                payload: sameIndexAndID,
              });
            }
          } else {
            this.selectedSellProduct[sameIndexAndID].frontNumber--;
            this.selectedSellProduct[sameIndexAndID].count -= order.backNumber;
          }
        }
      } else {
        //for pcs
        if (
          this.selectedSellProduct.some(
            (product) =>
              product.id === order.id && product.frontUnit === order.frontUnit
          )
        ) {
          this.selectedSellProduct[sameIndexAndID].frontNumber--;
          this.selectedSellProduct[sameIndexAndID].count--;
          if (this.selectedSellProduct[sameIndexAndID].frontNumber !== 0) {
            this.selectedSellProduct.forEach((product) => {
              if (product.id === order.id) {
                product.stock += 1;
              }
            });
          } else {
            this.selectedSellProduct.forEach((product) => {
              if (product.id === order.id) {
                product.stock += 1;
              }
            });
            this.$store.commit("removeSelectedSellProductByIndex", {
              index: localStorage.getItem("selectedSelection"),
              payload: sameIndexAndID,
            });
          }
        }
      }
    },

    async submitSale() {
      console.log("first");
      this.cancelConfirm();

      this.checkoutItem.casherCode = this.cashierAcc.accountCode;
      this.checkoutItem.paymentMethod = this.selectedPaymentType.paymentType;
      let padNumber = this.sellItems.length + 1;
      this.checkoutItem.vouncherId = this.generateVoucherId(padNumber);

      this.selectedSellProduct.forEach((order) => {
        this.checkoutItem.nameList.push(order.itemName);
        this.checkoutItem.sellPriceList.push(order.sellPrice.toString());
        this.checkoutItem.buyPriceList.push(order.buyPrice.toString());
        this.checkoutItem.itemCodeList.push(order.itemCode);
        this.checkoutItem.productUnitList.push(order.frontUnit);
        this.checkoutItem.productIdList.push(order.id);
        this.checkoutItem.countList.push(order.frontNumber.toString());
        this.totalPrice +=
          parseInt(order.sellPrice) * parseInt(order.frontNumber);
        this.costPerItem.push(
          parseInt(order.sellPrice) * parseInt(order.frontNumber)
        );
        this.pcsList.push(parseInt(order.count));
      });

      let nextTotal = 0;
      if (this.shopInfo.tax !== "0") {
        this.checkoutItem.tax = (
          this.totalPrice *
          (parseInt(this.shopInfo.tax) / 100)
        ).toFixed(0);
        nextTotal = parseInt(this.totalPrice) + parseInt(this.checkoutItem.tax);
        this.checkoutItem.totalPrice = nextTotal.toString();
      } else {
        this.checkoutItem.totalPrice = this.totalPrice.toString();
      }
      this.showCart = true;
      this.$store.commit(
        "SET_SELECTED_VOUCHER_PRODUCT",
        this.selectedSellProduct
      );
      this.$store.commit("SET_VOUCHER", this.checkoutItem);
      this.$router.push({ name: "Voucher" });
    },

    generateVoucherId(padNumber) {
      const date = new Date();
      let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
      let twoDigitMonth = new Intl.DateTimeFormat("en", {
        month: "2-digit",
      }).format(date);
      let twoDigitYear = date.getFullYear().toString().slice(-2);
      let vId = "";
      //TODO: must be used owner selected casherCode
      vId =
        this.shopInfo.shopCode +
        this.cashierAcc.accountCode +
        twoDigitYear +
        twoDigitMonth +
        day +
        padNumber.toString().padStart(5, "0");
      return vId;
    },

    async showSearchProduct() {
      this.showSearch = true;
      this.selectedCategory = "All";
    },

    //Selecting search item
    cancelConfirm() {
      this.costPerItem = [];
      this.checkoutItem.month = "";
      this.checkoutItem.day = "";
      this.checkoutItem.year = "";
      this.checkoutItem.dateTime = "";
      this.checkoutItem.time = "";
      this.checkoutItem.discount = "0";
      this.checkoutItem.vouncherId = "";
      this.checkoutItem.customerId = "";
      this.checkoutItem.customerPhone = "";
      this.checkoutItem.customerAddress = "";
      this.checkoutItem.customerName = "";
      this.checkoutItem.totalPrice = "";
      this.checkoutItem.nameList = [];
      this.checkoutItem.countList = [];
      this.checkoutItem.sellPriceList = [];
      this.checkoutItem.buyPriceList = [];
      this.checkoutItem.productIdList = [];
      this.checkoutItem.itemCodeList = [];
      this.checkoutItem.productUnitList = [];
      this.checkoutItem.credit = "";
      this.checkoutItem.cashReceived = "0";
      this.checkoutItem.paymentMethod = "";
      this.checkoutItem.change = "0";
      this.pcsList = [];
      // this.selectedPaymentType='';
      this.selectedCustomer = "";
    },

    clearCart() {
      this.totalPrice = 0;
      this.newDiscount = 0;
      this.focusInput();
    },

    confirm() {
      //this.loadingActive = true;
      this.confirmActive = true;
      this.$refs.barcodeSearch.focus();
      //console.log(this.checkoutItem)
      // db.collection(this.owner.city).doc(this.owner.shopId).collection('promotionCode').doc(this.deleteId).delete().then(()=>{
      //   //this.loadingActive = false;
      //   this.modalMessage='Deleted';
      //   this.infoModalActive=true;
      // });
    },

    cancel() {
      this.confirmActive = !this.confirmActive;
      this.focusInput();
    },

    closeModal() {
      this.infoModalActive = !this.infoModalActive;
      this.focusInput();
    },

    closeDialog() {
      this.focusInput();
      this.showDialog = !this.showDialog;
    },

    getRandomStr() {
      const chars = "1234567890";
      let result = "";
      for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },

    async printThis() {
      // console.log("printing..");
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", `${this.checkoutItem.vouncherId}.png`);
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();

      // console.log("done");
    },
  },
};
</script>

<style scoped>
tr,
td,
th {
  color: var(--text-color) !important;
  background-color: var(--white) !important;
}

.table-bottom {
  background-color: var(--light);
}

.table-bottom td {
  border-radius: 0px;
  border: none !important;
}

.table-voucher thead th,
.table-voucher tbody td {
  border: 2px solid var(--primary) !important;
}

.product-card {
  height: 200px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  border-radius: var(--border-radius);
  background-color: var(--white);
}

.product-card:hover {
  background-color: var(--side-bar-bg) !important;
}

.product-box {
  position: sticky;
  padding-bottom: 20px;
  z-index: 1000;
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.category-list:hover {
  background-color: var(--primary);
}

.modal-body-box {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.voucher-box {
  height: 80vh;
  border-radius: var(--border-radius);
  overflow-x: hidden;
  overflow-y: scroll;
}

.active-btn {
  background-color: var(--btnLight);
}

.selected-box {
  height: inherit;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
}

.content-box {
  top: 100px;
}

.customer-box {
  height: 79vh;
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
  border-radius: var(--border-radius) !important;
}

.discount-box {
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
  border-radius: var(--border-radius) !important;
}

.category-box {
  padding: 0;
  z-index: 1000;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  color: var(--text-color);
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  height: inherit;
  align-items: center;
}

.dropdown-item {
  cursor: pointer;
}

.category-box ul {
  padding: 5px;
  height: inherit;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.category-list {
  cursor: pointer;
  border: none !important;
  border-bottom: 1px solid;
  height: inherit;
  min-width: 60px;
  text-align: center;
  display: inline;
  padding: 5px;
  margin-top: 4px;
  margin-left: 5px;
  border-radius: var(--border-radius);
  background-color: var(--btnLight);
  transition: 0.3s;
}

.category-list-active {
  background-color: var(--softPrimary) !important;
}

.voucher-list-solid {
  border-top: 2px solid black;
}

.voucher-list-top {
  border-top: 2px dotted black;
}

.voucher-list-bottom {
  border-bottom: 2px dotted black;
}

.btn-group {
  width: 100%;
}

.check-btn {
  background-color: var(--secondary);
  border: none !important;
  width: 80px !important;
}

.btn-group .btn {
  border: 1px solid var(--primary);
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0px !important;
}

.btn-check {
  border: none !important;
  border-radius: var(--border-radius) !important;
}

thead,
tbody {
  border: none !important;
  color: var(--dark);
}

.modal-header {
  padding: 2px !important;
  margin: 3px !important;
}

.modal-body {
  padding: 5px !important;
}

@media only screen and (max-width: 1030px) {
}

.alert-warning {
  border-radius: var(--border-radius) !important;
}

.customer-card {
  background-color: var(--light) !important;
  transition: 0.3s;
  cursor: pointer;
}

.customer-card:hover {
  background-color: var(--softPrimary) !important;
}

.activeCustomer {
  background-color: var(--softPrimary) !important;
}

.voucher-modal {
  height: 70vh;
  overflow-y: scroll;
}

@media only screen and (max-width: 800px) {
  .category-box {
    width: 30%;
  }

  .selected-box {
    height: 70vh;
  }
}

.voucher-modal {
  height: 80vh;
}

@media only screen and (max-width: 500px) {
  .voucher-modal {
    height: inherit !important;
  }

  .voucher-box {
    height: inherit !important;
  }

  .modal-body-box {
    height: inherit !important;
  }

  .modal-body-box {
    height: 75vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .category-box {
    width: 94%;
  }
}
</style>
