<template>
  <div class="container mt-5">
    <div class="row">
      <h1>forgot password</h1>

    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>