<template>
  <div>
    <div class="container-fluid " style="background-color: var(--background-color);color: var(--text-color);">
      <div class="row">
        <div class="col-lg-2 p-0">
          <AdminSidebar/>
        </div>
        <div class="col-12 col-lg-10 content-box">
          <div class="row">
            <div class="d-flex mt-2 align-items-center">
              <button class="btn" style="color: var(--text-color);" @click="$router.back()"><i class="fas fa-chevron-left"> </i></button>
              <span class="fs-3">Orders</span>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <router-link :to="{name:'OrderList', params: {type:'pending'}}" class="text-decoration-none">
                <div class="card">
                  <div class="card-body text-center">
                    <div class="icon-badge p-2"><i class="fas fa-clipboard-list fa-2x"></i></div>
                    <h5 class="">Online Order Management</h5>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <router-link :to="{name:'LiveOrderList',params:{live_id:'',order_status:'pending'}}" class="text-decoration-none">
                <div class="card">
                  <div class="card-body text-center">
                    <div class="icon-badge p-2"><i class="fas fa-clipboard-list fa-2x"></i></div>
                    <h5 class=""><span class="bg-danger text-light p-1 icon-badge" >Live</span> Order Management</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AdminSidebar from "@/components/AdminSidebar";
export default {
  components:{AdminSidebar}
}
</script>

<style scoped>
.card, .card-body{
  background-color: var(--light) !important;
}
.content-box{
  margin-top: 50px;
}
.icon-badge{
  background-color: var(--side-bar-bg);
  border-radius: var(--border-radius);
  display: inline-block;
  justify-content: center;
  align-items: center;
}
</style>