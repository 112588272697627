<template>
  <div class="container-fluid p-0" style="background-color: var(--background-color);  color: var(--text-color); overflow-x: hidden;" >
    <div class="row">
      <AdminSidebar/>
      <div class="col-12 col-lg-10 px-3 mt-5" style="overflow-x:hidden; ">
        <slot />
      </div>
    </div>
  </div>

</template>

<script>
import AdminSidebar from "@/components/AdminSidebar";
export default {
  components: {AdminSidebar}
}
</script>

<style scoped>

</style>