<template>
  <div class="container-fluid " style="background-color: var(--background-color);">
    <div class="row">
      <div class="col-lg-2 p-0">
        <AdminSidebar />
      </div>
      <div class="col-12 col-lg-10 mt-5">
        <div class="row pt-3">
          <div class="d-flex align-items-center" style="color: var(--text-color);">
            <button @click="$router.back()" class=" btn fs-5" style="color: var(--text-color);" ><i class="fas fa-chevron-left"> </i>
            </button>
            <span class="fs-3">{{ $t('message.inventory') }}</span>
          </div>
          <div class="col-6 col-lg-4 col-md-6 mb-3">
            <router-link :to="{name:'ProductList',params: {category:'All'}}" class="text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 "><i class="fas fa-layer-group fa-2x"></i></div>
                  <h6 class=""> {{$t('message.product_management')}}</h6>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 col-md-6 mb-3" v-if="shopInfo.restaurant === 'yes'">
            <router-link :to="{name:'TableList',params: {category:'All'}}" class="text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 "><i class="fas fa-chair fa-2x"></i></div>
                  <h6 class=""> {{$t('message.table_management')}}</h6>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 col-md-6 mb-3">
            <router-link :to="{name:'PurchaseVoucher'}" class="text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 "><i class="fas fa-shopping-cart fa-2x"></i></div>
                  <h6 class=""> {{$t('message.purchase_lists')}}</h6>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 col-md-6 mb-3" v-if="shopInfo.packageStatus==='ultra' || shopInfo.packageStatus==='premium'">
            <router-link :to="{name:'LiveHistory',params:{session_id:''}}" class="text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <span class="badge bg-danger  my-2">Live</span>
                  </div>
                  <h6 class=""> {{$t('message.live_management')}}</h6>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 col-md-6 mb-3">
            <router-link :to="{name:'ShopOwnerCategoryManagement'}" class="text-decoration-none ">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <i class="far fa-rectangle-list fa-2x"></i>
                  </div>
                  <h6 class=""> {{ $t('message.categories_management') }}</h6>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-6 col-lg-4 col-md-6 mb-3" v-if="shopInfo.packageStatus==='ultra' || shopInfo.packageStatus==='premium'">
            <router-link :to="{name:'OnlinePromotion'}" class=" text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <i class="fas fa-gift fa-2x"></i>
                  </div>
                  <h6 class=""> {{$t('message.promotion_management')}}</h6>
                </div>
              </div>
            </router-link>
          </div>
          <!--<div class="col-6 col-lg-4 col-md-6 mb-3"  v-if="cashierAcc.accountLevel==='admin'">
            <router-link :to="{name:'PaymentMethods'}" class=" text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <i class="fas fa-money-check-dollar fa-2x"></i>
                  </div>
                  <h6 class=""> {{ $t('message.payment_management') }}</h6>
                </div>
              </div>
            </router-link>
          </div>-->

          <div class="col-6 col-lg-4 col-md-6 mb-3" v-if="shopInfo.packageStatus==='ultra' || shopInfo.packageStatus==='premium'">
            <router-link :to="{name:'OwnerDeliveryService'}" class=" text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <i class="fas fa-truck fa-2x"></i>
                  </div>
                  <h6 class="">{{$t('message.delivery_management')}}</h6>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-6 col-lg-4 col-md-6 mb-3" v-if="shopInfo.packageStatus==='ultra' || shopInfo.packageStatus==='premium'">
            <router-link :to="{name:'ShopOwnerMemberManagement'}" class=" text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <i class="fa-solid fa-address-card fa-2x"></i>
                  </div>
                  <!-- <h6 class="">{{$t('message.delivery_management')}}</h6> -->
                  <h6 class="">Member Card Management</h6>
                </div>
              </div>
            </router-link>
          </div>

          <!--<div class="col-6 col-lg-4 col-md-6 mb-3"  v-if="cashierAcc.accountLevel==='admin'">
            <router-link :to="{name:'Cashier'}" class=" text-decoration-none">
              <div class="card">
                <div class="card-body text-center">
                  <div class="icon-badge p-2 ">
                    <i class="fas fa-users-gear fa-2x"></i>
                  </div>
                  <h6 class="">{{$t('message.cashier_management')}}</h6>
                </div>
              </div>
            </router-link>

          </div>-->
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import AdminSidebar from "@/components/AdminSidebar";
export default {
  components:{AdminSidebar},
  data(){
    return{
      loadingActive:false,
      orders:[],
    }
  },
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },
    shopInfo(){
      return this.$store.getters.getShopInfo;
    },
    cashierAcc(){
      return this.$store.getters.getCashierAcc;
    }
  },
  created() {
    if(this.owner){
      this.$store.dispatch("getShopInfo", this.owner)
      // this.$store.dispatch("getAllProducts",this.owner);
      this.$store.dispatch("getCashier", this.owner);
      this.$store.dispatch("getPaymentTypes");
    }
  }
}
</script>
<style scoped>
h6{
  color: var(--dark);
}
.card{
  color: var(--text-color) !important;
  padding: 10px;
}
h6{
  color: var(--text-color);
}
.card-body{
  background-color: var(--light) !important;
}
.card:hover{
  background-color: var(--btnLight);
  transform: scale(1.05);
}
.icon-badge{
  border-radius: var(--border-radius);
  background-color: var(--side-bar-bg);
  display: inline-block;
  justify-content: center;
  align-items: center;
}


ul{
  text-align: start;
  list-style-type: none;
}
ol{
  text-align: start;
}
</style>