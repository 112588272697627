import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ErrorDisplay from "../views/ErrorDisplay";
import ForgotPassword from "../views/ForgotPassword";
import UserProfile from "../views/UserProfile";
import ShopRegistration from "../views/admin/ShopRegistration";
import Dashboard from "../views/shop-owner/Inventory";
import ShopHome from "../views/shop-owner/Home";
import OwnerProfile from "../views/shop-owner/Profile";
import { getAuth } from "firebase/auth";
import "firebase/compat/auth";
import ShopOwnerLogin from "../views/shop-owner/ShopOwnerLogin";
import ItemCreate from "../views/product/ItemCreate";
import ItemList from "../views/product/ItemList";
import ShopOwnerCategoryManagement from "../views/category/ShopOwnerCategoryManagement";
import ShopOwnerMemberManagement from "../views/category/ShopOwnerMemberManagement";
import ItemEdit from "../views/product/ItemEdit";
import ItemDetil from "../views/product/ItemDetil";
import UsersControl from "../views/admin/UsersControl";
import ShopManagement from "../views/admin/ShopManagement";
import AdminHome from "../views/admin/Home";
import ShopEdit from "../views/admin/ShopEdit";
import ShopType from "../views/category/ShopType";
import DisplayPromoType from "../views/category/DisplayPromoType";
import CityCategory from "../views/category/CityCategory";
// import SellProduct from "../views/shop-owner/SellProduct";
import TermsOfService from "../views/TermsOfService";
import ShopInfo from "../views/ShopInfo";
import ProductListsForLive from "../views/live-sale/ProductListsForLive";
import NotFound from "@/NotFound";
import LiveHistory from "../views/live-sale/LiveHistory";
import Orders from "../views/shop-owner/Orders";
import OrderList from "../views/shop-owner/OrderList";
import OrderDetails from "@/views/shop-owner/OrderDetails";
import CreatePaymentTypes from "@/views/category/CreatePaymentTypes";
import PaymentMethods from "@/views/shop-owner/PaymentMethods";
import CustomerOrderDetails from "@/views/CustomerOrderDetails";
import CustomerLiveOrderDetails from "@/views/CustomerLiveOrderDetails";
import SellReport from "@/views/shop-owner/SellReport";
import GeneralSetting from "../views/shop-owner/GeneralSetting";
import CreateDeliveryService from "@/views/delivery/CreateDeliveryService";
import OwnerDeliveryService from "@/views/shop-owner/OwnerDeliveryService";
import AdminLogin from "@/views/admin/AdminLogin";
import store from "@/store";
import UserLogin from "@/views/UserLogin";
import FinancialDashboard from "../views/finanacial/FinancialDashboard";
import ShopDetail from "@/views/admin/ShopDetail";
import About from "@/views/About";
import Contact from "@/views/Contact";
import OnlinePromotion from "@/views/promotion/OnlinePromotion";
import ExpireLists from "@/views/shop-owner/ExpireLists";
import Customer from "@/views/finanacial/Customer";
import ProductSellReport from "@/views/shop-owner/ProductSellReport";
import WarehouseManagement from "@/views/warehouse/WarehouseManagement";
import WarehouseProductDetail from "@/views/warehouse/WarehouseProductDetail";
import Supplier from "@/views/finanacial/Supplier";
import TransferRecord from "@/views/warehouse/TransferRecord";
import WareHouseTransfer from "@/views/admin/WareHouseTransfer";
import CustomerDetail from "@/views/finanacial/CustomerDetail";
import PaymentDetail from "@/views/shop-owner/PaymentDetail";
import Cashier from "@/views/shop-owner/Cashier";
import Expneses from "@/views/finanacial/Expneses";
import Incomes from "@/views/finanacial/Incomes";
import Refund from "@/views/shop-owner/Refund";
import BranchList from "@/views/branch/BranchList";
import BranchDetail from "@/views/branch/BranchDetail";
import SellProduct2 from "@/views/shop-owner/SellProduct2";
import Voucher from "@/views/shop-owner/Voucher";
import PurchaseVoucher from "@/views/shop-owner/PurchaseVoucher";
import PurchaseList from "@/views/shop-owner/PurchaseList";
import AddStock from "@/views/admin/product-manage/AddStock";
import Create from "@/views/admin/product-manage/Create";
import Detail from "@/views/admin/product-manage/Detail";
import Edit from "@/views/admin/product-manage/Edit";
import AdminSellReport from "@/views/admin/sell-report/AdminSellReport";
import AdminProductSellReport from "@/views/admin/sell-report/AdminProductSellReport";
import AdminRefund from "@/views/admin/sell-report/AdminRefund";
import AdminCustomerDetail from "@/views/admin/sell-report/AdminCustomerDetail";
import SellProductRestaurant from "@/views/restaurant/SellProductRestaurant";
import TableList from "@/views/restaurant/table/TableList";
import TableCreate from "@/views/restaurant/table/TableCreate";
import TableUpdate from "@/views/restaurant/table/TableUpdate";
import RestaurantVoucher from "@/views/restaurant/RestaurantVoucher";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/terms-of-services",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  //customer
  {
    path: "/shop/:city/:shop_type",
    props: true,
    // props: route => ({city:route.query.city, shop_type:route.query.shop_type}),
    name: "ShowShopByType",
    component: () =>
      import(/* webpackChunkName: "Shops" */ "../views/ShowShopByType"),
  },
  {
    path: "/home/:city",
    props: true,
    name: "Shops",
    component: () => import(/* webpackChunkName: "Shops" */ "../views/Shop"),
  },
  {
    path: "/:city/:shop_name/:product_type",
    //props: route => ({city:route.query.city, shop_id:route.query.shop_id,product_type:route.query.product_type}),
    name: "ShopInfo",
    component: ShopInfo,
  },
  {
    path: "/:city/:shop_name/live-sale",
    //props: route => ({city:route.query.city, shop_id:route.query.shop_id,product_type:route.query.product_type}),
    name: "LiveSales",
    component: () =>
      import(/* webpackChunkName: "ShopInfo" */ "../views/LiveSales"),
  },
  {
    path: "/:city/:shop_name/product/:product_id",
    //props: route => ({city:route.query.city, shop_id:route.query.shop_id, product_id:route.query.product_id}),
    name: "ProductInfo",
    component: () =>
      import(/* webpackChunkName: "ProductInfo" */ "../views/ProductInfo"),
  },

  //Owner
  {
    path: "/shop-owner",
    name: "ShopHome",
    component: ShopHome,
    meta: {
      title: "Shop Home",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/sell-product",
    name: "SellProduct2",
    component: SellProduct2,
    meta: {
      title: "Sell Product",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/sell-product-restaurant",
    name: "SellProductRestaurant",
    component: SellProductRestaurant,
    meta: {
      title: "Sell Product Restaurant",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/voucher-setup",
    name: "Voucher",
    component: Voucher,
    meta: {
      title: "Sell Voucher",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/restaurant-voucher-setup",
    name: "RestaurantVoucher",
    component: RestaurantVoucher,
    meta: {
      title: "Sell RestaurantVoucher",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/sell-Report/:date_type/:selected_date?",
    name: "SellReport",
    component: SellReport,
    meta: {
      title: "Sell Report",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/product-sell-Report",
    name: "ProductSellReport",
    component: ProductSellReport,
    meta: {
      title: "Product Sell Report",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/dashboard",
    name: "ShopDashboard",
    component: Dashboard,
    meta: {
      title: "Owner Dashboard",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/products/:category",
    props: true,
    name: "ProductList",
    component: ItemList,
    meta: {
      title: "Products",
      shopAuth: true,
    },
  },
  //  table start
  {
    path: "/shop-owner/table/:category",
    props: true,
    name: "TableList",
    component: TableList,
    meta: {
      title: "Table",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/create-table/:category?",
    props: true,
    name: "TableCreate",
    component: TableCreate,
    meta: {
      title: "Create Product",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/update-table/:table_id",
    props: true,
    name: "TableUpdate",
    component: TableUpdate,
    meta: {
      title: "Update Product",
      shopAuth: true,
    },
  },
  //  Table end
  {
    path: "/shop-owner/purchase-voucher",
    props: true,
    name: "PurchaseVoucher",
    component: PurchaseVoucher,
    meta: {
      title: "PurchaseVoucher",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/purchase-lists",
    props: true,
    name: "PurchaseList",
    component: PurchaseList,
    meta: {
      title: "PurchaseList",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/expire-lists",
    name: "ExpireLists",
    component: ExpireLists,
    meta: {
      title: "Expire Lists",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/create-product/:category?",
    props: true,
    name: "ProductCreate",
    component: ItemCreate,
    meta: {
      title: "Create Product",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/product-edit/:product_id",
    props: true,
    name: "ProductEdit",
    component: ItemEdit,
    meta: {
      title: "Edit Product",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/product-detail/:product_id",
    props: true,
    name: "ProductDetail",
    component: ItemDetil,
    meta: {
      title: "Product Detail",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/create-category",
    name: "ShopOwnerCategoryManagement",
    component: ShopOwnerCategoryManagement,
    meta: {
      title: "Create Category",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/create-member",
    name: "ShopOwnerMemberManagement",
    component: ShopOwnerMemberManagement,
    meta: {
      title: "Create Member",
      shopAuth: true,
    },
  },
  /*{
    path: '/shop-owner/promotion',
    name:'CreatePromotionCategory',
    component: CreatePromotionCategory,
    meta:{
      title:"In Shop Promotion",
      shopAuth: true,
    }
  },*/
  {
    path: "/shop-owner/online-promotion",
    name: "OnlinePromotion",
    component: OnlinePromotion,
    meta: {
      title: "Online Promotion",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/payment-method",
    name: "PaymentMethods",
    component: PaymentMethods,
    meta: {
      title: "Payment Methods",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/payment-method/:payment_id",
    name: "PaymentDetail",
    component: PaymentDetail,
    meta: {
      title: "Payment Details",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/delivery-services",
    name: "OwnerDeliveryService",
    component: OwnerDeliveryService,
    meta: {
      title: "Delivery Services",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/general-setting",
    name: "GeneralSetting",
    component: GeneralSetting,
    meta: {
      title: "General Setting",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/profile",
    name: "OwnerProfile",
    component: OwnerProfile,
    meta: {
      title: "Owner Profile",
      shopAuth: true,
    },
  },

  //orders
  {
    path: "/shop-owner/orders",
    name: "Orders",
    component: Orders,
    meta: {
      title: "Orders",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/online-orders-detail/:order_id",
    props: true,
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      title: "Order Detail",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/online-orders/:type",
    name: "OrderList",
    component: OrderList,
    meta: {
      title: "Online Orders",
      shopAuth: true,
    },
  },
  /*  {
    path: '/shop-owner/orders/online-orders',
    name:'OnlineOrders',
    component: OrderList,
    meta:{
      title:"Online Orders",
      shopAuth: true,
    }
  },*/
  /*  {
    path: '/shop-owner/orders/online-orders',
    name:'OnlineOrders',
    component: OrderList,
    meta:{
      title:"Online Orders",
      shopAuth: true,
    }
  },*/

  //Live Sale
  //start live order
  {
    path: "/shop-owner/live-order/:live_id?/:order_status",
    // props: route => ({live_id:route.query.live_id, live_name:route.query.live_name, date_types:route.query.date_types,date:route.query.date}),
    name: "LiveOrderList",
    component: () =>
      import(
        /* webpackChunkName: "LiveOrderList" */ "../views/shop-owner/LiveOrderList"
      ),
    meta: {
      title: "Live Orders",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/live-order-detail/:order_id",
    props: true,
    name: "LiveOrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "LiveOrderDetails" */ "../views/shop-owner/LiveOrderDetails"
      ),

    // component: LiveOrderDetails,
    meta: {
      title: "Live Orders Details",
      shopAuth: true,
    },
  },
  //end live order

  {
    path: "/shop-owner/live-sale/products/:session_id?",
    name: "ProductListsForLive",
    component: ProductListsForLive,
    meta: {
      title: "Live Products",
      shopAuth: true,
    },
  },
  /*{
    path: '/shop-owner/live-sale/now',
    name:'liveControl',
    component: LiveControl,
    meta:{
      title:"Owner Live",
      shopAuth: true,
    }
  },*/
  {
    path: "/shop-owner/live-session/:session_id?",
    // props: route => ({live_id:route.query.live_id}),
    name: "LiveHistory",
    component: LiveHistory,
    meta: {
      title: "Owner Live",
      shopAuth: true,
    },
  },

  {
    path: "/owner-login",
    name: "ShopOwnerLogin",
    component: ShopOwnerLogin,
  },
  {
    path: "/shop-owner/financial",
    name: "FinancialDashboard",
    component: FinancialDashboard,
    meta: {
      title: "Financial Dashboard",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/financial/customer/:customer_id?/voucher/:voucher_id?",
    props: true,
    name: "CustomerDetail",
    component: CustomerDetail,
    meta: {
      title: "Customer Detail",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/refund/customer/:customer_id?/voucher/:voucher_id?",
    props: true,
    name: "Refund",
    component: Refund,
    meta: {
      title: "Refund",
      shopAuth: true,
    },
  },

  {
    path: "/shop-owner/warehouses/:branch_city?/:branch_id?",
    name: "WarehouseManagement",
    component: WarehouseManagement,
    meta: {
      title: "Warehouse",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/warehouses/product",
    name: "WarehouseProductDetail",
    component: WarehouseProductDetail,
    meta: {
      title: "Warehouse product",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/warehouses/transfer-record",
    name: "TransferRecord",
    component: TransferRecord,
    meta: {
      title: "Transfer Record",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/branches",
    name: "Branches",
    component: BranchList,
    meta: {
      title: "Branches",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/branches/:city/:branch_id",
    props: true,
    // props: route => ({city:route.query.city, branch_id:route.query.branch_id}),
    name: "BranchDetail",
    component: BranchDetail,
    meta: {
      title: "Branch Detail",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/customer",
    name: "Customer",
    component: Customer,
    meta: {
      title: "customer",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/supplier",
    name: "Supplier",
    component: Supplier,
    meta: {
      title: "Supplier",
      shopAuth: true,
    },
  },
   {
    path: "/shop-owner/expenses",
    name: "Expenses",
    component: Expneses,
    meta: {
      title: "Expenses",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/incomes",
    name: "Incomes",
    component: Incomes,
    meta: {
      title: "Incomes",
      shopAuth: true,
    },
  },
  {
    path: "/shop-owner/cashiers",
    name: "Cashier",
    component: Cashier,
    meta: {
      title: "Cashier",
      shopAuth: true,
    },
  },

  //admin
  {
    path: "/admin-login",
    name: "AdminLogin",
    component: AdminLogin,
    meta: {
      title: "Admin Home",
      adminAuth: false,
    },
  },
  {
    path: "/admin/home",
    name: "AdminHome",
    component: AdminHome,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management",
    name: "ShopManagement",
    component: ShopManagement,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/warehouse",
    name: "WarehouseTransfer",
    component: WareHouseTransfer,
    meta: {
      title: "Admin Warehouse",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/shop-type",
    name: "ShopType",
    component: ShopType,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/promo-display-type",
    name: "DisplayPromoType",
    component: DisplayPromoType,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/city-category",
    name: "CityCategory",
    component: CityCategory,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/user/control",
    name: "UserControl",
    component: UsersControl,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/registration",
    name: "ShopRegistration",
    component: ShopRegistration,
    meta: {
      title: "Admin Home",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/detail/:shop_id",
    props: true,
    name: "ShopDetail",
    component: ShopDetail,
    meta: {
      title: "Shop Detail",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/edit/:shop_id",
    props: true,
    name: "ShopEdit",
    component: ShopEdit,
    meta: {
      title: "Shop Edit",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/products",
    props: true,
    name: "AddStock",
    component: AddStock,
    meta: {
      title: "Shop Stock",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/create-product",
    props: true,
    name: "Create",
    component: Create,
    meta: {
      title: "Add New Product",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/product-detail/:product_id",
    props: true,
    name: "Detail",
    component: Detail,
    meta: {
      title: "Add New Product",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/product-edit/:product_id",
    props: true,
    name: "Edit",
    component: Edit,
    meta: {
      title: "Edit Product",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/sell-report",
    props: true,
    name: "AdminSellReport",
    component: AdminSellReport,
    meta: {
      title: "SellReport",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/sell-product-report/customer/:customer_id/voucher/:voucher_id",
    props: true,
    name: "AdminProductSellReport",
    component: AdminProductSellReport,
    meta: {
      title: "Admin Product SellReport",
      adminAuth: true,
    },
  },

  {
    path: "/admin/shop-management/:shop_id/refund-voucher/:voucher_id/customer/:customer_id?",
    props: true,
    name: "AdminRefund",
    component: AdminRefund,
    meta: {
      title: "Admin Refund",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/:shop_id/customer-detail/:voucher_id/customer/:customer_id?",
    props: true,
    name: "AdminCustomerDetail",
    component: AdminCustomerDetail,
    meta: {
      title: "Admin Customer Detail",
      adminAuth: true,
    },
  },

  {
    path: "/admin/shop-management/payment-method",
    name: "CreatePaymentTypes",
    component: CreatePaymentTypes,
    meta: {
      title: "Shop Payment",
      adminAuth: true,
    },
  },
  {
    path: "/admin/shop-management/delivery",
    name: "CreateDeliveryService",
    component: CreateDeliveryService,
    meta: {
      title: "Shop Delivery",
      adminAuth: true,
    },
  },
  //end admin

  //customer
  {
    path: "/profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      title: "User Profile",
      requiresAuth: true,
    },
  },
  {
    path: "/user-login",
    name: "UserLogin",
    component: UserLogin,
    meta: {
      title: "User Login",
      requiresAuth: false,
    },
  },

  {
    path: "/user/profile/orders/:order_id",
    name: "CustomerOrderDetails",
    props: true,
    component: CustomerOrderDetails,
    meta: {
      title: "User Order",
      requiresAuth: true,
    },
  },
  {
    path: "/user/profile/live-orders/:order_id",
    name: "CustomerLiveOrderDetails",
    props: true,
    component: CustomerLiveOrderDetails,
    meta: {
      title: "User Live Order",
      requiresAuth: true,
    },
  },

  //end customer

  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    components: ErrorDisplay,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    components: ForgotPassword,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach(async (to, from, next) => {
  //const currentUser = await firebase.getCurrentUser();
  store.dispatch("getCurrentShop");
  const shopOwner = store.getters.getShopOwner;
  const shopAuth = to.matched.some((record) => record.meta.shopAuth);
  if (shopAuth && !shopOwner) {
    next({ name: "ShopOwnerLogin" });
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/");
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  //const currentUser = await firebase.getCurrentUser();
  //const currentUser = getAuth().currentUser;
  const admin = store.getters.getAdmin;
  const requiresAuth = to.matched.some((record) => record.meta.adminAuth);
  // console.log(currentUser);
  if (requiresAuth && !admin) {
    next({ name: "AdminLogin" });
  } else {
    next();
  }
});

export default router;
