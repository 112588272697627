<template>
  <div class="container">
    <div class="row mt-5 pt-5">
      <h1>About Us</h1>
      <div class="col-4 col-lg-3 col-md-3">
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>