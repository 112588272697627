<template>
  <div v-if="currentOrder" style="background-color: var(--background-color);">
    <Loading v-if="loadingActive"/>
    <InfoDialog v-if="infoModalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
    <div class="container mt-5" style="background-color: var(--light);color: var(--text-color);">
      <div class="row justify-content-center pt-3">
        <div class="col-12 col-lg-6 col-md-6 mx-auto rounded">
          <div class="d-flex align-items-center">
            <router-link :to="{name:'UserProfile'}" style="color: var(--text-color);" class="btn btn--white"><i class="fas fa-chevron-left"></i></router-link>
            <span class="fs-5 fw-bold">{{$t('message.order_details')}}</span>
          </div>

          <div class="d-flex p-2 mt-3 rounded justify-content-between" style="background-color: var(--side-bar-bg);">
            {{ $t('message.date') }}: {{currentOrder.time.toDate().toLocaleDateString()}}
            <span><i class="far fa-clock"></i>{{currentOrder.time.toDate().toLocaleTimeString('en')}}</span>
          </div>

<!--Status Box-->
          <div class="rounded my-1 p-1" style="background-color: var(--light);">
            <div v-if="currentOrder.orderStatus==='pending'" class="d-flex align-items-center">
              <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                <span class=" "><i class="fas fa-circle-check "></i></span>
                <span>{{ $t('message.pending') }}</span>
              </div>
              <div class="loading-bar" style="background-color: var(--primary);">
              </div>
              <div class="d-flex flex-column text-center status-box" >
                <span><i class="fas fa-spinner "></i></span>
                <span>{{ $t('message.confirm') }}</span>
              </div>
              <div class="loading-bar" >
              </div>
              <div class="d-flex flex-column text-center status-box">
                <span><i class="fas fa-spinner "></i></span>
                <span>{{ $t('message.paid') }}</span>
              </div>
            </div>
            <div v-if="currentOrder.orderStatus==='confirmed'" class="d-flex align-items-center">
              <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                <span class=" "><i class="fas fa-circle-check "></i></span>
                <span>{{ $t('message.pending') }}</span>
              </div>
              <div class="loading-bar" style="background-color: var(--primary);">
              </div>
              <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                <span><i class="fas fa-circle-check "></i></span>
                <span>{{ $t('message.confirm') }}</span>
              </div>
              <div class="loading-bar" style="background-color: var(--primary);">
              </div>
              <div class="d-flex flex-column text-center status-box">
                <span><i class="fas fa-spinner "></i></span>
                <span>{{ $t('message.paid') }}</span>
              </div>
            </div>
            <div v-if="currentOrder.orderStatus==='paid'" class="d-flex align-items-center">
              <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                <span><i class="fas fa-circle-check "></i></span>
                <span>{{ $t('message.pending') }}</span>
              </div>
              <div class="loading-bar" style="background-color: var(--primary);">
              </div>
              <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                <span><i class="fas fa-circle-check "></i></span>
                <span>{{ $t('message.confirm') }}</span>
              </div>
              <div class="loading-bar" style="background-color: var(--primary);">
              </div>
              <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);" >
                <span><i class="fas fa-circle-check "></i></span>
                <span>{{ $t('message.paid') }}</span>
              </div>
            </div>
            <div v-if="currentOrder.orderStatus==='cancelled'">
              <span class="badge rounded-pill bg-danger">{{currentOrder.orderStatus}}</span>
            </div>
          </div>
<!--End Status box-->

<!--Items details box-->
          <div class="card border-0 shadow-none order-box m-0 p-0">
            <div class="card-body">
              <div class="row">
                <div v-for="(count,index) in currentOrder.countList" class="d-flex justify-content-between align-items-center border-bottom border-1 py-1 mb-1" :key="index" >
                  <div class="w-50 fw-bold" style="font-size: 11px;">
                    <span>{{index+1}}. </span>
                    <span class="d-none">{{count}}  </span>
                    <span>{{currentOrder.nameList[index]}}</span>
                    <span>({{currentOrder.sellPriceList[index]}}</span>
                    <span>x {{currentOrder.countList[index]}})</span>
                  </div>

                  <div class="w-50 text-end fw-bold align-items-center" style="font-size: 11px;">
                    <span>{{eachPrice[index]}} Ks</span>
                    <span class="ms-1 text-success" v-if="currentOrder.acceptedList[index]==='available'"><i class="fas fa-check"></i></span>
                    <span class="ms-1 text-danger" v-if="currentOrder.acceptedList[index]==='unavailable'"><i class="fas fa-times"></i></span>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div v-if="currentOrder.discount>0" class=" p-1 m-0 d-flex justify-content-between align-items-center" style="border-top: 2px dashed black">
            <span>
              Sub Total:
            </span>
            <span>
              {{this.totalPrice}} Ks
            </span>
          </div>
          <div v-if="currentOrder.discount>0" class=" border-1 rounded p-1 m-0 d-flex justify-content-between align-items-center">
            <span>Discount:</span>
            <span>- {{currentOrder.discount}} Ks</span>
          </div>
          <div class=" border-1 rounded p-1 m-0 d-flex justify-content-between align-items-center">
            <span>
              {{ $t('message.total_price') }}
            </span>
            <span>
              {{this.totalPrice - this.currentOrder.discount}} Ks
            </span>
          </div>

<!--End Item Details box-->
        </div>
        <div class="col-12 col-lg-6 col-md-6 p-2 mx-auto rounded" v-if="currentOrder.orderStatus==='confirmed'">
          <div class="accordion" id="accordionExample" >
            <div class="accordion-item mb-1">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button border-0 collapsed"  style="background-color: var(--side-bar-bg)!important;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{$t('message.delivery_method')}}
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body"  style="background-color: var(--light)!important;">
                  <div class="m-1 p-1 rounded">
<!--                    <label class="form-label">{{ $t('message.select_city') }}</label>-->
                    <div class="card shadow-none">
                      <div class="card-body p-0">
                        <div class=" p-0 m-0 w-100">
                          <button type="button" class="form-select form-control rounded text-start" @click="showCityToggle=!showCityToggle">{{selectedCity!==''?selectedCity:$t('message.select_city')}}</button>

                          <div class="shadow-sm p-2 rounded" v-if="showCityToggle" >
                            <li v-for="(city, index) in cities" :key="index" class=" my-1 list-unstyled text-black rounded p-1 text-start" style="cursor:pointer; background-color: var(--light);" @click="showDeliRangeByCity(city.name)" >
                              {{city.name}}
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="card m-1 rounded" style="background-color: var(--light);" v-for="(deli, index) in delivery_services" :key="index">
                        <div class="card-body d-flex align-items-center">
                          <div class="form-check" >
                            <input class="form-check-input" @click="chooseDelivery($event, deli)" :checked="currentOrder.deliveryServiceId === deli.id" type="radio" name="flexRadioDefault" :id="deli.id">
                            <label class="form-check-label" :for="deli.id">
                            </label>
                          </div>
                          <img :src="deli.photo" class="rounded-circle shadow-sm" width="50" height="50" alt="">
                          <div class="d-flex w-75 text-start mx-2">
                            <div class="d-flex flex-column">
                              <span>{{deli.name}}</span>
                              <span v-if="deli.availableCities.includes(this.selectedCity)">{{deli.feeRange}}</span>
                              <span v-else>{{deli.externalFeeRange}}</span>
                            </div>
                          </div>
                          <div class="h-25 border-end border-1 bg-primary"></div>

                        </div>
                      </div>
                      <div class="card mt-3" style="background-color: var(--light);">
                        <div class="card-body">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="other-option" @click="chooseOther($event)">
                            <label class="form-check-label" for="other-option">
                              {{ $t('message.other') }}
                              <input type="text" required class=" form-control" v-model="selectedDeliveryName" v-if="showOtherDeliInput" placeholder="Enter Service Name">
                            </label>
                          </div>
                        </div>
                      </div>
                      <button class="btn w-50 mx-1 btn-sm my-2" style="background-color: var(--btnLight);" @click="saveDeliveryInfo">
                        {{ $t('message.save') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed"  style="background-color: var(--side-bar-bg)!important;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {{$t('message.payment_method')}}
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body"  style="background-color: var(--light)!important;">
                  <div class="card p-1 mt-3 border-0 shadow-none">
                    <div class="card-body m-0 p-0">
                      <div v-if="currentOrder.transactionImg!=='default'">
                        <img :src="currentOrder.transactionImg" class="shadow-sm" width="50" height="60" alt="">
                      </div>
                      <form action="" class="p-1">
<!--                        <label >{{$t('message.choose_payment')}}:</label>-->
                        <div class="p-0 m-0 w-100">
                          <button class="form-select form-control w-80 text-start" @click="showPaymentToggle=!showPaymentToggle">{{selectedPaymentType!==''?selectedPaymentType:'Choose Payment Methods'}}</button>

                          <div class=" shadow-sm p-2 rounded"  v-if="showPaymentToggle">
                            <li v-for="(type, index) in payment_methods" :key="index" class=" my-1 list-unstyled text-black rounded text-start" style="cursor:pointer;background-color: var(--light);" @click="choosePaymentType(type)">
                              <div class="w-100 d-flex align-items-center justify-content-start">
                                <img :src="type.url" class="rounded" width="40" height="40" alt="">
                                <div class="d-flex flex-column ms-2">
                                  <span>{{type.paymentType}} ({{type.accountName}})</span>
                                  <span style="color: var(--secondary);">{{type.accountNumber}}</span>
                                </div>
                              </div>
                            </li>
                          </div>
                          <span class="ms-2" style="color: var(--primary);">{{$t('message.account_no')}}: {{selectedPaymentNumber}}</span>

                        </div>
                        <label class="form-label mt-2">{{$t('message.upload_transaction')}} </label>
                        <input type="file" required @change="onChangePhotoInput" accept="image/*" id="img-input" class="form-control d-inline">
                        <button class="btn btn-sm my-1 w-50 mx-auto d-inline my-3" style="background-color: var(--btnLight);" @click.prevent="sendTransactionPhoto">{{$t('message.send')}}</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoDialog from "@/components/InfoDialog";
import Loading from "@/components/Loading";
import db,{storage} from "@/config/FirebaseInit";
// import firebase from "firebase/compat/app";
// import "firebase/compat/storage";
export default {
  components:{InfoDialog, Loading},
  props:['order_id'],
  data(){
    return{
      selectedCity:'',
      selectedPaymentType:'',
      selectedPaymentNumber:'',
      selectedDeliveryName:'',
      selectedDelivery:'',

      shop:'',
      currentOrder:'',
      file:'',
      payment_methods:[],
      delivery_services:[],
      eachPrice:[],
      costPerItem:[],

      showOtherDeliInput:false,
      showCityToggle:false,
      showPaymentToggle:false,
      infoModalActive:false,
      loadingActive:false,
      modalMessage:'',
    }
  },
  computed:{
    user(){
      return this.$store.getters.getCurrentUser;
    },
    totalPrice(){
      return this.getTotal();
    },
    cities(){
      return this.$store.getters.getCities;
    },
  },
  created() {
    //this.$store.dispatch('getCurrentShop');
  },
  async mounted(){
    await db.collection('users').doc(this.user.id).collection('orders').doc(this.order_id).onSnapshot( (snapshot) => {
      this.currentOrder=[];
      this.eachPrice=[];
      this.products=[];
      this.sellConfirmedOrder=[];
      this.currentOrder = snapshot.data();
      this.selectedCity = snapshot.data().cityToDeliver;
      this.selectedPaymentType = snapshot.data().paymentType;
      this.selectedDeliveryFeeRange = snapshot.data().deliveryFeeRange;

      for(let i=0;i<this.currentOrder.sellPriceList.length;i++){
        const price = parseInt(this.currentOrder.sellPriceList[i]);
        const count = parseInt(this.currentOrder.countList[i]);
        const amount = price*count;
        this.eachPrice.push(amount);
      }
      db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).collection('paymentMethods').get().then(snapshot => {
        this.payment_methods=[];
        snapshot.forEach(doc=>{
          this.payment_methods.push(doc.data());
        });
      });

      db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).get().then((snapshot)=>{
        this.delivery_services=[];
        snapshot.data().deliveryServices.forEach(deli=>{
          db.collection('deliveryServices').doc(deli).onSnapshot(snapshot=>{
            this.delivery_services.push(snapshot.data())
            if(snapshot.data().id===this.currentOrder.deliveryServiceId){
              this.selectedDelivery = snapshot.data();
            }
          })
        })
      })





      // db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).onSnapshot(snapshot=>{
      //   snapshot.data().deliveryServices.forEach(id=>{
      //   })
      // })

    });


  },
  methods: {
    saveDeliveryInfo() {
      this.loadingActive = true;

      db.collection('users').doc(this.user.id).collection('orders').doc(this.order_id).update({
        'deliveryFeeRange': this.selectedDeliveryFeeRange,
        'deliveryServiceId': this.selectedDelivery.id || '',
        'deliveryServiceName': this.selectedDeliveryName,
        'cityToDeliver':this.selectedCity
      }).then(()=>{
        db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).collection('orders').doc(this.currentOrder.id).update({
          'deliveryFeeRange': this.selectedDeliveryFeeRange,
          'deliveryServiceId': this.selectedDelivery.id || '',
          'deliveryServiceName': this.selectedDeliveryName,
          'cityToDeliver':this.selectedCity
        }).then(()=>{
          this.loadingActive = false;
          this.infoModalActive = true;
          this.modalMessage = "Update Success!"
        })

      })
    },
    choosePaymentType(type){
      this.selectedPaymentType =  type.paymentType;
      this.selectedPaymentNumber = type.accountNumber;
      this.showPaymentToggle = !this.showPaymentToggle;
    },
    showDeliRangeByCity(city){
      this.showCityToggle = !this.showCityToggle;
      this.selectedCity = city;
      if(this.selectedDelivery!=='' && this.selectedCity!==''){
        if(this.selectedDelivery.availableCities.includes(this.selectedCity)){
          this.selectedDeliveryFeeRange = this.selectedDelivery.feeRange;
        }else {
          this.selectedDeliveryFeeRange = this.selectedDelivery.externalFeeRange;
        }
      }
      console.log(this.selectedDeliveryFeeRange)
    },
    chooseDelivery(e, deli){
      if(e.target.checked){
        this.selectedDeliveryName = deli.name;
        this.selectedDelivery = deli;
        this.showOtherDeliInput = false;
        if(this.selectedDelivery!=='' && this.selectedCity!==''){
          if(this.selectedDelivery.availableCities.includes(this.selectedCity)){
            this.selectedDeliveryFeeRange = this.selectedDelivery.feeRange;
          }else {
            this.selectedDeliveryFeeRange = this.selectedDelivery.externalFeeRange;
          }
        }
        console.log(this.selectedDeliveryFeeRange)
      }
    },
    chooseOther(e){
      if(e.target.checked){
        this.selectedDeliveryFeeRange='';
        this.selectedDeliveryName = '';
        this.selectedDelivery = '';
        this.showOtherDeliInput = true;
      }else {
        this.showOtherDeliInput = false;
      }
    },
    getTotal(){
      let total = 0;
      this.eachPrice.forEach((price,index)=>{
        if(this.currentOrder.acceptedList[index]==='available'){
          total+=price;
        }
      })
      return total;
    },
    onChangePhotoInput(e) {
      this.file = e.target.files[0];
    },
    closeInfoModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    async sendTransactionPhoto() {
      if (this.selectedPaymentType !== '' && this.file !== null) {
        this.loadingActive = true;
        const storageRef = await storage.ref();
        const imgRef = storageRef.child(`/${this.currentOrder.shopCity}/${this.currentOrder.shopId}/transactionPhoto/${this.currentOrder.id}`)
        imgRef.put(this.file).on("state_changed", () => {
        }, error => {
          console.log(error);
          this.loadingActive = false;
        }, async () => {
          const img_src = await imgRef.getDownloadURL();
          console.log("payment", this.selectedPaymentType);
          db.collection('users').doc(this.user.id).collection('orders').doc(this.currentOrder.id).update({
            'paymentType': this.selectedPaymentType,
            'transactionImg': img_src
          }).then(() => {
            db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).collection('orders').doc(this.currentOrder.id).update({
              'paymentType': this.selectedPaymentType,
              'transactionImg': img_src
            }).then(() => {
              this.loadingActive = false;
              this.infoModalActive = true;
              this.modalMessage = 'Transaction has sent successfully!'
            })
          })
        })
      } else {
        this.infoModalActive = true;
        this.modalMessage = 'Fill the all requirements!';
      }
    },
  }

}
</script>

<style scoped>
.card ul{
  text-align: start;
}
.card img{
  width: 30px;
  height: 30px;
}
.card ul{
  text-align: start;
  width: inherit;
  padding: 0;
  list-style-type: none;
  background-color: var(--light);
  margin: 2px;
  border-radius: var(--border-radius);

}
.accordion-button{
  border: none !important;
  box-shadow: none;
}
.card li{
  min-width: 100px;
  text-align: start;
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px 5px;
  border-bottom: 2px solid var(--white);
}
.loading-bar{
  width: 30px; height: 3px; background-color: var(--softPrimary);
}
.status-box{
  font-size: 10px;
  background-color: var(--background-color);
  padding: 2px;
  border-radius: var(--border-radius);
  width: 80px;
}
.order-box{
  overflow-x: hidden;
  overflow-y: hidden;
}
.form-select{
  cursor: pointer;
  background-color: var(--light);
  color: var(--text-color) !important;
  border: none;
  transition: 0.3s;
}
.form-select:hover{
  cursor: pointer;
  background-color: var(--light) !important;
}
.payment-pop-up{
  background-color: var(--background-color);
  font-size: 12px;
  z-index: 5000;
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 200px;
  margin-top: 0px;
}

</style>