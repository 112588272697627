<template>
  <div id="app">
    <Navigation v-if="navigation" />
    <router-view/>
  </div>
</template>


<script>
import Navigation from "@/components/Navigation";
import db,{firebase, messaging} from "./config/FirebaseInit";
import {provide} from "vue";
export default {
  data(){
    return{
      navigation:null,
    }
  },
  components: {Navigation},
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },
    cashier(){
      return this.$store.getters.getCashierAcc;
    }
  },
  mounted() {

  },
  //options api
  provide: {
    messaging: messaging
  },
  //composition api
  setup() {
    provide('messaging', messaging)
  },
  async created() {
    await firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        db.collection('users').doc(user.uid).get().then(result=>{
          if(result.exists) {
            this.$store.dispatch("getCurrentUser");
            this.$store.dispatch("getCartInfo");
          }
          else{
            this.$store.dispatch("getCurrentShop");
            //this.$store.dispatch("getAdmin");
            // this.$store.dispatch("getAllProducts", this.$store.shopOwner.city, this.$store.shopOwner.shopId);
          }
        })
      }
    });
    if(!this.$store.state.cities.length>0){
      await this.$store.dispatch("getCities");
    }
    console.log('shop owner', this.owner);
    console.log('cashier', this.cashier)
    //console.log('Firebase cloud messaging object', this.$messaging)
    this.$messaging.getToken({ vapidKey:'BJMMGbfQ4hOe2acXMe11F99lGUArrGheO2nXLAFzs1D3Oh2MCuJCkWPiawTRR4h_roDqBaiqJ6NLVo5o2gu5mXo' })
        .then((token)=>{
          this.$store.commit("SET_FCM", token);
          /*firebase.auth().onAuthStateChanged((user)=>{
            if(user) {
              db.collection('users').doc(user.uid).get().then(result => {
                if (result.exists) {
                  db.collection('users').doc(user.uid).update({
                    'fcm':token
                  }).then(()=>{
                    console.log('token added for user')
                  })
                }else {
                  db.collection('shopRegistration').doc(user.uid).update({
                    'fcm':token
                  }).then(()=>{
                    console.log('token added for shop owner')
                  })
                }
              })
            }
          });*/
        }).catch(err=>{
      console.log(err.messaging)
    });

    //TODO: device signout
   /* if(this.$store.getters.cashierAcc!==''){
      db.collection(this.owner.city).doc(this.owner.shopId).collection('account').doc(this.cashier.id).get().then(snapshot=>{
        if(snapshot.data().deviceId !== navigator.userAgent){
          firebase.auth().signOut().then(()=>{
            window.localStorage.removeItem('vuex');
            sessionStorage.clear();
            this.$store.state.shopOwner='';
            this.$store.state.cashierAcc = '';
            console.log("logout");
            this.$router.push({name:'ShopOwnerLogin'});
          }).catch(error=>{
            alert(error.message);
          });
        }
      })
    }*/
    this.checkRoute()
  },
  methods:{
    checkRoute() {
      /*if (this.$route.name === "ShopOwnerLogin" || this.$route.name==="AdminLogin") {
        this.navigation = false;
        this.adminNavigation = false;
        return;
      }*/

      if (this.$route.name === 'Home' || this.$route.name === 'About' || this.$route.name === 'Contact' || this.$route.name === 'TermsOfService' || this.$route.name === 'ShowShopByType' || this.$route.name === 'Shops' || this.$route.name === 'ShopInfo' || this.$route.name === 'LiveSales' || this.$route.name === 'ProductInfo' || this.$route.name==='product-detail' || this.$route.name === 'UserProfile' || this.$route.name==='CustomerOrderDetails' || this.$route.name === 'CustomerLiveOrderDetails'){
        this.navigation=true;
        return;
      }else {
        this.navigation=false;
        return;
      }
    },

  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@200;300;400;600;800&display=swap');
#app {
  font-family: 'Sarabun', sans-serif;
  /*font-family: 'Zawgyi-One',second font,third font;*/
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
:root{
  --primary: #5865f6;
  --secondary: #7782fa;
  --discountPrimary: #fa6801;
  --live-bg: #b62323;
  --softPrimary: rgba(179, 184, 248);
  --text-color: black;
  --text-color-light: #4F4F50C0;
  --btnLight: rgb(184, 189, 248);
  --light: #f3f5fc;
  --side-bar-bg: rgb(216, 225, 252);
  --white: #ffffff;
  --gray: #eceff3;
  --dark: #2d2d3b;
  --border-radius:0.5rem;
  --background-color: white;
  --card-color: #ffffff;
}
[data-theme="darkMode"] {
  /* Variables for dark mode */
  --background-color: #0e141e;
  --light: #2e313b;
  --side-bar-bg: #475b9d;
  --text-color: white;
  --white: #000000;
  --text-color-light: #c6c7cb;
  --card-color: #293048D3;
}
body{
  background-color: var(--background-color) !important;
}
.form-floating label{
  color: gray !important;
}
.accordion-button{
  box-shadow: none !important;
}
.fa, .fas{
  color: var(--text-color) !important;
}

body::-webkit-scrollbar {
  width: 0.4em;
}

body::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
}

body::-webkit-scrollbar-thumb {
  background-color: var(--softPrimary);
  outline: 1px solid var(--softPrimary);
}

th{
  background-color: var(--light) !important;
}
tr, td, th{
  border:1px solid var(--btnLight) !important;
  background-color: var(--background-color);
  color: var(--text-color);
}
.modal-content{
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius)!important;
  border: none !important;
}
.modal-header{
  border: none!important;
}
.modal-footer{
  border:none!important;
}

.btn{
  border-radius: var(--border-radius) !important;
  transition: 0.3s;
}
.card{
  border-radius: var(--border-radius) !important;
  margin: 5px;
  color: var(--text-color);
  border: 0px !important;
  transition: 0.3s !important;
}
.card-body{
  border-radius: var(--border-radius) !important;
  transition: 0.3s !important;
}
.carousel-img{
  border: 0px !important;
}
.btn:focus{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
}
.btn:hover{
  transform: scale(1.02);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control{
  border:1px solid var(--side-bar-bg);
  color: var(--text-color) !important;
  background-color: var(--white) !important;
  border-radius: var(--border-radius) !important;
}

.form-control:focus{
  box-shadow: none !important;
}
.btn{
  border-radius: var(--border-radius);
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>