<template>
  <div v-if="currentOrder" style="background-color: var(--background-color);">
    <Loading v-if="loadingActive"/>
    <InfoDialog v-if="infoModalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
    <div class="container pt-5 mt-2" style="background-color: var(--background-color); color: var(--text-color);">
      <div class="row">
        <div class="col-12 col-lg-6 shadow-sm mx-auto">
          <div class="d-flex align-items-center">
            <button class="btn btn--white" style="color: var(--text-color);" @click="$router.back()"><i class="fas fa-chevron-left"></i></button>
            <span class="fs-5 fw-bold">{{$t('message.order_details')}}</span>
          </div>
          <div class="d-flex px-2 justify-content-between">
            {{ $t('message.date') }}: {{currentOrder.time.toDate().toLocaleDateString()}}
            <span><i class="far fa-clock"></i>{{currentOrder.time.toDate().toLocaleTimeString()}}</span>
          </div>
          <div class="px-2">
            <div class="rounded p-2 shadow-sm">
              <div v-if="currentOrder.orderStatus==='pending'" class="d-flex align-items-center">
                <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                  <span class=" "><i class="fas fa-circle-check "></i></span>
                  <span>{{ $t('message.pending') }}</span>
                </div>
                <div class="loading-bar" style="background-color: var(--primary);">
                </div>
                <div class="d-flex flex-column text-center status-box">
                  <span><i class="fas fa-spinner "></i></span>
                  <span>{{ $t('message.confirm') }}</span>
                </div>
                <div class="loading-bar">
                </div>
                <div class="d-flex flex-column text-center status-box">
                  <span><i class="fas fa-spinner "></i></span>
                  <span>{{ $t('message.paid') }}</span>
                </div>
              </div>
              <div v-if="currentOrder.orderStatus==='confirmed'" class="d-flex align-items-center">
                <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                  <span class=" "><i class="fas fa-circle-check "></i></span>
                  <span>{{ $t('message.pending') }}</span>
                </div>
                <div class="loading-bar" style="background-color: var(--primary);">
                </div>
                <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                  <span><i class="fas fa-circle-check "></i></span>
                  <span>{{ $t('message.confirm') }}</span>
                </div>
                <div class="loading-bar" style="background-color: var(--primary);">
                </div>
                <div class="d-flex flex-column text-center status-box">
                  <span><i class="fas fa-spinner "></i></span>
                  <span>{{ $t('message.paid') }}</span>
                </div>
              </div>
              <div v-if="currentOrder.orderStatus==='paid'" class="d-flex align-items-center">
                <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                  <span><i class="fas fa-circle-check "></i></span>
                  <span>{{ $t('message.pending') }}</span>
                </div>
                <div class="loading-bar">
                </div>
                <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                  <span><i class="fas fa-circle-check "></i></span>
                  <span>{{ $t('message.confirm') }}</span>
                </div>
                <div class="loading-bar">
                </div>
                <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);" >
                  <span><i class="fas fa-circle-check "></i></span>
                  <span>{{ $t('message.paid') }}</span>
                </div>
              </div>
              <div v-if="currentOrder.orderStatus==='canceled'">
                <span class="badge rounded-pill bg-danger">{{currentOrder.orderStatus}}</span>
              </div>
            </div>
          </div>
          <div class="card order-box">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <img :src="currentOrder.image" class="shadow-sm rounded" style="width: 100px; height: 100px" alt="">
                <div class="d-flex mx-2 flex-column">
                  <span>{{currentOrder.itemName}}</span>
                  <span>{{currentOrder.sellPrice}} Ks</span>
                  <span>x {{currentOrder.count}}</span>
                </div>
              </div>
            </div>
          </div>
          <p class="fw-bold fs-5 text-success">{{$t('message.total_price')}}: {{this.currentOrder.totalPrice}} Ks</p>
        </div>

        <div class="col-12 col-lg-6 col-md-6 p-2 mx-auto rounded" v-if="currentOrder.orderStatus==='confirmed'">
          <div class="accordion" id="accordionExample" >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed"  style="background-color: var(--btnLight)!important;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{$t('message.delivery_method')}}
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body"  style="background-color: var(--light)!important;">
                  <div class="m-1 p-1 rounded">
                    <label class="form-label">{{$t('message.select_city')}}</label>
                    <div class="card shadow-none">
                      <div class="card-body p-0">
                        <div class=" p-0 m-0 w-100">
                          <div class="rounded p-1" >
                            <input type="button" class="form-select rounded text-start" v-model="selectedCity" placeholder="Search Cities" @click="showCityToggle=!showCityToggle">
                          </div>
                          <div class="payment-pop-up shadow-sm p-2 rounded" v-if="showCityToggle" >
                            <li v-for="(city, index) in cities" :key="index" class=" my-1 list-unstyled text-black rounded p-1 text-start" style="cursor:pointer; background-color: var(--btnLight);" @click="showDeliRangeByCity(city.name)" >
                              {{city.name}}
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="card m-1 rounded" style="background-color: var(--light);" v-for="(deli, index) in delivery_services" :key="index">
                        <div class="card-body d-flex align-items-center">
                          <div class="form-check" >
                            <input class="form-check-input" @click="chooseDelivery($event, deli)" :checked="currentOrder.deliveryServiceId === deli.id" type="radio" name="flexRadioDefault" :id="deli.id">
                            <label class="form-check-label" :for="deli.id">
                            </label>
                          </div>
                          <img :src="deli.photo" class="rounded-circle shadow-sm" width="50" height="50" alt="">
                          <div class="d-flex w-75 text-start mx-2">
                            <div class="d-flex flex-column">
                              <span>{{deli.name}}</span>
                              <span v-if="deli.availableCities.includes(this.selectedCity)">{{deli.feeRange}}</span>
                              <span v-else>{{deli.externalFeeRange}}</span>
                            </div>
                          </div>
                          <div class="h-25 border-end border-1 bg-primary"></div>

                        </div>
                      </div>
                      <div class="card mt-3" style="background-color: var(--light);">
                        <div class="card-body">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="other-option" @click="chooseOther($event)">
                            <label class="form-check-label" for="other-option">
                              {{$t('message.other')}}
                              <input type="text" required class=" form-control" v-model="selectedDeliveryName" v-if="showOtherDeliInput" placeholder="Enter Service Name">
                            </label>
                          </div>
                        </div>
                      </div>
                      <button class="btn w-50 mx-1 btn-sm my-2" style="background-color: var(--btnLight);" @click="saveDeliveryInfo">{{$t('message.save')}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed"  style="background-color: var(--btnLight)!important;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {{$t('message.payment_method')}}
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body"  style="background-color: var(--light)!important;">
                  <div class="card p-1 mt-3 border-0 shadow-none">
                    <div class="card-body m-0 p-0">
                      <div v-if="currentOrder.transactionImg!=='default'">
                        <img :src="currentOrder.transactionImg" class="shadow-sm" width="50" height="60" alt="">
                      </div>
                      <form action="" class="p-1">
                        <label >{{$t('message.choose_payment')}}</label>
                        <div class="p-0 m-0 w-100">
                          <div class="bg-white rounded" >
                            <input type="button" class="form-select rounded w-80  text-start" v-model="selectedPaymentType" placeholder="Search Cities" @click="showPaymentToggle=!showPaymentToggle">
                          </div>
                          <div class="payment-pop-up shadow-sm p-2 rounded "  v-if="showPaymentToggle" >
                            <li v-for="(type, index) in payment_methods" :key="index" class=" my-1 list-unstyled text-black rounded text-start" style="cursor:pointer;background-color: var(--btnLight);" @click="choosePaymentType(type)">
                              <div class="w-100 d-flex align-items-center justify-content-start">
                                <img :src="type.url" class="rounded" width="40" height="40" alt="">
                                <div class="d-flex flex-column ms-2">
                                  <span>{{type.paymentType}}</span>
                                  <span style="color: var(--secondary);">{{type.accountNumber}}</span>
                                </div>
                              </div>
                            </li>
                          </div>
                          <span class="ms-2" style="color: var(--primary);">{{ $t('message.account_no') }}: {{selectedPaymentNumber}}</span>

                        </div>
                        <label class="form-label mt-2">{{$t('message.upload_transaction')}}: </label>
                        <input type="file" required @change="onChangePhotoInput" accept="image/*" id="img-input" class="form-control d-inline">
                        <button class="btn btn-sm my-1 w-50 mx-auto d-inline my-3" style="background-color: var(--btnLight);" @click.prevent="sendTransactionPhoto">{{$t('message.send')}}</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import InfoDialog from "@/components/InfoDialog";
import Loading from "@/components/Loading";
import db,{storage} from "@/config/FirebaseInit";
// import firebase from "firebase/compat/app";
// import "firebase/compat/storage";
export default {
  components:{InfoDialog, Loading},
  props:['order_id'],
  data(){
    return{
      selectedCity:'',
      selectedDelivery:'',
      selectedDeliveryFeeRange:'',
      selectedPaymentType:'',
      shop:'',
      currentOrder:'',
      file:'',
      payment_methods:[],

      showOtherDeliInput:false,
      showCityToggle:false,
      showPaymentToggle:false,
      infoModalActive:false,
      loadingActive:false,
      modalMessage:'',
    }
  },
  computed:{
    user(){
      return this.$store.getters.getCurrentUser;
    },
    cities(){
      return this.$store.getters.getCities;
    }
  },
  created() {
    this.$store.dispatch('getCurrentShop');
  },
  async mounted(){
    await db.collection('users').doc(this.user.id).collection('liveOrders').doc(this.order_id).onSnapshot( (snapshot) => {
      this.currentOrder=[];
      this.currentOrder = snapshot.data();
      this.selectedCity = snapshot.data().cityToDeliver;
      this.selectedPaymentType = snapshot.data().paymentType;
      this.selectedDeliveryFeeRange = snapshot.data().deliveryFeeRange;
      db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).collection('paymentMethods').get().then(snapshot => {
        this.payment_methods=[];
        snapshot.forEach(doc=>{
          this.payment_methods.push(doc.data());
        });
      });

      db.collection('deliveryServices').onSnapshot(snapshot=>{
        this.delivery_services=[];
        snapshot.forEach(doc=>{
          this.delivery_services.push(doc.data())
          if(doc.data().id===this.currentOrder.deliveryServiceId){
            this.selectedDelivery = doc.data();
          }
        })
      })
    });


  },
  methods:{
    //delivery
    saveDeliveryInfo() {
      this.loadingActive = true;
      db.collection('users').doc(this.user.id).collection('liveOrders').doc(this.order_id).update({
        'deliveryFeeRange': this.selectedDeliveryFeeRange || '0',
        'deliveryServiceId': this.selectedDelivery.id || '',
        'deliveryServiceName': this.selectedDeliveryName,
        'cityToDeliver':this.selectedCity
      }).then(()=>{
        db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).collection('liveOrders').doc(this.currentOrder.id).update({
          'deliveryFeeRange': this.selectedDeliveryFeeRange || '0',
          'deliveryServiceId': this.selectedDelivery.id || '',
          'deliveryServiceName': this.selectedDeliveryName,
          'cityToDeliver':this.selectedCity
        }).then(()=>{
          this.loadingActive = false;
          this.infoModalActive = true;
          this.modalMessage = "Update Success!"
        })

      })
    },
    choosePaymentType(type){
      this.selectedPaymentType =  type.paymentType;
      this.selectedPaymentNumber = type.accountNumber;
      this.showPaymentToggle = !this.showPaymentToggle;
    },
    showDeliRangeByCity(city){
      this.showCityToggle = !this.showCityToggle;
      this.selectedCity = city;
      if(this.selectedDelivery!=='' && this.selectedCity!==''){
        if(this.selectedDelivery.availableCities.includes(this.selectedCity)){
          this.selectedDeliveryFeeRange = this.selectedDelivery.feeRange;
        }else {
          this.selectedDeliveryFeeRange = this.selectedDelivery.externalFeeRange;
        }
      }
      console.log(this.selectedDeliveryFeeRange)
    },
    chooseDelivery(e, deli){
      if(e.target.checked){
        this.selectedDeliveryName = deli.name;
        this.selectedDelivery = deli;
        this.showOtherDeliInput = false;
        if(this.selectedDelivery!=='' && this.selectedCity!==''){
          if(this.selectedDelivery.availableCities.includes(this.selectedCity)){
            this.selectedDeliveryFeeRange = this.selectedDelivery.feeRange;
          }else {
            this.selectedDeliveryFeeRange = this.selectedDelivery.externalFeeRange;
          }
        }
        console.log(this.selectedDeliveryFeeRange)
      }
    },
    chooseOther(e){
      if(e.target.checked){
        this.selectedDeliveryFeeRange='';
        this.selectedDeliveryName = '';
        this.selectedDelivery = '';
        this.showOtherDeliInput = true;
      }else {
        this.showOtherDeliInput = false;
      }
    },
    //end delivery
    onChangePhotoInput(e) {
      this.file = e.target.files[0];
    },
    closeInfoModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    async sendTransactionPhoto(){
      if(this.selectedPaymentType!=='' && this.file!==null){
        this.loadingActive = true;
        const storageRef = await storage.ref();
        const imgRef = storageRef.child(`/${this.currentOrder.shopCity}/${this.currentOrder.shopId}/transactionPhoto/${this.currentOrder.id}`)
        imgRef.put(this.file).on("state_changed", () => {
        },error => {
          console.log(error);
          this.loadingActive = false;
        },async ()=> {
          const img_src = await imgRef.getDownloadURL();
          console.log("payment", this.selectedPaymentType);
          db.collection('users').doc(this.user.id).collection('liveOrders').doc(this.currentOrder.id).update({
            'paymentType':this.selectedPaymentType,
            'transactionImg':img_src
          }).then(()=>{
            db.collection(this.currentOrder.shopCity).doc(this.currentOrder.shopId).collection('liveOrders').doc(this.currentOrder.id).update({
              'paymentType':this.selectedPaymentType,
              'transactionImg':img_src
            }).then(()=>{
              this.loadingActive=false;
              this.infoModalActive = true;
              this.modalMessage = 'Transaction has sent successfully!'
            })
          })
        })
      }else {
        this.infoModalActive = true;
        this.modalMessage='Fill the all requirements!';
      }

    },
  }

}
</script>

<style scoped>
.card ul{
  text-align: start;
}
.card img{
  width: 30px;
  height: 30px;
}
.card ul{
  text-align: start;
  width: inherit;
  padding: 0;
  list-style-type: none;
  background-color: var(--light);
  margin: 2px;
  border-radius: var(--border-radius);

}
.card li{
  height: 60px;
  min-width: 80px;
  text-align: start;
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px 5px;
  border-bottom: 2px solid var(--white);
}
.loading-bar{
  width: 30px; height: 3px; background-color: var(--softPrimary);
}
.status-box{
  font-size: 10px;
  background-color: var(--light);
  padding: 2px;
  border-radius: var(--border-radius);
  width: 80px;
}
.order-box{
}
.input-box{
  border-radius: var(--border-radius);
  width: 300px;
  position: fixed;
  height: 300px;
  right:10px;
  background-color: var(--softPrimary);
}
.pay-now-box{
  display: none;
  border-radius: var(--border-radius);
  position: fixed;
  height: 80px;
  bottom: 10px;
}
.payment-box{
  background-color: var(--light);
  width: 300px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
}

.form-select{
  cursor: pointer;
  background-color: var(--light);
  color:var(--text-color) !important;
  border: none;
  transition: 0.3s;
}
.form-select:hover{
  cursor: pointer;
  background-color: var(--light) !important;
}
.payment-pop-up{
  background-color: var(--background-color);
  font-size: 12px;
  z-index: 5000;
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 200px;
  margin-top: 0px;
}

</style>