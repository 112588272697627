<template>
  <div class="container">
    <div class="row mt-5 pt-5">
      <h1>Contact Us</h1>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>