<template>
  <Loading v-if="loadingModalActive"/>
<!--  <PhoneAuthModal v-on:close-modal="closeLoginModal"/>-->
  <PhoneAuthModal v-show="loginModal" v-on:close-modal="closeLoginModal"/>
  <InfoDialog v-if="infoModalActive" :modal-message="modalMessage" v-on:closeModal="closeModal"/>
  <ConfirmDialog :modal-message="modalMessage" v-if="confirmActive" v-on:confirm="confirm" v-on:cancel="cancel"/>

  <div>
    <div class="card m-1" >
      <div class="" @click="checkDetail(product)">
        <div id="carouselExampleControls" v-if="product.images.length>0" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner p-2">
            <div class="carousel-item p-2" data-bs-interval="4000" :class="index===0?'active':''" v-for="(img, index) in product.images" :key="index">
              <img :src="img" class="carousel-img img-thumbnail" alt="">
            </div>
            <div class="carousel-caption top-0 p-0 text-start w-80 mt-2" v-if="product.discount>0">
              <span class="badge bg-danger rounded text-white mb-1" style="position: absolute" >{{product.discount}}%</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/white-logo.svg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-caption text-start" v-if="product.discount>0">
                <span class="badge rounded text-white mb-1" style="position: absolute;background-color: var(--discountPrimary);" ><i class="fas fa-tags"></i> {{product.discount}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body rounded text-start w-100">

        <div class="" @click="checkDetail(product)" style="cursor:pointer;">
          <div class="rounded m-1 d-flex flex-column" style="cursor:pointer;">
            <span class="text-wrap text-truncate py-1" style="height: 50px; font-size: 14px;">{{product.itemName}}</span>
            <div v-if="currentShop.showStock=='yes'">
              <span class="" style="font-size: 12px;" v-if="product.stock>0">{{product.stock}} {{$t('message.item_available')}}</span>
              <span class="text-danger" style="font-size: 12px;" v-else>{{$t('message.out_of_stock')}}</span>
            </div>

            <div v-if="product.discount!=='0'" class="d-flex align-items-center" style="height: 35px;">
              <span class="text-decoration-line-through" style="font-size: 11px;">{{product.sellPrice}}</span>
              <span class="fs-6 ms-1 fw-bold" style="color: var(--discountPrimary);">{{product.sellPrice-product.sellPrice*(product.discount/100)}} Ks</span>
            </div>
            <div v-else style="height: 35px;">
              <span class="fs-6 fw-bold" style="color: var(--primary);">{{product.sellPrice}} Ks</span>
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-sm w-100" v-if="currentShop.showStock==='yes'" :disabled="product.stock<=0" style="background-color: var(--secondary);" :id="product.id" @click="addToCart(product)">
            <span class="text-white" style="font-size: 11px;">{{$t('message.add_to_cart')}}</span>
            <div class="spinner-border spinner-border-sm d-inline-block" v-if="loadingActive"></div>
          </button>
          <button class="btn btn-sm w-100" v-else style="background-color: var(--secondary); !important;" :id="product.id" @click="addToCart(product)">
            <span class="text-white" style="font-size: 11px;">{{$t('message.add_to_cart')}}</span>
            <div class="spinner-border spinner-border-sm d-inline-block" v-if="loadingActive"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/config/FirebaseInit";
import ConfirmDialog from "@/components/ConfirmDialog";
import InfoDialog from "@/components/InfoDialog";
import PhoneAuthModal from "@/components/PhoneAuthModal";
import Loading from "@/components/Loading";
export default {
  name:"ProductItemCard",
  components: {PhoneAuthModal, Loading, InfoDialog, ConfirmDialog},
  props:["product","city","shop_name"],
  data(){
    return{
      modalMessage:'',
      currentShop:'',
      addToCartOrder:'',
      loadingModalActive:false,
      loadingActive:false,
      confirmActive:false,
      infoModalActive:false,
      loginModal:false

    }
  },
  computed:{
    user(){
      return this.$store.getters.getCurrentUser;
    },
    cartInfo(){
      return this.$store.getters.getCartInfo;
    },
    orders(){
      return this.$store.getters.getCartItems;
    }
  },
  async mounted() {
    await db.collection(this.city).where('name','==',this.$route.params.shop_name.split("-").join(" ")).onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        this.currentShop = doc.data();
      })
    })
  },
  methods:{
    async addToCart(product){
      if(this.user){
        this.loadingActive = true;
        const orderItem = {
          'productId': product.id,
          'image':'',
          'orderUserId':this.user.id,
          'count':'1',
          'itemName':product.itemName,
          'itemCode':product.itemCode,
          'buyPrice':product.buyPrice,
          'sellPrice':product.sellPrice,
          'discount':product.discount,
        }
        if(product.images.length>0){
          orderItem.image = product.images[0];
        }else {
          orderItem.image = 'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/default%2F1.png?alt=media&token=b935b93f-8b63-431b-93bd-ef52bcb2f3e0'
        }
        if(!this.cartInfo){
          await db.collection('users').doc(this.user.id).collection('cart').doc('shop').set({
            'shopId':this.currentShop.id,
            'city':this.currentShop.city
          }).then(()=>{
            db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(product.id).set(orderItem).then(()=>{
              this.loadingActive=false;
              this.$store.dispatch("getCartItems", this.currentShop.id);
            })
          })
        }else {
          if(this.currentShop.id === this.cartInfo.shopId){
            if (!this.orders.some((order)=>order.productId === product.id)){
              db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(product.id).set(orderItem).then(()=>{
                this.loadingActive=false;
                this.$store.dispatch("getCartItems", this.currentShop.id);
              })
            }else {
              this.modalMessage=this.$t('message.already_selected');
              this.loadingActive = false;
              this.infoModalActive = true;
            }
          }
          else {
            this.modalMessage=this.$t('message.do_u_remove_item');
            this.loadingActive = false;
            this.addToCartOrder = orderItem;
            this.confirmActive = true
          }
        }
      }
      else {
        this.loginModal =true;
      }
      this.$store.dispatch("getCartInfo");
    },

    confirm(){
      this.confirmActive=false;
      this.loadingActive = true;
      db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').get().then((snapshot)=>{
        snapshot.forEach(doc=>{
          doc.ref.delete();
        });
        db.collection('users').doc(this.user.id).collection('cart').doc('shop').delete().then(()=>{
          db.collection('users').doc(this.user.id).collection('cart').doc('shop').set({
            'shopId':this.currentShop.id,
            'city':this.currentShop.city
          }).then(()=>{
            db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(this.addToCartOrder.id).set(this.addToCartOrder).then(()=>{
              this.loadingActive=false;
              console.log('added')
              this.$store.dispatch("getCartItems", this.currentShop.id);
            });
          });
        })
      })
    },
    closeLoginModal(){
      this.loginModal = !this.loginModal;
    },
    closeModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    cancel(){
      this.confirmActive = !this.confirmActive

    },
    checkDetail(product){
      window.scrollTo(0, 0);
      this.$emit("selectedProduct", product)
      this.$router.push({name:'ProductInfo',params:{city:this.city,shop_name:this.shop_name.split(' ').join('-'),product_id:product.id}});

      // window.location.reload()
    },
  }

}
</script>
<style scoped>
.carousel-caption{
  left: 5px;
  right: 0;
  width: 100%;
  top:5px;
  height:20px;
  padding: 10px;
}
.card{
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  background-color: var(--light);
}
.card-body{
  transition: 0.5s;
}
.carousel-caption:hover+.carousel-img{
  border: 2px solid var(--btnLight);
  border-radius: var(--border-radius);
}
.card-body:hover + .carousel-img{
  transform: scale(1.2);

}
.carousel-inner{
  display: flex;
  box-sizing: border-box !important;
  align-items: center;
  height: 180px;
}
.carousel-img{
  border-radius: var(--border-radius);
  transition: 0.6s;
  cursor: pointer;
}
.carousel-img:hover{
  transform: scale(1.2);
}
@media only screen and (max-width: 1100px){

  .carousel-inner{
    width: 100%;
  }
  .carousel-item{

    height: 180px;
  }
  .carousel-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
<style>

</style>