<template>
  <PhoneAuthModal v-show="loginModal" v-on:close-modal="closeLoginModal"/>
  <Cart v-if="addToCartActive" @close-cart="closeCartModal"/>

  <div>
    <nav class="navbar navbar-expand-lg p-0 fixed-top" style="z-index: 20000;border-bottom: 1px solid var(--light);">
      <div class="container-xxl px-0">

        <router-link class="d-flex text-decoration-none align-items-center" :to="{name:'Home'}">
          <img src="../assets/flash/logo1.svg" class="ms-1" width="50" alt="">
          <span class="fw-bold fs-5 ms-2" style="color: var(--text-color);"> Flash Mall</span>
          <span>{{}}</span>
        </router-link>

        <div class="d-flex">
          <a class="btn d-lg-none d-md-none text-decoration-none align-items-center" style="cursor: pointer;color: var(--text-color);" @click="addToCartActive = !addToCartActive">
            <div class="d-flex align-items-center">
              <div class="position-relative">
                <span style="font-size: 22px;"> <i class="fas fa-shopping-cart"></i></span>
                <span class="badge position-absolute shadow-sm rounded-circle text-white d-flex justify-content-center align-items-center"  style="background-color: red; width: 15px;height: 15px; top: 1px;left: 10px">{{orders.length>0?orders.length:'0'}}</span>
              </div>
<!--              <div class="ms-2 d-flex flex-column">
                <span style="font-size: 9px;">{{$t('message.total')}}</span>
                <span class="fw-bold" style="font-size: 12px">0 Ks</span>
              </div>-->
            </div>
          </a>

          <button class="navbar-toggler border-0" data-bs-toggle="collapse" :class="visible?null:'collapsed'"
                  :aria-expanded="visible ? 'true' : 'false'"
                  @click="visible = !visible"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-label="Toggle navigation">
            <span > </span>
            <span > </span>
            <span > </span>

          </button>

        </div>



        <div class="collapse navbar-collapse" :class="visible?null:'collapsed'" id="navbarSupportedContent">

          <ul class="navbar-nav ms-auto mb-2 align-items-center mb-lg-0">
<!--            <li class="nav-item" @click="visible =!visible" >
              <router-link class="nav-link" :class="this.$route.name==='Home'?'active':''"  aria-current="page" :to="{name:'Home'}">{{$t('message.nav_home')}}</router-link>
            </li>

            <li class="nav-item" @click.prevent="visible =!visible">
              <router-link class="nav-link" :class="this.$route.name==='About'?'active':''" :to="{name:'About'}">{{$t('message.nav_about')}}</router-link>
            </li>
            <li class="nav-item" @click.prevent="visible =!visible">
              <router-link class="nav-link" :class="this.$route.name==='Home'?'Contact':''" :to="{name:'Contact'}">{{$t('message.nav_contact')}}</router-link>
            </li>-->
            <li class="nav-item">
              <span  @click="toggleTheme" aria-label="Toggle themes" class=" nav-link d-flex justify-content-center align-items-center">
                <span v-if="this.theme == 'darkMode'" style="font-size: 22px;" class="text-white"><i class="fas fa-sun"></i></span>
                <span v-else style="font-size: 22px;"><i class="fas fa-moon "></i></span>
              </span>
            </li>
            <div class="nav-item dropdown">
              <a class="nav-link text-center dropdown-toggle p-1 d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
               <span style="font-size: 22px;"> <i class="fas fa-globe"></i></span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                <li class="dropdown-item" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                  <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
                </li>
              </ul>
            </div>
            <li class="nav-item " @click.prevent="visible =!visible">
              <router-link :to="{name:'UserProfile'}" class="nav-link" v-if="user">
               <div class="d-flex justify-content-center align-items-center">
                 <span class="me-2" style="font-size: 22px"><i class="fas fa-user-circle"></i></span>
                 <div class="d-flex flex-column ">
                  <span style="font-size: 9px">
                    {{ $t('message.profile') }}
                  </span>
                   <span class="fw-bold" style="font-size: 11px">
                    {{ user.name.substr(0, user.name.indexOf(' '))}}
                  </span>
                 </div>
               </div>
              </router-link>
              <div v-else class="nav-link" @click="signIn">
                <div class="d-flex justify-content-center align-items-center">
                  <span style="font-size: 22px"><i class="fas fa-user-circle"></i></span>
                  <div class="d-flex flex-column">
                  <span style="font-size: 9px">
                    {{ $t('message.nav_signIn') }}
                  </span>
                    <span class="fw-bold" style="font-size: 11px">
                    Account
                  </span>
                  </div>
                </div>
              </div>

            </li>
            <li class="nav-item d-none d-lg-block">
              <a class="nav-link text-decoration-none align-items-center" style="cursor: pointer;color: var(--text-color);" @click="addToCartActive = !addToCartActive">
                <div class="d-flex align-items-center">
                  <div class="position-relative">
                    <span style="font-size: 22px;"> <i class="fas fa-shopping-cart"></i></span>
                    <span class="badge position-absolute shadow-sm rounded-circle text-white d-flex justify-content-center align-items-center"  style="background-color: red; width: 15px;height: 15px; top: 1px;left: 10px">{{orders.length>0?orders.length:'0'}}</span>
                  </div>
<!--                  <div class="ms-2 d-flex flex-column ">
                    <span style="font-size: 9px;">{{$t('message.total')}}</span>
                    <span class="fw-bold" style="font-size: 12px">0 Ks</span>
                  </div>-->
                </div>
              </a>
            </li>
          </ul>

        </div>

      </div>
    </nav>

  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import PhoneAuthModal from "@/components/PhoneAuthModal";
import Cart from "@/components/Cart";
import db, {timestamp} from "@/config/FirebaseInit";
export default {
  components:{Cart, PhoneAuthModal},
  data(){
    return{
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'mm', language: 'mmr', title: 'Myanmar' },
        { flag: 'cn', language: 'chn', title: 'China' }
      ],
      theme:'',
      darkModeActive:false,
      userTheme:"light-theme",
      loginModal:false,
      visible:false,

      addToCartActive:false,
      showCategory:false,
      totalPrice:0,
      allTotal:[],

      /*user:null,
      owner:null,*/
    }
  },
  name:"Navigation",

  mounted() {
    if(localStorage.getItem('locale')){
      this.$i18n.locale = localStorage.getItem('locale');
    }
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme); // updates the data-theme attribute
  },
  computed: {
    orders(){
      return this.$store.getters.getCartItems;
    },
    user(){
      return this.$store.getters.getCurrentUser;
    },
    admin(){
      return this.$store.getters.getAdmin;
    },

  },
  created() {
    this.$store.dispatch('getAdmin')
  },

  methods:{
    changeLocale(locale) {
      this.visible =!this.visible;
      console.log(locale);
      this.$i18n.locale = locale;
      localStorage.setItem('locale',locale)
    },
    toggleTheme() {
      this.visible =!this.visible;
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage
    },
    adminLogout(){
      firebase.auth().signOut().then(()=>{
        window.localStorage.removeItem('vuex');
        sessionStorage.clear();
        this.$store.state.admin='';
        console.log("logout");
        this.$router.push({name:'AdminLogin'});
      }).catch(error=>{
        alert(error.message);
      });
    },
    signIn() {
      this.loginModal = true;
    },

    //add to cart
    showCategories(){
      this.showCategory = !this.showCategory;
      this.$emit("getCategory", this.showCategory)
    },
    removeFromCart(productId){
      db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').doc(productId).delete();
    },
    increaseCount(order){
      (order.count)++;

    },
    decreaseCount(order){
      if(order.count>1){
        order.count--;
      }
    },
    getRandomStr(){
      const chars = "1234567890";
      let result = '';
      for ( let i = 0; i < 8; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    checkOut(){
      this.modalMessage="Are you sure to order?"
      this.confirmActive=true;
    },
    confirm(){
      this.confirmActive=false;
      this.loadingActive = true;
      const checkoutItem = {
        'orderUserId':this.user.id,
        'orderStatus':'pending',
        'id':'',
        'countList':[],
        'nameList':[],
        'sellPriceList':[],
        'buyPriceList':[],
        'productIdList':[],
        'itemCodeList':[],
        'images':[],
        'acceptedList':[],
        'time':'',
        'day':'',
        'year':'',
        'discount':"0",
        'promoCode':"0",
        'tax':"0",
        'totalPrice':'',
        'vouncherId':'',
        'paymentType':'',
        'transactionImg':'default',
      }
      const orderDB = db.collection(this.city).doc(this.currentShop.id).collection('orders').doc();
      checkoutItem.id= orderDB.id;
      const date = new Date();
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      // let numericMonth = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      /*let time = new Intl.DateTimeFormat('default',
          {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric'
          }).format(date);*/
      checkoutItem.month=month+"-"+year;
      checkoutItem.day = day+"-"+month+"-"+year;
      // checkoutItem.time =day+"/"+numericMonth+"/"+year+" "+time;
      checkoutItem.year = year;
      checkoutItem.time = timestamp;
      for(let i =0; i<this.orders.length; i++){
        checkoutItem.nameList.push(this.orders[i].itemName);
        if(this.orders[i].discount !== '0'){
          let discountSellPrice = 0;
          discountSellPrice = this.orders[i].sellPrice-(this.orders[i].sellPrice*(this.orders[i].discount/100));
          checkoutItem.sellPriceList.push(discountSellPrice.toString());
          this.totalPrice+=discountSellPrice * parseInt(this.orders[i].count);
        }else{
          checkoutItem.sellPriceList.push(this.orders[i].sellPrice.toString());
          this.totalPrice+=parseInt(this.orders[i].sellPrice ) * parseInt(this.orders[i].count);
        }
        checkoutItem.buyPriceList.push(this.orders[i].buyPrice);
        checkoutItem.countList.push(this.orders[i].count.toString());
        checkoutItem.images.push(this.orders[i].image);
        checkoutItem.itemCodeList.push(this.orders[i].itemCode);
        checkoutItem.productIdList.push(this.orders[i].productId);
        checkoutItem.acceptedList.push('available');

        if(i==(this.orders.length-1)){
          checkoutItem.totalPrice = this.totalPrice.toString();
          orderDB.set(checkoutItem)
              .then(()=>{
                db.collection('users').doc(this.user.id).collection('orders').doc(orderDB.id).set({'shopId':this.currentShop.id,'shopCity':this.city,'shopName':this.currentShop.name, ...checkoutItem}).then(()=>{
                  this.removeCart();
                }).catch(error=>{
                  this.loadingActive = false;
                  console.log(error.message)
                });
              }).catch(error=>{
            console.log(error.message);
          });
        }
      }
    },
    removeCart(){
      db.collection('users').doc(this.user.id).collection('cart').doc('shop').collection('items').get().then((snapshot)=>{
        snapshot.forEach(doc=>{
          doc.ref.delete();
        });
        db.collection('users').doc(this.user.id).collection('cart').doc('shop').delete().then(()=>{
          this.loadingActive = false;
          this.infoModalActive = true;
          this.modalMessage = "Order Success! You can check your orders status in profile.";
        })
      })
    },
    closeModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    closeLoginModal(){
      this.loginModal = !this.loginModal
    },
    closeCartModal(){
      this.addToCartActive = !this.addToCartActive;
    },
    cancel(){
      this.confirmActive = !this.confirmActive

    },

  }


}
</script>
<style>

</style>
<style scoped>
.nav-item{
  margin: 0px 5px;
}
.nav-link{
  color: var(--text-color);
  text-decoration: none;
}
.nav-link:hover{
  color: var(--primary);
}

.navbar{
  background-color: var(--white);
  color: var(--text-color);
}

li{
  cursor: pointer;
}
.navbar-toggler{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.4s;
}
.navbar-toggler:focus{
  transform: scale(1.02);
}
.navbar-toggler{
  border: none;
  outline: none;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.navbar-toggler span {
  display: block;
  background-color: var(--primary);
  height: 3px;
  border-radius: 20px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
/* top line needs a little padding */
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
/**
 * Animate collapse into X.
 */
/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg) ;
}

/**
 * Animate collapse open into hamburger menu
 */

/* top line moves back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
/* middle line goes back to regular color and opacity */
.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
@media (min-width: 768px) {

}

@media screen and (max-width: 800px) {
  .signin-nav{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .navbar-nav{
    text-align: center;
  }
  .signin-btn{
    margin: auto;
  }
  .navbar-collapse{
    margin-top: 100px;
    text-align: center;
    height: 100vh;
  }
  .nav-link{
    width: 150px;
    border-radius: 0px;
    margin: 10px auto 0 auto;
    border-bottom: 2px solid var(--light);
  }
  .dropdown{
    display: inline;
    margin: 10px auto 0 auto;
  }
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }

}
</style>