<template>
  <AppLayout>
    <div class="row px-3">
      <div class="col-12">
        <button class="btn" @click="$router.back()">
          <i class="fas fa-chevron-left"></i>
          <span class="fs-4 ms-2 mt-1" style="color: var(--text-color);">{{ $t('message.home_page') }}</span>
        </button>
      </div>

      <div class="col-12 col-lg-3 col-md-4 " v-if="shopInfo.onlineSell!=='free'">
        <router-link :to="{name:shopInfo.restaurant==='yes'?'SellProductRestaurant':'SellProduct2'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas icon fa-cash-register fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{$t('message.sell_product')}}</h4>
            </div>
          </div>
        </router-link>

      </div>
      <div class="col-12 col-lg-3 col-md-4" v-if="shopInfo.packageStatus === 'premium' || shopInfo.packageStatus === 'ultra'">
        <router-link :to="{name:'Orders'}" class="text-decoration-none m-4">
          <div class="card ">
            <div class="card-body mx-lg-auto text-center d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas icon fa-clipboard-list fa-2x"></i>

              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{ $t('message.orders') }}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4">
        <router-link :to="{name:'ShopDashboard'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center ">
              <div class="icon-badge p-2">
                <i class="fas icon fa-boxes-stacked fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{ $t('message.inventory') }}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4" >
        <router-link :to="{name:'FinancialDashboard'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas icon fa-coins fa-2x"></i>

              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{$t('message.financial_report')}}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4" >
        <router-link :to="{name:'Customer'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas fa-users fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{ $t('message.customers') }}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4" >
        <router-link :to="{name:'PaymentMethods'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas fa-money-check-dollar fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{ $t('message.payment_management') }}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4"  v-if="cashierAcc.accountLevel==='admin'" >
        <router-link :to="{name:'Cashier'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas fa-computer fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{$t('message.cashier_management')}}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4" v-if="shopInfo.packageStatus === 'ultra'|| shopInfo.packageStatus==='pro'">
        <router-link :to="{name:'WarehouseManagement'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas fa-warehouse fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{ $t('message.warehouse') }}</h4>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-lg-3 col-md-4" v-if="shopInfo.packageStatus === 'ultra'|| shopInfo.packageStatus==='pro'">
        <router-link :to="{name:'Branches'}" class="text-decoration-none m-4">
          <div class="card">
            <div class="card-body mx-lg-auto text-center  d-flex flex-lg-column justify-content-lg-center align-items-center">
              <div class="icon-badge p-2">
                <i class="fas fa-shop fa-2x"></i>
              </div>
              <h4 class=" text-center mt-3 ms-2 ms-lg-0">{{ $t('message.branches') }}</h4>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import db from "../../config/FirebaseInit";
import AppLayout from "@/components/AppLayout";
// import {firebase} from "@/config/FirebaseInit";
//import { inject } from 'vue'

export default {
  //inject: ['messaging'],
  components:{AppLayout},

  computed:{
    shopInfo(){
      return this.$store.getters.getShopInfo;
    },
    owner(){
      return this.$store.getters.getShopOwner;
    },
    cashier(){
      return this.$store.getters.getCashierAcc;
    },
    cashierAcc(){
      return this.$store.getters.getCashierAcc;
    },

  },
  mounted() {

    this.$store.dispatch("getCashierAcc",this.owner);
    this.$store.dispatch("getCashier", this.owner);


    //TODO: device sign out
    /*if(this.cashier!==''){
      db.collection(this.owner.city).doc(this.owner.shopId).collection('account').doc(this.cashier.id).get().then(snapshot=>{
        if(snapshot.data().deviceId !== navigator.userAgent){
          firebase.auth().signOut().then(()=>{
            window.localStorage.removeItem('vuex');
            sessionStorage.clear();
            this.$store.state.shopOwner='';
            this.$store.state.cashierAcc = '';
            console.log("logout");
            this.$router.push({name:'ShopOwnerLogin'});
          }).catch(error=>{
            alert(error.message);
          });
        }
      })

    }*/



  },
  created() {
    this.$store.dispatch("getCurrentShop")
    this.$store.dispatch("getShopInfo", this.owner)
  },
  // mounted () {
  //   console.log('Firebase cloud messaging object', this.$messaging)
  // },
  // //composition api
  // setup() {
  //   const messaging = inject('messaging')
  //
  //   console.log('Firebase cloud messaging object', messaging)
  // },
  methods:{
    addCashierCode(){
      db.collection(this.owner.city).doc(this.owner.shopId).collection('sellProducts').get().then(snapshot => {
        snapshot.forEach(doc=>{
          db.collection(this.owner.city).doc(this.owner.shopId).collection('sellProducts').doc(doc.data().id).update({
            'casherCode':'C1'
          }).then(()=>{
            console.log("Updated Ok!")
          })
        })
      })
    },


    makeScheduler() {
      let date = new Date();
      //let custom = '8:10 PM';
     // let customDate = Date.parse(custom);
      let time = new Intl.DateTimeFormat('en-US',
          {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric'
          }).format(date);
      // let time = new Intl.DateTimeFormat('en-US',
      //     {
      //       hour12: true,
      //       hour: 'numeric',
      //       minute: 'numeric'
      //     }).format(date);
      console.log(time);
      let testRef = db.collection('testing').doc();
      testRef.set({
        id:testRef.id,
        time:time,
        count:2
      })
    }
  }
}
</script>

<style scoped>
.card{
  padding: 5px;
  background-color: var(--light) !important;
}
.card-body{

  padding:5px;
  margin: 0 !important;
  width: 100%;
  background-color: var(--light) !important;
}
.card-body:hover{
  transform: scale(1.01);
  background-color: var(--softPrimary) !important;
}
h4{
  font-size: 18px;
}
.icon-badge{
  margin: 20px 0;
  width: 70px;
  height: 70px;
  border-radius: var(--border-radius);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>