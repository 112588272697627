<template>
  <div class="bg-home">
    <Loading v-if="loadingActive"/>
    <LoginModal v-show="loginModal" v-on:close-modal="closeModal"/>
    <div class="overlay">
      <div class="container-xxl">
        <div class="row pt-5 align-items-center px-5">
          <div class="col-12 col-lg-5 mx-auto ">
            <div class="left-box mt-lg-0 mt-5">
              <div class="mb-5 text-start">
<!--                <p class="m-0 " style="color: var(&#45;&#45;text-color); font-size: 35px; font-weight: bolder; ">{{$t('message.welcome')}}</p>-->
                <p class="m-0"  style="font-size:50px;color: var(--primary); font-weight: bolder;">Flash Mall</p>
                <p class="m-0 fw-bold" style="font-size: 25px; color: var(--text-color);" >{{$t('message.welcome2')}}</p>
              </div>
              <div class="rounded text-center">
                <div class="p-0 w-100">
                  <div class="d-flex rounded align-items-center p-1" style="background-color: var(--white);">
                    <span class="btn btn--white" style="color: var(--text-color);"><i class="fas fa-compass"></i></span>
                    <input class="form-control p-3 rounded border-0 w-80 text-start" style="background-color: var(--white)!important;border-radius: var(--border-radius);color: var(--text-color);" v-model="selectedCity" :placeholder="$t('message.search_cities')" @click="cityToggle">
                    <button class="btn" v-if="toggleClick" @click="toggleClick=false;"><i class="fas fa-times-circle"></i></button>
                  </div>
                  <div class="city-pop-up p-2 w-100"  v-if="toggleClick" >
                    <li v-for="(city, index) in filterCities" :key="index" class=" my-1 list-unstyled bg-white rounded py-2 text-start" style="color: var(--text-color);" @click="showCity(index)">{{city.name}}</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7 col-md-6 d-lg-block d-none p-0" @click="toggleClick=false;">
            <img src="../assets/flash-new-1.svg" class="mx-auto" style="width: 100%; height: 700px;"  alt="">
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LoginModal from "@/components/LoginModal";
import Loading from "@/components/Loading";
export default {
  name: 'Home',
  components:{LoginModal, Loading},
  data(){
    return{
      toggleClick:false,
      items:[],
      count:0,
      orderItem:null,
      selectedCity:'',
      loginModal:false,
      disableBtn:false,
      loadingActive:false,
      liveOrders:[]
    }
  },

  computed: {

    cities(){
      return this.$store.state.cities;
    },
    filterCities(){
      if(this.selectedCity!==''){
        return this.cities.filter(city=>{
          return city.name.toLocaleLowerCase().includes(this.selectedCity.toLocaleLowerCase());
        })
      }else {
        return this.cities;
      }
    }
  },
  methods:{
    cityToggle(){
      this.selectedCity='',
          this.toggleClick = !this.toggleClick;
    },
    showCity(index){
      this.selectedCity = this.cities[index].name;
      this.toggleClick = !this.toggleClick;
      this.goToShop();

    },
    search(){
      this.$store.state.commit("SET_SHOP_CITY", this.selectedCity);
    },
    goToShop(){
      if(this.selectedCity!=='Choose City'){
        this.$router.push({name:'Shops',params:{city:this.selectedCity}})
      }
    },

    closeModal(){
      this.loginModal = !this.loginModal;
    },
  }

}
</script>

<style scoped>
.card{
  border: none;
  background-color: rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(3);
}
.bg-home{
  background-color: var(--light);
  scroll-behavior:smooth;
  overflow-y: hidden;
  min-height: 100vh;
}


li{
  background-color: var(--light)!important;
  border-radius: var(--border-radius) !important;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
  padding: 10px;
}
li:hover{
  background-color: var(--side-bar-bg) !important;
  color: black;
}
.form-select{
  border: none;
  transition: 0.3s;
}
.form-select:hover{
  /*background-color: var(--light) !important;*/
}
.city-pop-up{
  margin-top: 5px;
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  position: sticky;
  overflow-y: scroll;
  min-height: 150px;
  max-height: 200px;
  /*margin-top: 5px;
  margin-left: 15px;*/
}
.btn{
  transition: 0.3s;
}
.btn:focus{
  transform: scale(0.9);
  outline: none !important;
  box-shadow: none !important;
}

.form-select:focus{
  outline: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 900px){
  .bg-home{
    background-image: url("../assets/flash-new-1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: bottom;
    /*padding-bottom: calc(100% * 3 / 4);*/
  }
  .city-pop-up{
    height: 200px;
  }
  .overlay{
   /*background-color: rgba(88, 101, 246, 0.49);*/
    min-height: 100vh;
  }

}
</style>
