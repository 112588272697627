<template>
  <div class="col-12 col-lg-2 align-items-end">
    <div class="row">
      <div class="col-12 p-0 ">
        <nav class="navbar navbar-expand-lg navbar-dark position-fixed w-100 top-0" style="z-index: 10000; left: 0;">
          <div class="container-fluid">
            <router-link class="d-flex text-decoration-none text-light align-items-center" :to="{name:'ShopHome'}">
              <img src="../assets/flash/1.png"  class="text-white" width="30" height="30" alt="" >
              <span class="fw-bold ms-1" >{{shopInfo.name}} </span>
             </router-link>
            <span class="badge rounded-pill d-none d-lg-block" style="font-size:12px;background-color: var(--side-bar-bg); color: var(--primary);">Flash Mall {{shopInfo.packageStatus}}</span>

            <button class="navbar-toggler border-0" :class="visible?null:'collapsed'"
                    :aria-expanded="visible ? 'true' : 'false'"
                    @click="showSideBar"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation">
              <span > </span>
              <span > </span>
              <span > </span>
            </button>
          </div>
        </nav>
      </div>

      <!--      default sidebar-->
      <div class="col-6 col-lg-2 d-none d-lg-block category-container">
        <div class="category-box shadow-sm">
          <div class="d-flex justify-content-between align-items-center rounded mb-3 p-1">
            <router-link  @click="routeClick" class="profile-link p-1 " :to="{name:'OwnerProfile'}">
              <img :src="shopInfo.profileUrl"  class="text-white" style="border-radius: var(--border-radius);" width="30" height="30" alt="">
              <span class="ms-1">{{$t('message.profile')}}</span>
            </router-link>

            <div class="d-flex profile-link align-items-center p-1">
              <button  @click="toggleTheme" aria-label="Toggle themes" class="btn rounded-circle d-flex justify-content-center align-items-center me-1" style="background-color: var(--light); width: 25px; height: 25px;">
                <span v-if="this.theme == 'darkMode'" class="text-white"><i class="fas fa-sun"></i></span>
                <span v-else><i class="fas fa-moon"></i></span>
              </button>
              <div class="dropdown ">
                <button class="btn rounded-circle text-center p-1 d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton1" style="background-color: var(--light);color: var(--text-color-light); width: 25px; height: 25px;" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fas fa-globe "></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" style="z-index: 30000;" aria-labelledby="dropdownMenuButton1">
                  <li class="dropdown-item" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                    <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul class="text-start d-block list-unstyled">
            <router-link  @click="routeClick" class="" :to="{name:'ShopHome'}">
              <li :to="{name:'ShopHome'}" class="d-inline-block align-items-center mx-1"  :class="this.$route.name==='ShopHome'?'list_active':''">
                <div class="d-flex align-items-center">
                  <div><i class="fas icon-bg fa-home"></i></div>
                  <div><span class="mx-2">{{$t('message.home_page')}}</span></div>
                </div>
              </li>
            </router-link>
            <router-link  @click="routeClick" class="" :to="{name:shopInfo.restaurant==='yes'?'SellProductRestaurant':'SellProduct2'}" v-if="shopInfo.onlineSell!=='free'">
              <li class="d-inline-block mx-1" :class="this.$route.name==='SellProduct2' || this.$route.name === 'SellProductRestaurant'?'list_active':''">
                <div class="d-flex align-item-center">
                  <div> <i class="fas icon-bg fa-cash-register"></i></div>
                  <div> <span class="mx-2 mb-1">{{$t('message.sell_product')}}</span></div>
                </div>

              </li>
            </router-link>
            <router-link  @click="routeClick" class="" :to="{name:'SellReport',params:{date_type:'day',selected_date:today}}" v-if="shopInfo.onlineSell!=='free'">
              <li class="d-inline-block mx-1" :class="this.$route.name==='SellReport'?'list_active':''">
                <div class="d-flex align-item-center">
                  <div></div>
                  <div></div>
                </div>
                <i class="fas icon-bg fa-square-poll-vertical"></i><span class="mx-2 mb-1">{{$t('message.sell_report')}}</span>
              </li>
            </router-link>
            <span class="mt-2 pl-1 d-none d-lg-block" v-if="shopInfo.packageStatus === 'ultra' || shopInfo.packageStatus === 'premium'">Orders</span>
            <router-link  @click="routeClick" class="" :to="{name:'OrderList',params:{type:'pending'}}" v-if="shopInfo.packageStatus === 'ultra' || shopInfo.packageStatus === 'premium'">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='OrderList'?'list_active':''">
                <div class="d-flex align-item-center">
                  <div><i class="fas icon-bg fa-shopping-bag"></i></div>
                  <div><span class="mx-2">{{$t('message.online_orders')}}</span></div>
                </div>

              </li>
            </router-link>
            <router-link  @click="routeClick" class="" :to="{name:'LiveOrderList',params:{live_id:'',order_status:'pending'}}" v-if="shopInfo.packageStatus === 'ultra' || shopInfo.packageStatus === 'premium'">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='LiveOrderList'?'list_active':''">
                <div class="d-flex align-item-center">
                  <div><i class="fas bg-danger text-white icon-bg fa-podcast"></i></div>
                  <div><span class="mx-2">{{$t('message.live_orders')}}</span></div>
                </div>

              </li>
            </router-link>

            <span class="mt-2 d-none d-lg-block my-3 ps-2" style="font-size: 13px;border-left: 2px solid var(--secondary);">{{$t('message.inventory')}}</span>
            <router-link  @click="routeClick" class="" :to="{name:'ShopDashboard'}">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='ShopDashboard'?'list_active':''">
                <div class="d-flex align-item-center">
                  <div class="d-flex align-items-center"><i class="fas icon-bg fa-boxes-stacked"></i></div>
                  <div><span class="mx-2">{{$t('message.inventory_control')}}</span></div>
                </div>

              </li>
            </router-link>
            <span class="mt-2 d-none d-lg-block my-3 ps-2" style="font-size: 13px;border-left: 2px solid var(--secondary);">{{ $t('message.setting') }}</span>
            <router-link @click="routeClick" class="" :to="{name:'GeneralSetting'}" v-if="shopInfo.onlineSell!=='free'">

              <li class="d-inline-block mx-1"  :class="this.$route.name==='GeneralSetting'?'list_active':''">
                <div class="d-flex align-item-center">
                  <div class="d-flex align-items-center"><i class="fas icon-bg fa-gear"></i></div>
                  <div><span class="mx-2">{{$t('message.general_settings')}}</span></div>
                </div>

              </li>
            </router-link>

          </ul>
        </div>
      </div>

<!--      Mobile Side bar-->
      <div class="col-8 col-md-4 p-0 col-lg-2 d-lg-none " :class="sidebarActive?'d-block':'d-none'">
        <div class="category-box shadow-sm">
          <div class="d-flex justify-content-between align-items-center rounded mb-3 p-1">
            <router-link  @click="routeClick" class="profile-link p-1 " :to="{name:'OwnerProfile'}">
              <img :src="shopInfo.profileUrl"  class="text-white" style="border-radius: var(--border-radius);" width="30" height="30" alt="">
              <span class="ms-1">{{$t('message.profile')}}</span>
            </router-link>

            <div class="d-flex profile-link align-items-center p-1">
              <button  @click="toggleTheme" aria-label="Toggle themes" class="btn rounded-circle d-flex justify-content-center align-items-center me-1" style="background-color: var(--light); width: 25px; height: 25px;">
                <span v-if="this.theme == 'darkMode'" class="text-white"><i class="fas fa-sun"></i></span>
                <span v-else><i class="fas fa-moon"></i></span>
              </button>
              <div class="dropdown ">
                <button class="btn rounded-circle text-center p-1 d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton1" style="background-color: var(--light);color: var(--text-color-light); width: 25px; height: 25px;" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fas fa-globe "></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" style="z-index: 30000;" aria-labelledby="dropdownMenuButton1">
                  <li class="dropdown-item" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                    <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul class="text-start d-block list-unstyled">
            <router-link  @click="routeClick" class="" :to="{name:'ShopHome'}">
              <li :to="{name:'ShopHome'}" class="d-inline-block align-items-center mx-1"  :class="this.$route.name==='ShopHome'?'list_active':''">
                <span><i class="fas icon-bg fa-home"></i></span><span class="mx-2">{{$t('message.home_page')}}</span>
              </li>
            </router-link>
            <router-link  @click="routeClick" class="" :to="{name:'SellProduct2'}" v-if="shopInfo.onlineSell!=='free'">
              <li class="d-inline-block mx-1" :class="this.$route.name==='SellProduct2'?'list_active':''">
                <i class="fas icon-bg fa-cash-register"></i><span class="mx-2 mb-1">{{$t('message.sell_product')}}</span>
              </li>
            </router-link>
            <router-link  @click="routeClick" class="" :to="{name:'SellReport',params:{date_type:'day',selected_date:today}}" v-if="shopInfo.onlineSell!=='free'">
              <li class="d-inline-block mx-1" :class="this.$route.name==='SellReport'?'list_active':''">
                <i class="fas icon-bg fa-square-poll-vertical"></i><span class="mx-2 mb-1">{{$t('message.sell_report')}}</span>
              </li>
            </router-link>
            <span class="mt-2 pl-1 d-none d-lg-block" v-if="shopInfo.packageStatus === 'ultra' || shopInfo.packageStatus === 'premium'">Orders</span>
            <router-link  @click="routeClick" class="" :to="{name:'OrderList',params:{type:'pending'}}" v-if="shopInfo.packageStatus === 'ultra' || shopInfo.packageStatus === 'premium'">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='OrderList'?'list_active':''">
                <i class="fas icon-bg fa-shopping-bag"></i><span class="mx-2">{{$t('message.online_orders')}}</span>
              </li>
            </router-link>
            <router-link  @click="routeClick" class="" :to="{name:'LiveOrderList',params:{live_id:'',order_status:'pending'}}" v-if="shopInfo.packageStatus === 'ultra' || shopInfo.packageStatus === 'premium'">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='LiveOrderList'?'list_active':''">
                <i class="fas bg-danger text-white icon-bg fa-podcast"></i><span class="mx-2">{{$t('message.live_orders')}}</span>
              </li>
            </router-link>

            <span class="mt-2 d-none d-lg-block">{{$t('message.inventory')}}</span>
            <router-link  @click="routeClick" class="" :to="{name:'ShopDashboard'}">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='ShopDashboard'?'list_active':''">
                <i class="fas icon-bg fa-boxes-stacked"></i><span class="mx-2">{{$t('message.inventory_control')}}</span>
              </li>
            </router-link>
            <span class="py-2">Setting</span>
            <router-link @click="routeClick" class="" :to="{name:'GeneralSetting'}" v-if="shopInfo.onlineSell!=='free'">
              <li class="d-inline-block mx-1"  :class="this.$route.name==='GeneralSetting'?'list_active':''">
                <i class="fas icon-bg fa-gear"></i><span class="mx-2">{{$t('message.general_settings')}}</span>
              </li>
            </router-link>

          </ul>
        </div>
      </div>
      <div class="col-4 col-md-8 p-0 col-lg-2 d-lg-none" :class="sidebarActive?'d-block':'d-none'">
        <div class="category-box" @click="routeClick" style="background-color:rgba(0,0,0,0.33);">
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  data(){
    return{
      guestMode:'',
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'mm', language: 'mmr', title: 'Myanmar' },
        { flag: 'cn', language: 'chn', title: 'China' }
      ],
      theme:'',
      darkModeActive:false,
      userTheme:"light-theme",
      visible:false,
      sidebarActive:false,
    }
  },
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },
    shopInfo(){
      return this.$store.getters.getShopInfo;
    },
    today(){
      return this.$store.getters.getDay;
    },
    cashierAcc(){
      return this.$store.getters.getCashierAcc;
    },
  },
  mounted() {
    if(localStorage.getItem('locale')){
      this.$i18n.locale = localStorage.getItem('locale');
    }
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme); //updates the data-theme attribute
  },
  methods:{
    changeLocale(locale) {
      this.visible =!this.visible;
      console.log(locale);
      this.$i18n.locale = locale;
      localStorage.setItem('locale',locale);
    },
    toggleTheme() {
      // this.visible =!this.visible;
      this.theme = this.theme == 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // sets the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value on local storage

    },
    showSideBar(){
      this.visible = !this.visible;
     this.sidebarActive = !this.sidebarActive;
    },
    routeClick() {
      this.sidebarActive = !this.sidebarActive;
      this.visible = !this.visible;
    },

  }

}
</script>

<style scoped>
span{
  font-size: 13px;
}
.dropdown-menu{
  background-color: var(--background-color);
  border: 0;
  padding: 5px;
  border-radius: var(--border-radius);
}
.icon-bg{
  width: 15px;
  height: 15px;
  background-color: var(--side-bar-bg);
  border-radius: var(--border-radius);
  padding: 5px;
}
li{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-color);
  background-color: var(--light);
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  border-radius: var(--border-radius);
  transition: 0.3s ;
}
li:hover, li:focus{
  background-color: var(--softPrimary);
  color: var(--text-color);
}
.list_active{
  background-color: var(--softPrimary);
  color: var(--text-color);
}
ul{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.category-box{
  color: var(--text-color);
  z-index: 1000;
  background-color: var(--white);
  border-right: 1px solid var(--light);
  height: 100%;
  margin-bottom: 100px;
  position: fixed;
  width: inherit;
  top: 43px;
  padding:10px 10px 80px 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}
/*.admin-bar{
  position: fixed;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: white;
  z-index: 1000;
}*/
/*toggle anim*/
.navbar{
  background-color: var(--primary);
}
.profile-link{
  text-decoration: none;
  background-color: var(--light);
  color:var(--text-color);
  border-radius: var(--border-radius);
  transition: 0.3s;
}
.profile-link:hover{
  background-color: var(--secondary);
}

.navbar-toggler{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.4s;
}
.navbar-toggler:focus{
  transform: scale(1.02);
}
.navbar-toggler{
  border: none;
  outline: none;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.navbar-toggler span {
  display: block;
  background-color: var(--light);
  height: 3px;
  border-radius: 20px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
/* top line needs a little padding */
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
/**
 * Animate collapse into X.
 */
/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg) ;
}

/**
 * Animate collapse open into hamburger menu
 */

/* top line moves back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
/* middle line goes back to regular color and opacity */
.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
/*end*/
@media only screen and (max-width: 1200px){
  .category-box {
    width: 180px;
  }
}
.category-container{
  margin-top: 50px;
}
@media only screen and (max-width: 1030px) {

  .category-box {
    padding-top: 10px;
    z-index: 5000;
    position: fixed;
    width: inherit;
    margin-top: 0px;
    margin-bottom: 100px;
    min-height:92%;
    align-items: center;
  }

}
</style>