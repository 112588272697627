import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
import VueExcelXlsx from "vue-excel-xlsx";
import "v-calendar/dist/style.css";
import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
import print from "vue3-print-nb";
import NextDatePicker from "vue-datepicker-next";
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import "vue-datepicker-next/index.css";
import "aos/dist/aos.css";
import "./registerServiceWorker";
import { createI18n } from "vue-i18n";
import FlagIcon from "vue-flag-icon";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import "v3-infinite-loading/lib/style.css";
import VueSplide from "@splidejs/vue-splide";

//pagination testing

const messages = {
  en: {
    message: {
      //owner
      //home
      home_page: "Home Page",
      sell_product: "Sell Products",
      sell_report: "Sell Report",
      inventory: "Inventory",
      financial_report: "Financial Report",
      general_settings: "General Settings",
      inventory_control: "Inventory Control",
      setting: "Setting",
      warehouse: "Ware House",
      online_orders: "Online Orders",

      //Sell Products
      barcode: "Barcode",
      itemCode: "Item Code",
      itemName: "Item Name",
      search_by_barcode: "Search by barcode",
      stock: "Stock",
      selected_items: "Selected Items",
      submit_sales: "Submit Sales",
      sale_setup: "Sale Setup",
      discount: "Discount",
      by_price: "By Price",
      by_rate: "By Rate",
      reset: "Reset",
      enter_discount_ks: "Enter discount (Ks)",
      enter_discount_rate: "Enter discount ( 1-100%)",
      enter_pay_amount: "Enter pay amount",
      please_choose_payment: "Please choose payment method",
      pay_amount: "Pay amount",
      add: "Add",
      customers: "Customers",
      //voucher
      list_view: "List Views",
      table_view: "Table Views",
      voucher_no: "Voucher No",
      items: "Items",
      qty: "Qty",
      price: "Price",
      amount: "Amount",
      total: "Total",
      tax: "Tax",
      net_amount: "Net Amount",
      cash_received: "Received",
      change: "Change",
      credit: "Credit",
      sell_now: "Sell now",
      thank: "Thank You",
      restrict_refund: "Refund does not allow",
      //end sell products

      //start sell report
      invoices: "Invoices",
      daily: "Daily",
      monthly: "Monthly",
      yearly: "Yearly",
      first_added: "Added",
      in_shop_sell: "In Shop Sell",
      online_sell: "Online Sell",
      live_sell: "Live Sell",
      info_update: "Info Update",
      stock_update: "Stock Update",
      waste_update: "Waste",
      export: "Export",
      sell_type: "Sell Type",
      customer_name: "Customer Name",
      sold_items: "Sold Items",
      control: "Control",
      count: "Count",
      details: "Details",
      enter_vocher_id: "Enter Voucher Id",
      //end sell report

      //start inventory
      product_management: "Products Management",
      categories_management: "Categories Management",
      live_management: "Live Sale Management",
      promotion_management: "Promotion Management",
      delivery_management: "Delivery Management",
      payment_management: "Payment Management",
      cashier_management: "Cashier Management",
      table_management: "Table Management",
      tables: "Tables",
      creat_new_table: "Create New Table",
      //end inventory
      //product create
      create_product: "Add New Product",
      expire_date: "Expire Date",
      //end product create

      //products management
      products: "Products",
      item_code: "Item Code",
      photo: "Photo",
      type: "Type",
      buy_price: "Buy Price",
      sell_price: "Sell Price",
      kitchen: "Kitchen",
      brand: "Brand",
      low_stock_products: "Low Stock Products",
      expire_list: "Expire Lists",
      show_stock_customer: "Show Stock to Customers",

      //end products management

      //category management
      category: "Category",
      create_category: "Create New Category",
      upload_image: "Upload Image",
      category_name: "Category name",
      category_lists: "Category Lists",
      cate_already_exist: "Category name is already added.",
      update_category: "Update Category",
      delete_confirm: "Are you sure to delete?",
      deleted: "Deleted.",
      updated: "Updated",
      category_delete_confirm:
        "This is category is used by your products. Are you sure to delete?",
      category_update_confirm:
        "This is category is used by your products. Are you sure to update?",
      enter_category: "Enter Category Name",
      //end category management

      //voucher details
      voucher_details: "Voucher Details",
      customer_info: "Customer Info",
      total_credit: "Total Credit",
      print: "Print",
      note: "",
      payment_history: "Payment History",
      select_payment_amount: "Please select payment method and fill amount",
      refund: "Refund",
      refund_manage: "Manage Refund Items",
      //end voucher details

      //product detail
      product_details: "Product Details",
      more_options: "More Options",
      edit: "Edit",
      add_stock: "Add Stock",
      add_relation: "Add Relation",
      waste: "Waste",
      product_history: "Product History",
      sellPrice: "Sell Price",
      delete: "Delete",

      weight: "Weight",
      add_discount: "Add Discount (1-100%)",
      unit: "Unit",
      relations_table: "Relation Tables",
      product_delete_warning:
        "Are you sure to delete? Records of this product will be permanently deleted.",
      purchase_lists: "Purchase Lists",
      purchase_voucher_setup: "Purchase Voucher Setup",
      //end product detail

      //branches
      branches: "Branches",

      //user
      //home

      welcome: "Welcome To",
      welcome2: "Explore Our Shopping Mall",
      nav_home: "Home",
      nav_about: "About",
      nav_contact: "Contact",
      nav_signIn: "Sign in",
      search_cities: "Search Cities",

      //Shop
      shop_page_title: "Current City",
      shop_page_choose: "Choose City",
      shop_page_categories: "Categories",
      shop_page_showAll: "Show All",
      shop_page_trending: "Trending Shops",
      shop_page_promotion: "Promotion Shops",
      shop_page_recommend: "Recommended Shops",

      //All shops, shop info, card
      search: "search",
      shop_profile: "Profile",
      shop_products: "Products",
      all: "All",
      seeMore: "See More",
      add_to_cart: "Add To Cart",
      description: "Description",
      size: "Size",
      color: "Color",
      out_of_stock: "Out Of Stock",
      item_available: "Items Available",
      best_seller: "Best Seller Items",

      //Usr Profile
      logout: "Logout",
      update: "Update",
      profile: "Profile",
      orders: "Orders",
      live_orders: "Live Orders",
      view_details: "View Details",
      pending: "Pending",
      confirm: "Confirm",
      confirmed: "Confirmed",
      paid: "Paid",
      cancel: "Cancel",
      canceled: "Canceled",
      order_id: "Order ID",
      total_price: "Total Price",
      phone: "Phone",
      email: "Email",
      name: "Name",
      password: "Password",
      address: "Address",
      signin: "Sing in",
      register: "Register",
      continue_with: "Continue with",

      order_details: "Order Details",
      date: "Date",
      delivery_method: "Delivery Methods",
      payment_method: "Payment Methods",
      choose_payment: "Choose Payment Methods",
      choose_delivery: "Choose Deliver Methods",
      account_no: "Account No",
      upload_transaction: "Upload Transaction Photo",
      send: "Send",
      select_city: "Select City",
      other: "Other",
      save: "Save",

      //refund
      available: "Available",
      refund_count: "Refund Count",

      //Cart
      cart_items: "Your Items",
      cost: "Cost",
      update_again: "Update Again?",
      update_info: "Update Your Information!",
      update_info_warning: "Please update your Address!",
      next: "Next",
      these_rur_info: "These are your current address?",
      promo_code: "Promotion Code",
      previous: "Previous",
      order_now: "Order Now",
      check: "Check",
      check_order: "Check Order",
      order_success:
        "Congratulations!! Your order is successfully arrived to shop owner.",
      sry_cant_promo: "Sorry!you can not use this promo code!",
      sry_purchase_least: "Sorry!You have to purchase at least",
      sry_code_expire: "Sorry!Your Code is expired.",
      sry_code_already: "Sorry your account has been used the promotion code!",
      sry_promo_limit: "Sorry the promotion limit is fulled!",

      //Live Cart
      message: "Message",
      live_order_cant:
        "Sorry, you can't order now! Please try again in next session.",
      buy_now: "Buy Now",
      live_sale_product: "Live Sale Products",

      //Dialog,
      yes: "Yes",
      no: "No",
      close: "Close",

      //Shop Card warning
      already_selected: "Already Selected",
      do_u_remove_item: "Do you want to remove items from previous shop?",

      //    login
      hello: "Hello Again!",
      wct_flash: "Welcome To Flash Mall",
      mail_already: "Mail already in used!",
      enter_phone: "Enter your Phone number",
      get_otp: "GET OTP",
      enter_code: "Enter your OTP code",
      verify: "Verify",
      resend: "Resend OTP",
      not_register: "You haven't register with this mail.",
      otp_sen:
        "OTP has been sent to your phone via SMS.Please wait for a moment",
      invalid_phone: "Invalid Phone Number Format !",
      invalid_code: "Invalid Verification Code!",
      invalid_otp: "Invalid OTP Format !",
      veri_success: "Verification Success",
      fill_name: "Please Fill user name!",
      fill_email: "Please Fill email!",
      fill_address: "Please Fill address!",

      //Financial Dashboard
      daily_report: "Daily Report",
      income: "Income",
      expenses: "Expenses",
      remaining_amount: "Remaining Amount",
      current_inventory: "Current Inventory",
      monthly_report: "Monthly Report",
      total_invoice: "Total Invoice",
      profit: "Profit",
      capital: "Capital",

      add_customer: "Add Customer",
      debt: "Debt",
      enter_name: "Enter Name",

      //Settings
      voucher_settings: "Voucher Settings",
      show_customer_name: "Show Customer Name",
      show_customer_phone: "Show Customer Phone",
      show_logo: "Show Logo",
      show_price_on_barcode: "Show Price On Barcode",
      show_item_name_on_barcode: "Show Item Name On Barcode",
      allow_refund: "Allow Refund",
      voucher_style: "Voucher Style",
      create_low_stock: "Create Low Stock",
      enter_low_stock: "Specify Low Stock count for your shop",
      expire_alert: "Expire Alert",
      enter_expire_alert: "Specify expiration days for alert",
      search_by_voucher_number: "Search by voucher number",
      enter_voucher_id: "Search by voucher number",
      clear_all: "Clear All",
      choose_table: "Choose Table",
      choose_kitchen: "Choose Kitchen",
      kitchen_print_setup: "Kitchen Print Setup",
      order_slip: "Kitchen Print Setup",
      optional: "Optional",
      enter_note: "Enter Note",
      enter_amount: "Enter Amount",
    },
  },
  mmr: {
    message: {
      home_page: "ပင်မစာမျက်နှာ",
      sell_product: "အရောင်းစာမျက်နှာ",
      sell_report: "အရောင်းမှတ်တမ်း",
      inventory: "ကုန်ပစ္စည်းစာရင်း",
      financial_report: "ငွေကြေးမှတ်တမ်း",
      general_settings: "အထွေထွေဆက်တင်များ",
      inventory_control: "ကုန်ပစ္စည်းစာရင်းထိန်းချုပ်မှု",
      setting: "စက်တင်",
      warehouse: "သိုလှောင်ရုံ",
      online_orders: "Onlineမှာယူမှုများ",
      barcode: "ဘားကုဒ်",
      itemCode: "ကုဒ်နံပါတ်",
      itemName: "ပစ္စည်းအမည်",
      search_by_barcode: "ဘားကုဒ်ဖြင့်ရှာရန်",
      stock: "အရေအတွက်",
      selected_items: "ရွေးချယ်ထားသောပစ္စည်းများ",
      submit_sales: "အရောင်းအတည်ပြု",
      sale_setup: "အရောင်းအပြင်အဆင်",
      discount: "လျှော့ဈေး",
      by_price: "ပမာဏဖြင့်",
      by_rate: "ရာခိုင်နှုန်းဖြင့်",
      reset: "ပြန်လည်သတ်မှတ်ရန်",
      enter_discount_ks: "လျှော့ဈေးထည့်ရန်(ကျပ်)",
      enter_discount_rate: "လျှော့ဈေးထည့်ရန်( ရာခိုင်နှုန်းဖြင့်)",
      enter_pay_amount: "ငွေပေးချေမှုပမာဏထည့်ရန်",
      please_choose_payment: "ငွေပေးချေမှုနည်းလမ်းကိုရွေးပါ။",
      pay_amount: "ရရှိသောငွေပမာဏ",
      add: "ထည့်ပါ။",
      customers: "ဖေါက်သည်များ",
      //voucher
      list_view: "List Views",
      table_view: "Table Views",
      voucher_no: "ဘောင်ချာနံပါတ်",
      items: "ပစ္စည်းများ",
      qty: "အရေအတွက်",
      price: "ဈေးနှုန်း",
      amount: "တန်ဖိုး",
      total: "စုစုပေါင်း",
      tax: "အခွန်",
      net_amount: "ကျသင့်ငွေ",
      cash_received: "ငွေလက်ခံ",
      change: "ပြန်အမ်းငွေ",
      credit: "ကျန်ငွေ",
      sell_now: "ရောင်းမည်။",
      thank: "ကျေးဇူးတင်ပါသည်။",
      restrict_refund: "ပြန်အမ်းငွေခွင့်မပြုပါ။",
      invoices: "ဘောင်ချာများ",
      daily: "နေ့စဉ်",
      monthly: "လစဉ်",
      yearly: "နှစ်စဉ်",
      first_added: "Added",
      in_shop_sell: "ဆိုင်တွင်းရောင်းချမှု",
      online_sell: "Online ရောင်းချမှု",
      live_sell: "Live ရောင်းချမှု",
      info_update: "အချက်အလက် Update",
      stock_update: "ကုန်ပစ္စည်း Update",
      waste_update: "အပျက်အစီး",
      export: "တင်ပို့ခြင်း",
      sell_type: "အရောင်းအမျိုးအစား",
      customer_name: "ဖေါက်သည်အမည်",
      sold_items: "ရောင်းချပြီးသောပစ္စည်းများ",
      control: "ထိန်းချုပ်မှု",
      count: "အရေအတွက်",
      details: "အသေးစိတ်",
      enter_vocher_id: "ဘောင်ချာ ID ကိုရိုက်ထည့်ပါ။",
      product_management: "ကုန်ပစ္စည်း စီမံခန့်ခွဲမှု",
      categories_management: "အမျိုးအစား စီမံခန့်ခွဲမှု",
      live_management: "Live Sale စီမံခန့်ခွဲမှု",
      promotion_management: "ပရိုမိုးရှင်း စီမံခန့်ခွဲမှု",
      delivery_management: "Delivery စီမံခန့်ခွဲမှု",
      payment_management: "ငွေပေးချေစနစ် စီမံခန့်ခွဲမှု",
      cashier_management: "ငွေကိုင် စီမံခန့်ခွဲမှု",
      table_management: "စားပွဲ စီမံခန့်ခွဲမှု",
      tables: "စားပွဲများ",
      creat_new_table: "စားပွဲအသစ်ဖန်တီးရန်",
      create_product: "ပစ္စည်းအသစ်ထည့်ရန်",
      expire_date: "သက်တမ်းကုန်ဆုံးရက်",
      products: "ကုန်ပစ္စည်းများ",
      item_code: "ကုဒ်နံပါတ်",
      photo: "ပုံ",
      type: "အမျိုးအစား",
      buy_price: "ဝယ်ဈေး",
      sell_price: "ရောင်းဈေး",
      kitchen: "မီးဖိုချောင်",
      brand: "အမှတ်တံဆိပ်",
      low_stock_products: "လက်ကျန်နည်းနေသောပစ္စည်းများ",
      expire_list: "သက်တမ်းကုန်စာရင်များ",
      show_stock_customer: "Ecommerce Site တွင်လက်ကျန်ပစ္စည်းများပြမည်။",
      category: "အမျိုးအစား",
      create_category: "အမျိုးအစားအသစ်ဖန်တီးရန်",
      upload_image: "ပုံထည့်ရန်",
      category_name: "အမျိုးအစားအမည်",
      category_lists: "အမျိုးအစားများ",
      cate_already_exist: "ဤအမျိုးအစားအမည်ကိုထည့်သွင်းပြီးသားဖြစ်ပါသည်။",
      update_category: "အမျိုးအစားပြောင်းမည်။",
      delete_confirm: "ဖျက်ရန်သေချာပါသလား",
      deleted: "ဖျက်ပြီးသော.",
      updated: "ပြင်ပြီးသော",
      category_delete_confirm:
        "ဤအမျိုးအစားဖြင့်ပစ္စည်းသွင်းထားပြီးဖြစ်ပါသည်။ ဖျက်ရန် သေချာပါသလား",
      category_update_confirm:
        "ဤအမျိုးအစားဖြင့်ပစ္စည်းသွင်းထားပြီးဖြစ်ပါသည်။ ပြင်ရန် သေချာပါသလား",
      enter_category: "အမျိုးအစားအမည်ထည့်ပါ။",
      voucher_details: "ဘောင်ချာအသေးစိတ်",
      customer_info: "ဖေါက်သည်၏အချက်အလက်",
      total_credit: "လက်ကျန်ငွေစုစုပေါင်း",
      print: "Print",
      purchase_voucher_setup: "ဝယ်ယူမှုဘောက်ချာ စနစ်",
      note: "",
      payment_history: "ငွေပေးချေမှုမှတ်တမ်း",
      select_payment_amount:
        "ကျေးဇူးပြု၍ ငွေပေးချေမှုနည်းလမ်းကိုရွေးပြီး ငွေပမာဏကိုထည့်ပါ။",
      refund: "ပြန်အမ်းငွေ",
      refund_manage: "ပြန်အမ်းငွေများကိုစီမံရန်",
      product_details: "ပစ္စည်းအသေးစိတ်",
      more_options: "လုပ်ဆောင်ချက်များ",
      edit: "ပြင်ရန်",
      add_stock: "အရေအတွက်ထပ်ထည့်ရန်",
      add_relation: "ဆက်သွယ်မှုဇယားထပ်ထည့်ရန်",
      waste: "အပျက်အစီး",
      product_history: "ကုန်ပစ္စည်းမှတ်တမ်း",
      sellPrice: "ရောင်းဈေး",
      delete: "ဖျက်ရန်",
      add_customer: "ဖောက်သည်ထည့်ပါ",
      debt: "အကြွေး",
      enter_name: "နာမည်ထည့်ပါ",

      weight: "အလေးချိန်",
      add_discount: "လျှော့ဈေးထည့်ရန်(ရာခိုင်နှုန်းဖြင့်)",
      unit: "ယူနစ်",
      relations_table: "ဆက်သွယ်မှုဇယား",
      product_delete_warning:
        " ဖျက်ပစ်ရန်သေချာပါသလား။ ဤပစ္စည်း၏မှတ်တမ်းများလည်းပျက်သွားမည်ဖြစ်သည်။",
      purchase_lists: "ဝယ်ယူမှုစာရင်းများ",
      branches: "ဆိုင်ခွဲများ",
      welcome: "Welcome To",
      welcome2: "Explore Our Shopping Mall",
      nav_home: "Home",
      nav_about: "About",
      nav_contact: "Contact",
      nav_signIn: "Sign in",
      search_cities: "မြို့အမည်ရှာရန်",
      shop_page_title: "လက်ရှိမြို့",
      shop_page_choose: "မြို့ရွေးရန်",
      shop_page_categories: "အမျိုးအစားများ",
      shop_page_showAll: "အကုန်ကြည့်ရန်",
      shop_page_trending: "ခေတ်စားနေသောဆိုင်များ",
      shop_page_promotion: "ပရိုမိုးရှင်းဆိုင်များ",
      shop_page_recommend: "အကြုံပြုသောဆိုင်များ",
      search: "ရှာရန်",
      shop_profile: "ပရိုဖိုင်",
      shop_products: "ကုန်ပစ္စည်းများ",
      all: "အားလုံး",
      seeMore: "ထပ်ကြည့်ရန်",
      add_to_cart: "ခြင်းထဲသို့ထည့်ရန်",
      description: "ဖော်ပြချက်",
      size: "အရွယ်အစား",
      color: "အရောင်",
      out_of_stock: "ပစ္စည်းလက်ကျန်ကုန်နေပါသည်။",
      item_available: "ရရှိနိုင်သောပစ္စည်းများ",
      best_seller: "ရောင်းအားအကောင်းဆုံးပစ္စည်းများ",
      logout: "အကောင့်မှထွက်ရန်",
      update: "Update",
      profile: "Profile",
      orders: "အော်ဒါများ",
      live_orders: "Liveအော်ဒါများ",
      view_details: "အသေးစိတ်ကြည့်ရန်",
      pending: "စောင့်ဆိုင်းပါ",
      confirm: "အတည်ပြုပါ",
      confirmed: "အတည်ပြုသည်။",
      paid: "ပေးချေရန်",
      cancel: "မလုပ်ပါ",
      canceled: "ပယ်ဖျက်လိုက်ပါသည်။",
      order_id: "အော်ဒါအိုင်ဒီ",
      total_price: "စုစုပေါင်းဈေးနှုန်း",
      phone: "ဖုန်းနံပါတ်",
      email: "အီးမေးလ်",
      name: "နာမည်",
      password: "စကားဝှက်",
      address: "လိပ်စာ",
      signin: "Sing in",
      register: "Register",
      continue_with: "Continue with",

      order_details: "အော်ဒါအသေးစိတ်",
      date: "ရက်စွဲ",
      delivery_method: "ပို့ဆောင်မှုနည်းလမ်းများ",
      payment_method: "ပေးချေမှုနည်းလမ်းများ",
      choose_payment: "ပေးချေမှုနည်းလမ်းများကိုရွေးချယ်ပါ",
      choose_delivery: "ပို့ဆောင်မှုနည်းလမ်းများကိုရွေးချယ်ပါ",
      account_no: "အကောင့်နံပါတ်",
      upload_transaction: "ငွေလွှဲပြီးကြောင်းပုံတင်ရန်",
      send: "ပို့ရန်",
      select_city: "မြို့ရွေးချယ်ရန်",
      other: "အခြား",
      save: "သိမ်းရန်",
      available: "ရရှိနိုင်သော",
      refund_count: "ပြန်နှုတ်မည့်အရေအတွက်",
      cart_items: "သင့်ပစ္စည်းများ",
      cost: "စရိတ်",
      update_again: "ထပ်ပြင်ရန်သေချာပါသလား",
      update_info: "သင်၏အချက်အလက်များကိုပြင်ရန်",
      update_info_warning: "ကျေးဇူးပြု၍သင်၏လိပ်စာကိုအဆင့်မြှင့်ပါ",
      next: "နောက်တစ်ခု",
      these_rur_info: "ဒီလိပ်စာက ခုသင်၏လိပ်စာဟုတ်ပါသလား",
      promo_code: "ပရိုးမိုးရှင်းကုဒ်",
      previous: "ရှေ့တစ်ခု",
      order_now: "အော်ဒါတင်ရန်",
      check: "စစ်ဆေးရန်",
      check_order: "အော်ဒါစစ်ရန်",
      order_success:
        "ဂုဏ်ယူပါသည်။ သင်၏အော်ဒါသည်ဆိုင်ပိုင်ရှင်ထံသို့အောင်မြင်စွာရောက်ရှိသွားပါသည်။",
      sry_cant_promo: "ဝမ်းနည်းပါသည်! ယခုပရိုမိုကုဒ်ကိုအသုံးပြု၍မရနိုင်ပါ။",
      sry_purchase_least: "ဝမ်းနည်းပါတယ်! အရင်ဆုံး ဝယ်ယူမှုလုပ်ဆောင်ပါ။",
      sry_code_expire: "ဝမ်းနည်းပါသည်! သင်၏ကုဒ်နံပါတ်မှာသက်တမ်းကုန်နေပါသည်။",
      sry_code_already:
        "ဝမ်းနည်းပါသည်!သင်၏အကောင့်မှာပရိုမိုးရှင်းကုဒ်ကိုအသုံးပြုပြီးသားဖြစ်နေပါသည်။",
      sry_promo_limit: "ဝမ်းနည်းပါသည်! ပရိုးမိုးရှင်းလစ်မစ်ပြည့်နေပါသည်။",
      message: "မက်ဆေ့",
      live_order_cant:
        "ဝမ်းနည်းပါတယ်၊ သင်အခု မှာလို့မရပါ။ နောက်ဆက်ရှင်တွင် ထပ်စမ်းကြည့်ပါ။",
      buy_now: "ဝယ်မည်",
      live_sale_product: "ရောင်းချနေသော ပစ္စည်းများ",

      //Dialog,
      yes: "အတည်ပြုသည်",
      no: "မလုပ်ဆောင်ပါ",
      close: "ပိတ်ပါ",

      //shop card warning
      already_selected: "ရွေးထားပြီးသားဖြစ်နေပါတယ်။",
      do_u_remove_item:
        "ယခင်ဆိုင်မှာ ရွေးထားသော ပစ္စည်းများကို ဖယ်ရှားလိုပါသလား။",

      hello: "မင်္ဂလာပါ",
      wct_flash: "Flash Mall မှကြိုဆိုပါတယ်။",
      enter_phone: "သင့်ဖုန်းနံပါတ်ကို ထည့်ပါ။",
      get_otp: "OTP ကုဒ်ရယူမည်",
      enter_code: "OTP ကုဒ်နံပါတ် ရိုက်ထည့်ပါ။",
      verify: "စစ်ဆေးပါ",
      resend: "OTP ကုဒ် ပြန်ပို့ပါ",

      mail_already: "မေးလ်ကို အသုံးပြုထားပြီးဖြစ်သည်။!",
      not_register: "သင်သည် ဤမေးလ်ဖြင့် စာရင်းသွင်းထားခြင်း မရှိသေးပါ။",
      otp_sent:
        "OTP ကို SMS မှတဆင့် သင့်ဖုန်းဆီသို့ ပို့လိုက်ပါပြီ။ ခဏစောင့်ပါ။",
      invalid_phone: "ဖုန်းနံပါတ် မမှန်ကန်ပါ။",
      invalid_code: "Invalid Verification Code!",
      invalid_otp: "မမှန်ကန်သော အတည်ပြုကုဒ်။ !",
      veri_success: "အတည်ပြုခြင်း အောင်မြင်သည်။",
      fill_name: "အသုံးပြုသူအမည်ဖြည့်ပါ။",
      fill_email: "ကျေးဇူးပြု၍ အီးမေးလ်ဖြည့်ပါ။",
      fill_address: "လိပ်စာဖြည့်ပါ။",

      //Financial Dashboard
      daily_report: "နေ့စဉ်အစီရင်ခံစာ",
      income: "ဝင်ငွေ",
      expenses: "အသုံးစရိတ်များ",
      remaining_amount: "လက်ကျန်ငွေပမာဏ",
      current_inventory: "လက်ရှိစာရင်း",
      monthly_report: "လစဉ်အစီရင်ခံစာ",
      total_invoice: "စုစုပေါင်းပြေစာ",
      profit: "အမြတ်",
      capital: "အရင်းအနှီး",

      //Settings
      voucher_settings: "ဘောက်ချာ ဆက်တင်များ",
      show_customer_name: "ဝယ်ယူသူအမည်ပြပါ",
      show_customer_phone: "ဝယ်ယူသူဖုန်းနံပါတ်ကို ပြပါ",
      show_logo: "လိုဂိုပြပါ",
      show_price_on_barcode: "ဘားကုဒ်တွင်စျေးနှုန်းပြသပါ",
      show_item_name_on_barcode: "ဘားကုဒ်တွင် ပစ္စည်းအမည်ကို ပြပါ",
      allow_refund: "ပြန်အမ်းခွင့်ပြုမလား",
      voucher_style: "ဘောက်ချာပုံစံ",
      create_low_stock: "low stock ဖန်တီးပါ",
      enter_low_stock: "သင့်ဆိုင်အတွက် Low Stock အရေအတွက်ကို သတ်မှတ်ပါ",
      expire_alert: "သက်တမ်းကုန် သတိပေးချက်",
      enter_expire_alert: "သတိပေးချက်အတွက် သက်တမ်းကုန်ဆုံးရက်ကို သတ်မှတ်ပါ",
      clear_all: "အားလုံးကိုရှင်းပါ",
      choose_table: "စားပွဲရွေးချယ်ပါ",
      choose_kitchen: "မီးဖိုချောင်ရွေးချယ်ပါ",
      kitchen_print_setup: "မီးဖိုချောင်ပုံနှိပ်စနစ်",
      order_slip: "အော်ဒါစလစ်",
      enter_note: "မှတ်စုရိုက်ထည့်ပါ",
      optional: "ရွေးချယ်ခွင့်",
      enter_amount: "ပမာဏကိုထည့်ပါ",
    },
  },
  chn: {
    message: {
      home_page: "主页",
      sell_product: "销售产品",
      sell_report: "销售报告",
      inventory: "存货",
      financial_report: "财务报告",
      general_settings: "常规设置",
      inventory_control: "库存控制",
      setting: "环境",
      warehouse: "仓库",
      online_orders: "网上订单",
      barcode: "条码",
      itemCode: "项目代码",
      itemName: "项目名",
      search_by_barcode: "按条形码搜索",
      stock: "库存",
      selected_items: "精选项目",
      submit_sales: "提交销售情况",
      sale_setup: "销售设置",
      discount: "折扣",
      by_price: "按价格",
      by_rate: "以百分比表示",
      reset: "重置",
      enter_discount_ks: "输入折扣 (Ks)",
      enter_discount_rate: "输入折扣t ( 1-100%)",
      enter_pay_amount: "输入支付金额",
      please_choose_payment: "请选择付款方式",
      pay_amount: "支付金额",
      add: "添加",
      customers: "顾客",
      //voucher
      list_view: "列表樣式",
      table_view: "網格樣式",
      voucher_no: "凭证号",
      items: "项目",
      qty: "数量",
      price: "价格",
      amount: "数量",
      total: "全部的",
      tax: "税",
      net_amount: "收费",
      cash_received: "已收到",
      change: "退款",
      credit: "信用",
      sell_now: "现在卖",
      thank: "谢谢",
      restrict_refund: "不允许退款",
      invoices: "发票",
      daily: "日常的",
      monthly: "每月",
      yearly: "每年",
      first_added: "添加",
      in_shop_sell: "店内销售",
      online_sell: "网上销售",
      live_sell: "现场销售",
      info_update: "信息更新",
      stock_update: "库存更新",
      waste_update: "浪费",
      export: "出口",
      sell_type: "销售类型",
      customer_name: "顾客姓名",
      sold_items: "出售物品",
      control: "反对l",
      count: "伯爵",
      details: "细节",
      enter_vocher_id: "输入优惠券 ID",
      product_management: "产品管理",
      categories_management: "类别管理",
      live_management: "现场销售管理",
      promotion_management: "促销管理t",
      delivery_management: "配送管理",
      payment_management: "支付管理",
      cashier_management: "收银管理",
      table_management: "餐桌管理",
      tables: "表",
      creat_new_table: "创建新表",
      create_product: "添加新产品",
      expire_date: "到期日期",
      products: "产品",
      item_code: "项目代码",
      photo: "照片",
      type: "类型",
      add_customer: "添加客户",
      debt: "债务",
      enter_name: "输入名字",
      buy_price: "购买价格",
      sell_price: "售价",
      kitchen: "厨房",
      brand: "品牌",
      low_stock_products: "低库存产品",
      expire_list: "过期列表",
      show_stock_customer: "向客户展示库存",
      category: "类别",
      create_category: "创建新类别",
      upload_image: "上传图片",
      category_name: "分类名称",
      category_lists: "类别列表",
      cate_already_exist: "类别名称已添加.",
      update_category: "更新类别",
      delete_confirm: "确定删除吗？",
      deleted: "已删除.",
      updated: "更新",
      category_delete_confirm: "这是您的产品使用的类别。 确定删除吗？",
      category_update_confirm: "这是您的产品使用的类别。 您确定要更新吗？",
      enter_category: "输入类别名称",
      voucher_details: "优惠券详情",
      customer_info: "客户信息",
      total_credit: "总学分",
      print: "打印",
      note: "",
      payment_history: "支付历史",
      select_payment_amount: "请选择付款方式并填写金额",
      refund: "退款",
      refund_manage: "管理退款项目",
      product_details: "产品详情",
      more_options: "更多的选择",
      edit: "编辑",
      add_stock: "添加库存",
      add_relation: "添加关系",
      waste: "浪费",
      product_history: "Product History",
      sellPrice: "售价",
      delete: "删除",
      weight: "重量",
      add_discount: "添加折扣(1-100%)",
      unit: "單元",
      relations_table: "关系表",
      product_delete_warning: "确定删除吗？ 该产品的记录将被永久删除。",
      purchase_lists: "采购清单",
      branches: "分支机构",

      //end product detail

      //Home
      welcome: "欢迎",
      welcome2: "探索我们的购物中心",
      nav_home: "家",
      nav_about: "关于我们",
      nav_contact: "联系我们",
      nav_signIn: "登入",
      search_cities: "搜索城市",
      shop_page_title: "当前城市",
      shop_page_choose: "选择城市",
      shop_page_categories: "类别",
      shop_page_showAll: "显示所有",
      shop_page_trending: "热门店铺",
      shop_page_promotion: "促销店",
      shop_page_recommend: "推荐店铺",
      search: "搜索",
      shop_profile: "轮廓",
      shop_products: "产品",
      all: "全部",
      seeMore: "查看更多",
      add_to_cart: "添加到购物车",
      description: "描述",
      size: "尺寸",
      color: "颜色",
      out_of_stock: "缺货",
      item_available: "可用项目",
      best_seller: "畅销商品",
      logout: "Logout",
      update: "更新",
      profile: "轮廓",
      orders: "命令",
      live_orders: "实时订单",
      view_details: "查看详情",
      pending: "待办的",
      confirm: "确认",
      confirmed: "Confirmed",
      paid: "有薪酬的",
      cancel: "取消",
      canceled: "取消",
      order_id: "订单号",
      total_price: "总价",
      phone: "电话",
      email: "电子邮件",
      name: "姓名",
      password: "密码",
      address: "地址",
      signin: "Sing in",
      register: "登记",
      continue_with: "Continue with",
      order_details: "订单详细信息",
      date: "日期",
      delivery_method: "配送方式",
      payment_method: "支付方式",
      choose_payment: "选择付款方式",
      choose_delivery: "选择交付方式",
      account_no: "帐号",
      upload_transaction: "上传交易照片",
      send: "发送",
      select_city: "选择城市",
      other: "其他",
      save: "节省",
      available: "可用的",
      refund_count: "退款次数",
      cart_items: "您的物品",
      cost: "成本",
      update_again: "再次更新？",
      update_info: "更新您的信息！",
      update_info_warning: "请更新您的地址！",
      next: "下一个",
      these_rur_info: "这些是你现在的地址？",
      promo_code: "促销代码",
      previous: "以前的",
      order_now: "现在下单",
      check: "查看",
      purchase_voucher_setup: "購買憑證設置",
      check_order: "检查订单",
      order_success: "恭喜！ 您的订单已成功送达店主。",
      sry_cant_promo: "抱歉！您不能使用此促销代码！",
      sry_purchase_least: "抱歉！您至少需要购买",
      sry_code_expire: "抱歉！您的代码已过期。",
      sry_code_already: "抱歉，您的帐户已使用促销代码！",
      sry_promo_limit: "对不起，促销限额已满！",
      message: "信息",
      live_order_cant: "抱歉，您现在不能下单！ 请在下一个会话中重试。",
      buy_now: "立即购买",
      live_sale_product: "现场销售产品",
      yes: "是的",
      no: "不",
      close: "关闭",
      already_selected: "已选择",
      do_u_remove_item: "你想从以前的商店中删除项目吗？",
      hello: "Hello Again!",
      wct_flash: "Welcome To Flash Mall",
      mail_already: "邮件已被使用!",
      enter_phone: "输入你的电话号码",
      get_otp: "获取一次性密码",
      enter_code: "输入您的一次性密码",
      verify: "核实",
      resend: "重新发送一次性密码",
      not_register: "你还没有用这个邮箱注册。",
      otp_sen: "OTP已通过短信发送至您的手机，请稍候",
      invalid_phone: "电话号码格式无效！",
      invalid_code: "无效验证码!",
      invalid_otp: "OTP 格式无效 !",
      veri_success: "验证成功",
      fill_name: "请填写用户名！",
      fill_email: "请填写邮箱！",
      fill_address: "请填写地址！",

      daily_report: "每日报告",
      income: "收入",
      expenses: "花费",
      remaining_amount: "剩余数量",
      current_inventory: "当前库存",
      monthly_report: "月度报告",
      total_invoice: "发票总额",
      profit: "利润",
      capital: "资",

      //Settings
      voucher_settings: "優惠券設置",
      show_customer_name: "顯示客戶姓名",
      show_customer_phone: "顯示客戶電話號碼",
      show_logo: "顯示標誌",
      show_price_on_barcode: "在條形碼上顯示價格",
      show_item_name_on_barcode: "在條形碼上顯示商品名稱",
      allow_refund: "允許退款嗎？",
      voucher_style: "憑證樣式",
      create_low_stock: "造成庫存不足",
      enter_low_stock: "為您的商店指定低庫存數量",
      expire_alert: "過期提醒",
      enter_expire_alert: "指定警報的到期天數",
      enter_voucher_id: "按優惠券編號搜索",
      clear_all: "全部清除",
      choose_table: "選擇桌子",
      choose_kitchen: "選擇廚房",
      kitchen_print_setup: "廚房打印設置",
      order_slip: "訂單單",
      optional: "選修的",
      enter_note: "輸入註釋",
      enter_amount: "輸入金額",
    },
  },
};
// 2. Create i18n instance with options
const i18n = createI18n({
  locale: "en", // set locale
  fallbackLocale: "mmr", // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});
import { messaging } from "./config/FirebaseInit";
import VueBarcode from "@chenfengyuan/vue-barcode";

const app = createApp(App);
const VueTelInputOptions = {
  mode: "international",
  //onlyCountries: ['MM', 'GH', "GB", "US", "CA"]
};

app.config.globalProperties.$messaging = messaging;
app
  .use(store)
  .use(router)
  .use(VueTelInput, VueTelInputOptions)
  .use(VueSplide)
  .use(VueTelInput)
  .use(i18n)
  .use(Vue3Lottie)
  .use(FlagIcon)
  .use(VueExcelXlsx)
  .use(print)
  .use(SetupCalendar, {})
  .component(VueBarcode.name, VueBarcode)
  .component("Calendar", Calendar)
  .component("NextDatePicker", NextDatePicker)
  .component("DatePicker", DatePicker)
  .component("fon t-awesome-icon", FontAwesomeIcon)
  .mount("#app");
