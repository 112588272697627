<template>
  <AdminNavigation>
    <div class="row">
      <div class="col-12 table-responsive">

        <div class="d-flex align-items-center">
          <span class="badge rounded-circle text-black me-2" style="background-color: var(--side-bar-bg);">{{users.length}}</span><span class="fs-4 me-2">Users</span>
          <div class="d-flex align-items-center m-1 p-1 rounded" style="background-color: var(--light);">
            <i class="fas fa-search"></i>
            <input type="text" class="form-control ms-1 border-0" placeholder="search" v-model="searchValue">
          </div>
        </div>
        <table class="table table-hover border">
          <thead>
          <tr>
            <th class="text-nowrap">#</th>
            <th class="text-nowrap">Name</th>
            <th class="text-nowrap">Email</th>
            <th class="text-nowrap">Phone</th>
            <th class="text-nowrap">Control</th>
          </tr>

          </thead>
          <tbody>
          <tr v-for="(user, index) in filterUsers" :key="index">
            <td class="text-nowrap">{{index+1}}</td>
            <td class="text-nowrap">{{user.name}}</td>
            <td class="text-nowrap">{{user.email}}</td>
            <td class="text-nowrap">{{user.phone}}</td>
            <td class="text-nowrap">
              <button class="btn btn-outline-dark btn-sm">
                <i class="fas fa-info-circle"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="pt-3">
          <pagination
              :totalPages="pageCount"
              :perPage="perPage"
              :currentPage="currentPage"
              :pages="users"
              @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
  </AdminNavigation>
</template>

<script>
import "firebase/compat/auth";
import db from "@/config/FirebaseInit";
import AdminNavigation from "@/components/AdminNavigation";
import Pagination from "@/components/Pagination";
export default {
  components:{AdminNavigation, Pagination},
  data(){
    return{
      users:[],
      currentPage:0,
      perPage:10,
      searchValue:'',
    }
  },
  computed:{
    pageCount(){
      return Math.ceil(this.users.length/this.perPage)
    },

    offsetCount(){
      return this.currentPage*this.perPage
    },
    filterUsers(){
      let userList = [];
      if(this.searchValue!==""){
        userList = this.users.filter(own=>own.name.toLowerCase().includes(this.searchValue.toLowerCase()) || own.email.includes(this.searchValue) ||  own.phone.includes(this.searchValue))
        console.log('filtering',userList)
        return userList.slice(this.offsetCount, this.offsetCount + this.perPage);
      }else {
        return this.users.slice(this.offsetCount, this.offsetCount + this.perPage);
      }

    }
  },
  async mounted(){
    await db.collection('users').onSnapshot(snapshot => {
      snapshot.forEach(doc=>{
        this.users.push(doc.data())
      })
    });
  },
  methods:{
    onPageChange(page) {
      console.log(page)
      this.currentPage = page;
    },
  }
}
</script>

<style scoped>

</style>