<template>
  <app-layout>
    <Loading v-if="loadingActive" />
    <InfoDialog
      v-if="infoModalActive"
      :modal-message="modalMessage"
      @close-modal="closeModal"
    />
    <ConfirmDialog
      :modal-message="modalMessage"
      v-if="confirmActive"
      @confirm="confirm"
      @cancel="cancel"
    />
    <RestRelationMenuDialog
      :selectedProduct="selectedProduct"
      :owner="owner"
      :selectedRelations="selectedRelations"
      v-if="showDialog"
      @confirm-modal="confirmMenuItem"
      @close-modal="closeDialog"
    />

    <div class="col-12">
      <div class="row">
        <div
          class="modal fade"
          id="tableModal"
          tabindex="-1"
          aria-labelledby="tableModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div
              class="modal-content border-0 mt-5"
              style="
                background-color: var(--background-color);
                border-radius: var(--border-radius);
              "
            >
              <div class="d-flex justify-content-between p-3">
                <h5 class="modal-title" id="tableModalLabel">
                  {{ $t("message.choose_table") }}
                </h5>
                <button
                  type="button"
                  id="close-table"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body" style="height: 70vh; overflow-y: scroll">
                <div class="row px-3">
                  <div class="col-12">
                    <div
                      class="category-box d-lg-flex justify-content-between align-items-center"
                    >
                      <ul
                        class="text-start d-block list-unstyled border-0 py-3 align-center"
                      >
                        <li
                          class="category-list d-inline-block"
                          :class="
                            selectedTableCategory === 'All'
                              ? 'category-list-active'
                              : ''
                          "
                          @click="selectTableCategory('All')"
                        >
                          {{ $t("message.all") }}
                        </li>
                        <li
                          v-for="(category, index) in tableCategories"
                          style="background-color: var(--light)"
                          :key="index"
                          class="category-list d-inline-block"
                          :class="
                            selectedTableCategory === category.name
                              ? 'category-list-active'
                              : ''
                          "
                          @click="selectTableCategory(category.name)"
                        >
                          <span class="mx-2">{{ category.name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="col-2 align-middle"
                    v-for="product in filterTables.product"
                    :key="product.id"
                  >
                    <div
                      class="card"
                      style="cursor: pointer"
                      @click="chooseTable(product)"
                    >
                      <div
                        class="card-body"
                        style="background-color: var(--light)"
                        :class="
                          selectedTable.id == product.id
                            ? 'category-list-active'
                            : ''
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span class="fs-5">{{ product.name }}</span>
                          <span
                            :class="
                              product.categoryType === 'Free'
                                ? 'text-success'
                                : 'text-danger'
                            "
                            >{{ product.categoryType }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <pagination
                  :totalPages="pageCountTable"
                  :perPage="perPageTable"
                  :currentPage="currentPageTable"
                  :pages="tables"
                  @pagechanged="onPageChangeTable"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="kitchenModal"
          tabindex="-1"
          aria-labelledby="kitchenModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div
              class="modal-content border-0 mt-5"
              style="
                background-color: var(--background-color);
                border-radius: var(--border-radius);
              "
            >
              <div class="d-flex justify-content-between p-3">
                <h5 class="modal-title" id="kitchenModalLabel">
                  {{ $t("message.kitchen_print_setup") }}
                </h5>
                <button
                  type="button"
                  id="close-table"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body" style="height: 70vh; overflow-y: scroll">
                <div class="container">
                  <div class="row">
                    <div class="col-6 fs-5">
                      <h3>{{ $t("message.choose_kitchen") }}</h3>
                      <div class="form-check mb-2">
                        <input
                          style="cursor: pointer"
                          class="form-check-input"
                          type="radio"
                          id="0"
                          value=""
                          name="radioGroup"
                          v-model="selectedKitchen"
                        />
                        <label
                          style="cursor: pointer"
                          class="form-check-label"
                          :for="0"
                        >
                          All
                        </label>
                      </div>
                      <div
                        class="form-check mb-2"
                        v-for="option in kitchens"
                        :key="option.id"
                      >
                        <input
                          style="cursor: pointer"
                          class="form-check-input"
                          type="radio"
                          :id="option.id"
                          :value="option.name"
                          name="radioGroup"
                          v-model="selectedKitchen"
                        />
                        <label
                          style="cursor: pointer"
                          class="form-check-label"
                          :for="option.id"
                        >
                          {{ option.name }}
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <h3>{{ $t("message.order_slip") }}</h3>
                      <!-- Kitchen Voucher -->
                      <div
                        class="bg-white text-black p-2"
                        style="font-size: 14px"
                        ref="kitchenPrintcontent"
                        id="kitchenPrintcontent"
                      >
                        <div
                          class="text-start d-flex fw-bold flex-column pt-1 mx-2 mt-2 mb-2"
                        >
                          <span class="mb-1 fs-5"
                            >Kitchen:
                            {{
                              selectedKitchen ? selectedKitchen : "All"
                            }}</span
                          >
                          <span class="mb-1 fs-5"
                            >Table: {{ selectedTable.name }}</span
                          >
                          <span class="mb-1 fs-6"
                            >{{ $t("message.date") }}: {{ dateTime }}</span
                          >
                        </div>
                        <table
                          class="w-100 table-1 fs-6 border-0 table border-white"
                        >
                          <thead class="px-2">
                            <tr
                              class="py-1"
                              style="
                                border-bottom: 2px dotted black !important;
                                border-right: 0 !important;
                                border-left: 0 !important;
                                border-top: 0 !important;
                              "
                            >
                              <th
                                class="bg-white border-0 text-start text-wrap align-start"
                                style="width: 40%"
                              >
                                {{ $t("message.items") }}
                              </th>
                              <th
                                class="bg-white border-0 text-end text-nowrap align-start"
                              >
                                {{ $t("message.qty") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody class="border-0 p-0">
                            <tr
                              class="border-0 mb-1 px-0 py-1"
                              v-for="(product, index) in kitchenPrintItems"
                              :key="index"
                            >
                              <td
                                :hidden="
                                  Math.abs(
                                    product.printCount - product.count
                                  ) === 0
                                "
                                class="border-0 text-start text-wrap align-start"
                                style="width: 40%"
                              >
                                {{ index + 1 }}. {{ product.itemName }}
                              </td>
                              <td
                                :hidden="
                                  Math.abs(
                                    product.printCount - product.count
                                  ) === 0
                                "
                                class="border-0 text-end text-nowrap align-start"
                              >
                                {{
                                  Math.abs(product.printCount - product.count)
                                }}
                                {{ product.relation }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          style="border-bottom: 2px dotted black !important"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="w-100 p-3 d-flex">
                  <button
                    data-bs-dismiss="modal"
                    class="btn w-50 btn-primary mx-auto"
                    @click="handlePrintKitchenItem"
                    v-print="'#kitchenPrintcontent'"
                  >
                    <i class="fas fa-print"></i> {{ $t("message.print") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--          search box-->
        <div
          class="col-12 col-lg-5 col-md-6"
          style="background-color: var(--light)"
        >
          <div
            class="d-flex flex-column rounded p-1"
            style="background-color: var(--light)"
          >
            <div
              class="d-flex align-items-end p-1"
              style="
                background-color: var(--background-color);
                border-radius: var(--border-radius);
              "
            >
              <button class="btn" style="color: var(--text-color)">
                <i class="fas fa-search"></i>
              </button>
              <input
                type="text"
                ref="barcodeSearch"
                autofocus
                v-model="search"
                :placeholder="$t('message.search')"
                class="form-control"
                @click="showSearchBoxHandler"
              />
            </div>
          </div>

          <!--            Category -->
          <div class="category-box">
            <span
              class="badge rounded-circle float-end text-dark mb-1"
              style="background-color: var(--softPrimary)"
              >{{
                selectedSellProduct.length > 0 ? selectedSellProduct.length : ""
              }}</span
            >
            <ul class="text-start pb-3 d-block list-unstyled border-0">
              <li
                class="d-inline-block mx-1 category-list"
                :class="
                  selectedCategory === 'All' ? 'category-list-active' : ''
                "
                style="background-color: var(--light)"
                @click="selectCate('All')"
              >
                <span class="mx-2"> All</span>
              </li>
              <li
                v-for="(category, index) in filterCategories"
                style="background-color: var(--light)"
                :key="index"
                class="category-list d-inline-block"
                :class="
                  selectedCategory === category.name
                    ? 'category-list-active'
                    : ''
                "
                @click="selectCate(category.name)"
              >
                <span class="mx-2">{{ category.name }}</span>
              </li>
            </ul>
          </div>

          <div class="ms-1">
            <div class="row product-box">
              <div
                class="col-4"
                v-for="(product, index) in filterProducts.product"
                :key="index"
                @click="selectForCart(product)"
              >
                <div class="product-card text-center">
                  <div class="">
                    <img
                      :src="product.images[0]"
                      v-if="product.images.length > 0"
                      style="
                        width: 120px;
                        height: 120px;
                        border-radius: var(--border-radius) !important;
                      "
                      alt=""
                    />
                    <img
                      src="../../assets/flash/1.png"
                      v-else
                      style="
                        opacity: 0.3;
                        width: 120px;
                        height: 120px;
                        border-radius: var(--border-radius) !important;
                      "
                      alt=""
                    />
                  </div>
                  <div
                    class="badge d-flex justify-content-between align-items-center"
                    style="
                      position: absolute;
                      top: 5px;
                      border-radius: var(--border-radius);
                      background-color: var(--secondary);
                      right: 5px;
                    "
                  >
                    <span class="fw-bold">{{ product.stock }} Pcs</span>
                  </div>
                  <div
                    class="ms-2 p-1 text-start"
                    style="border-radius: var(--border-radius)"
                  >
                    <div class="d-flex text-start flex-column">
                      <div
                        class="d-flex justify-content-between text-wrap align-items-start"
                        style="height: 25px"
                      >
                        <span class="fw-bold">{{ product.itemName }}</span>
                      </div>
                      <span
                        class="mt-3"
                        style="font-size: 12px; color: var(--text-color-light)"
                        >{{ product.barcode }}</span
                      >
                      <span class="fw-bold" style="color: var(--primary)"
                        >{{ product.sellPrice }} Ks</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row py-2 border-top me-1">
              <pagination
                :totalPages="pageCount"
                :perPage="perPage"
                :currentPage="currentPage"
                :pages="allProducts"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
        <!--          selected box-->
        <div
          class="col-12 col-lg-7 col-md-6 text-center rounded"
          v-if="showSelectedBox"
        >
          <div
            class="d-flex rounded align-items-center justify-content-between py-2 px-2"
            style="background-color: var(--light)"
          >
            <div class="d-flex">
              <button
                @click="handleChooseTable"
                class="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#tableModal"
              >
                <i class="fas fa-chair me-2"></i>
                {{ $t("message.choose_table") }}
              </button>
            </div>
            <span class="w-50"
              >{{ selectedTable?.category }}/ {{ selectedTable?.name }}</span
            >

            <button
              data-bs-toggle="modal"
              data-bs-target="#kitchenModal"
              class="btn btn-sm text-white d-flex d-none d-lg-block"
              style="background-color: var(--dark) !important"
            >
              <i class="fas fa-print"></i> {{ $t("message.kitchen") }}
            </button>
            <button
              type="button"
              style="background-color: var(--primary)"
              :disabled="selectedSellProduct.length <= 0"
              class="btn btn-sm text-white d-flex d-none d-lg-block"
              @click="submitSale"
            >
              {{ $t("message.submit_sales") }}
              <!-- {{ $t("message.sale_setup") }} -->
            </button>
          </div>
          <!--             TODO-->
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex mt-2">
              <div
                class="p-1"
                style="
                  background-color: var(--light);
                  border-radius: var(--border-radius);
                "
              >
                <button
                  class="btn btn-sm me-2"
                  :class="gridView === 'true' ? 'active-btn' : ''"
                  @click="setViewOption('true')"
                >
                  <i class="fas fa-grip"></i>
                </button>
                <button
                  class="btn btn-sm"
                  :class="gridView === 'false' ? 'active-btn' : ''"
                  @click="setViewOption('false')"
                >
                  <i class="fas fa-border-all"></i>
                </button>
              </div>
              <button
                class="btn btn-sm btn-danger my-1 ms-2"
                @click="clearCartItems"
              >
                <i class="fa fa-filter-circle-xmark"></i>
                <span class="ms-2">{{ $t("message.clear_all") }}</span>
              </button>
            </div>
            <div>
              <span class="text-end">{{ $t("message.total") }}</span
              ><span v-if="shopInfo.tax !== '0'">
                + tax {{ shopInfo.tax }} %
              </span>
              <span class="text-success">= {{ totalForShow }} Ks</span>
            </div>
          </div>
          <div class="row selected-box" v-if="gridView === 'true'">
            <div
              class="col-12 col-lg-6 col-md-6"
              v-for="(order, index) in selectedSellProduct"
              :key="index"
            >
              <div
                class="card p-0"
                style="
                  background-color: var(--side-bar-bg) !important;
                  padding: 4px !important;
                "
              >
                <div
                  class="card-body m-0"
                  style="background-color: var(--background-color) !important"
                >
                  <div class="d-flex">
                    <img
                      :src="order.images[0]"
                      v-if="order.images.length > 0"
                      style="
                        object-fit: cover;
                        border-radius: var(--border-radius);
                        width: 120px;
                        height: 120px;
                        border: 2px solid var(--light);
                      "
                      alt=""
                    />
                    <img
                      src="../../assets/flash/1.png"
                      v-else
                      style="width: 120px; height: 120px"
                      alt=""
                    />
                    <div class="d-flex text-start flex-column ms-1 w-100">
                      <div
                        class="d-flex justify-content-between align-items-start"
                        style="height: 50px; font-size: 13px"
                      >
                        <p class="text-wrap fw-bold">{{ order.itemName }}</p>
                        <button
                          class="btn btn-sm btn-sm"
                          :id="order.id"
                          @click="removeFromCart(order, index)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                      <div
                        class="d-flex w-100 mt-1 rounded justify-content-between align-items-center p-1"
                        style="
                          background-color: var(--light);
                          border-radius: var(--border-radius);
                        "
                      >
                        <button
                          class="btn btn-sm text-white"
                          style="background-color: var(--secondary)"
                          @click="decreaseCount(order)"
                        >
                          -
                        </button>
                        <input
                          class="form-control mx-1 w-50"
                          type="number"
                          min="1"
                          style="background-color: var(--background-color)"
                          v-model="order.frontNumber"
                          @input="countChangeInput(order)"
                        />
                        <button
                          class="btn btn-sm text-white"
                          style="background-color: var(--secondary)"
                          @click="increaseCount(order)"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 m-0 p-0">
              <div
                class="d-flex d-lg-none p-1"
                style="
                  position: fixed;
                  bottom: 0;
                  background-color: var(--background-color);
                "
              >
                <button
                  class="btn w-25 me-2"
                  style="border: 2px solid var(--softPrimary)"
                >
                  Cancel
                </button>
                <button
                  style="background-color: var(--primary)"
                  :disabled="selectedSellProduct.length <= 0"
                  class="btn w-75 text-white"
                  @click="submitSale"
                >
                  {{ $t("message.submit_sale") }}
                </button>
              </div>
            </div>
          </div>
          <div class="row selected-box" v-else>
            <div class="col-12">
              <!--                {{order}}-->
              <div class="table-responsive p-0 mt-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>*</th>
                      <th
                        class="text-truncate text-start"
                        style="max-width: 250px"
                      >
                        {{ $t("message.itemName") }}
                      </th>
                      <th>{{ $t("message.sell_price") }}</th>
                      <th>{{ $t("message.unit") }}</th>
                      <th>{{ $t("message.qty") }}</th>
                      <th>{{ $t("message.cost") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(order, index) in selectedSellProduct"
                      :key="index"
                    >
                      <td>
                        <button
                          class="btn btn-sm btn-sm text-danger"
                          :id="order.id"
                          @click="removeFromCart(order, index)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                      <td class="d-none">
                        <img
                          :src="order.images[0]"
                          v-if="order.images.length > 0"
                          style="
                            object-fit: cover;
                            border-radius: var(--border-radius);
                            width: 50px;
                            height: 50px;
                            border: 2px solid var(--light);
                          "
                          alt=""
                        />
                        <img
                          src="../../assets/flash/1.png"
                          v-else
                          style="width: 50px; height: 50px"
                          alt=""
                        />
                      </td>
                      <td
                        class="text-truncate text-start"
                        style="max-width: 250px"
                      >
                        {{ order.itemName }}
                      </td>
                      <td>{{ order.sellPrice }} Ks</td>
                      <td>{{ order.relation }}</td>
                      <td class="text-center">
                        <div
                          class="d-flex rounded justify-content-between align-items-center p-1"
                          style="width: 150px"
                        >
                          <button
                            class="btn btn-sm text-white"
                            style="background-color: var(--secondary)"
                            @click="decreaseCount(order)"
                          >
                            -
                          </button>
                          <!-- <span class="mx-2">{{order.frontNumber}}</span>-->
                          <input
                            class="form-control mx-1"
                            @focus="$event.target.select()"
                            style="background-color: var(--background-color)"
                            v-model="order.count"
                            @input="countChangeInput(order)"
                          />
                          <button
                            class="btn btn-sm text-white"
                            style="background-color: var(--secondary)"
                            @click="increaseCount(order)"
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td>
                        {{
                          (
                            parseInt(order.count) * parseInt(order.sellPrice)
                          ).toLocaleString()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 m-0 p-0">
              <div
                class="d-flex w-100 d-lg-none p-1"
                style="
                  position: fixed;
                  bottom: 0;
                  background-color: var(--background-color);
                "
              >
                <button
                  class="btn w-25 me-2"
                  style="border: 2px solid var(--softPrimary)"
                >
                  {{ $t("message.cancel") }}
                </button>
                <button
                  style="background-color: var(--primary)"
                  :disabled="selectedSellProduct.length <= 0"
                  class="btn w-75 text-white"
                  @click="submitSale"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {{ $t("message.submit_sale") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import db, { timestamp } from "../../config/FirebaseInit";
import ConfirmDialog from "@/components/ConfirmDialog";
import InfoDialog from "@/components/InfoDialog";
import Loading from "@/components/Loading";
import "lodash/debounce";
import Pagination from "@/components/Pagination";
import RestRelationMenuDialog from "@/components/RestRelationMenuDialog";
import AppLayout from "../../components/AppLayout.vue";
export default {
  components: {
    Loading,
    ConfirmDialog,
    InfoDialog,
    Pagination,
    RestRelationMenuDialog,
    AppLayout,
  },
  data() {
    return {
      selectedKitchen: null,
      //pagination
      currentPage: 0,
      perPage: 20,
      currentPageTable: 0,
      perPageTable: 20,
      //end pagination
      // selectedTable:'',
      selectedTableCategory: "All",
      showCart: false,
      showTableVoucher: false,
      infoModalActive: false,
      modalMessage: "",
      loadingActive: false,
      confirmActive: false,
      selectedSellProductContainer: 1,
      newDiscount: "",
      newDiscountPrice: "",
      newPromotion: "",
      showDialog: false,

      products: [],
      tax: 0,
      //search data
      search: "",
      searchCode: "",
      searchProduct: null,
      showSelectedBox: true,
      showSearchBox: true,
      totalPrice: 0,
      originalProduct: "",
      showSearch: false,

      showAlert: false,
      alertMessage: "",
      discountType: "price",

      cashier: [],
      //searched product
      product: {
        name: "",
        images: [],
        buyPrice: "",
        sellPrice: "",
        barcode: "",
        itemCode: "",
      },
      selectedCategory: "All",
      selectedSearchType: "",
      selectedSelectionIndex: "",

      //for count*price of each order
      costPerItem: [],
      casherCode: "",

      //Relations
      selectedProduct: "",
      pcsList: [],
      customers: [],
      searchCustomer: "",
      selectedSellProduct: [],
      // selectedSearchType:'barcode',
      //final order confirm obj
      checkoutItem: {
        countList: [],
        nameList: [],
        sellPriceList: [],
        buyPriceList: [],
        productIdList: [],
        itemCodeList: [],
        productUnitList: [],
        backNumberList: [],
        casherCode: "",
        sellType: "offlineSell",
        id: "",
        time: "",
        day: "",
        year: "",
        month: "",
        customerAddress: "",
        customerName: "",
        customerPhone: "",
        customerId: "",
        dateTime: "",
        discount: "0",
        promoCode: "0",
        tax: "0",
        totalPrice: "0",
        cashReceived: "0",
        credit: "0",
        change: "0",
        vouncherId: "",
        paymentMethod: "",
        paymentId: "",
        note: "",
        tableName: "",
      },
      showWarning: true,
      warningMessage: "",
      sellNowActive: false,
      searchedCustomerList: [],
      timeout: null,
      selectedRelations: [],
      relation: "Pcs",
      relationNumber: "1",
      allProducts: [],
    };
  },
  computed: {
    kitchens() {
      return this.$store.getters.getKitchens;
    },
    kitchenPrintItems() {
      if (this.selectedKitchen) {
        return this.selectedSellProduct.filter(
          (p) => p.kitchenType === this.selectedKitchen
        );
      }
      return this.selectedSellProduct;
    },
    selectedTable() {
      return this.$store.getters.getSelectedTable;
    },
    tableCategories() {
      return this.$store.getters.getTableCategories;
    },
    pageCountTable() {
      return Math.ceil(this.filterTables.prodCount / this.perPageTable);
    },
    offsetCountTable() {
      return this.currentPageTable * this.perPageTable;
    },
    tables() {
      return this.$store.getters.getAllTables;
    },

    filterTables() {
      let searchProducts = [];
      if (this.selectedTableCategory !== "All") {
        searchProducts = this.tables.filter(
          (pd) => pd.category === this.selectedTableCategory
        );
        return {
          product: searchProducts.slice(
            this.offsetCountTable,
            this.offsetCountTable + this.perPageTable
          ),
          prodCount: searchProducts.length,
        };
      } else {
        return {
          product: this.tables.slice(
            this.offsetCountTable,
            this.offsetCountTable + this.perPageTable
          ),
          prodCount: this.tables.length,
        };
      }
    },

    productCategories() {
      return this.$store.getters.getProductCategories;
    },
    filterCategories() {
      return this.productCategories.filter((cate) => cate.name !== "All");
    },
    // allProducts(){
    //   return this.$store.getters.getAllProducts
    // },
    filterProducts() {
      let filterProduct = [];
      if (this.search !== "") {
        filterProduct = this.allProducts.filter(
          (pro) =>
            pro.barcode.toLowerCase().includes(this.search.toLowerCase()) ||
            pro.itemName.toLowerCase().includes(this.search.toLowerCase())
        );
        return {
          product: filterProduct.slice(
            this.offsetCount,
            this.offsetCount + this.perPage
          ),
          prodCount: filterProduct.length,
        };
      } else if (this.selectedCategory !== "All") {
        filterProduct = this.allProducts.filter(
          (pro) => pro.type === this.selectedCategory
        );
        return {
          product: filterProduct.slice(
            this.offsetCount,
            this.offsetCount + this.perPage
          ),
          prodCount: filterProduct.length,
        };
      } else {
        return {
          product: this.allProducts.slice(
            this.offsetCount,
            this.offsetCount + this.perPage
          ),
          prodCount: this.allProducts.length,
        };
      }
    },
    pageCount() {
      return Math.ceil(this.filterProducts.prodCount / this.perPage);
    },
    offsetCount() {
      return this.currentPage * this.perPage;
    },
    gridView() {
      return localStorage.getItem("gridView");
    },
    totalForShow() {
      let tot = 0;
      if (this.selectedSellProduct.length > 0) {
        this.selectedSellProduct.forEach((order) => {
          tot += parseInt(order.sellPrice) * parseInt(order.count);
        });
      }
      if (this.shopInfo.tax !== "0") {
        tot += parseInt((tot * (parseInt(this.shopInfo.tax) / 100)).toFixed(0));
      }
      return tot;
    },

    selectedSellProductLists() {
      return this.$store.getters.getSelectedSellProductLists;
    },

    showName() {
      return localStorage.getItem("showCustomerName");
    },
    showLogo() {
      return localStorage.getItem("showLogoImage");
    },
    showPhone() {
      return localStorage.getItem("showCustomerPhone");
    },
    showRefund() {
      return localStorage.getItem("showRefund");
    },
    cashierAcc() {
      return this.$store.getters.getCashierAcc;
    },

    owner() {
      return this.$store.getters.getShopOwner;
    },

    shopInfo() {
      return this.$store.getters.getShopInfo;
    },

    today() {
      return this.$store.getters.getDay;
    },
    thisMonth() {
      return this.$store.getters.getMonth;
    },
    thisYear() {
      return this.$store.getters.getYear;
    },
    dateTime() {
      return this.$store.getters.getDateTime;
    },
  },

  async mounted() {
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getDateTime");
    this.$store.dispatch("getYear");
    this.$store.dispatch("getShopInfo", this.owner);
    this.$store.dispatch("fetchKitchens", this.owner);

    this.$store.dispatch("getCashierAcc", this.owner);
    await db
      .collection(this.owner.city)
      .doc(this.owner.shopId)
      .collection("products")
      .orderBy("itemName", "asc")
      .get()
      .then((snapshot) => {
        // const result = [];
        this.allProducts = [];
        snapshot.forEach(
          (doc) => {
            this.allProducts.push(doc.data());
          },
          (error) => {
            console.log("Product", error.message);
          }
        );
        // console.log(result)
      });
    // this.$store.dispatch("getAllProducts", this.owner);
    this.$refs.barcodeSearch.focus();
    if (window.innerWidth < 500) {
      this.showSelectedBox = false;
      this.showDiscountBox = false;
      this.showCustomerBox = false;
    }

    if (localStorage.getItem("voucherStyle")) {
      this.voucherStyle = localStorage.getItem("voucherStyle");
    }

    this.$store.dispatch("getProductCategories", this.owner);
    // this.$store.dispatch("getAllProducts",this.owner);
    this.$store.dispatch("getTableCategories", this.owner);
    this.$store.dispatch("fetchAllTables", this.owner);

    await db
      .collection(this.owner.city)
      .doc(this.owner.shopId)
      .collection("sellProducts")
      .where("day", "==", this.today)
      .where("casherCode", "==", this.cashierAcc.accountCode)
      .onSnapshot((snapshot) => {
        this.sellItems = [];
        snapshot.forEach((doc) => {
          this.sellItems.push(doc.data());
        });
      });
    if (this.selectedTable) {
      this.getTableOrders();
    }
  },

  methods: {
    handleChooseTable() {
      this.$store.dispatch("fetchAllTables", this.owner);
    },
    handlePrintKitchenItem() {
      console.log(this.kitchenPrintItems);
      this.kitchenPrintItems.map((kp) => {
        // let updatePrintCount = Math.abs(parseInt(kp.printCount) - parseInt(kp.count))
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .collection("orders")
          .doc(kp.id)
          .update({
            printCount: kp.count + "",
          });
      });
      this.$("#kitchenModal").modal("hide");
    },

    getTableOrders() {
      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("tables")
        .doc(this.selectedTable.id)
        .collection("orders")
        .onSnapshot((snapshot) => {
          this.selectedSellProduct = [];
          snapshot.forEach((doc) => {
            this.selectedSellProduct.push(doc.data());
          });
        });
    },
    selectTableCategory(name) {
      this.selectedTableCategory = name;
    },
    chooseTable(table) {
      // this.selectedTable = table;
      this.$store.commit("SET_SELECTED_TABLE", table);
      document.getElementById("close-table").click();
      this.getTableOrders();
    },
    selectCate(name) {
      this.selectedCategory = name;
      this.currentPage = 0;
    },
    onPageChange(page) {
      console.log(page);
      this.currentPage = page;
    },
    onPageChangeTable(page) {
      console.log(page);
      this.currentPageTable = page;
    },
    focusInput() {
      this.$refs.barcodeSearch.focus();
    },
    setViewOption(value) {
      localStorage.setItem("gridView", value);
      this.gridView = value;
      window.location.reload();
    },

    showSearchBoxHandler() {
      if (window.innerWidth < 500) {
        this.showSearchBox = true;
        this.showSelectedBox = false;
      }
    },
    showSelectedBoxHandler() {
      if (window.innerWidth < 500) {
        this.showSelectedBox = true;
        this.showSearchBox = false;
      }
    },
    clearCartItems() {
      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("tables")
        .doc(this.selectedTable.id)
        .collection("orders")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            db.collection(this.owner.city)
              .doc(this.owner.shopId)
              .collection("tables")
              .doc(this.selectedTable.id)
              .collection("orders")
              .doc(doc.data().id)
              .delete();
          });
        });
      this.changeTableStatusFree();
    },
    selectSearchType(type) {
      this.focusInput();
      localStorage.setItem("selectedProductSearchType", type);
      if (this.selectedSearchType) {
        this.selectedSearchType = localStorage.getItem(
          "selectedProductSearchType"
        );
      }
    },
    clearSearch() {
      this.focusInput();

      this.products = [];
      this.search = "";
    },

    /*searchProductFromServer:debounce(function (e){
      if(e.target.value!==''){
        //orderBy(this.selectedSearchType).startAt(this.search).endAt(this.search+"~")
        db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection('products').where('itemName',">=",e.target.value).where("itemName","<=",e.target.value+'\uf8ff').onSnapshot(snapshot=>{
          this.products = [];
          snapshot.docs.filter(e=>{
            const isDuplicate = this.products.includes(e.id);
            if(!isDuplicate){
              this.products.push(e.data());
              return true;
            }
            return false;
          })
        })
      }else {
        this.products = [];
      }
    }, 500),*/

    checkProduct(payload) {
      console.log(payload);
      if (
        this.selectedSellProduct.some(
          (p) =>
            p.productId === this.selectedProduct.id &&
            p.relation === payload.relation
        )
      ) {
        let sameIndex = this.selectedSellProduct.findIndex(
          (p) =>
            p.productId === this.selectedProduct.id &&
            p.relation === payload.relation
        );
        let newCount = parseInt(this.selectedSellProduct[sameIndex].count) + 1;
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .collection("orders")
          .doc(this.selectedSellProduct[sameIndex].id)
          .update({
            count: newCount + "",
          });
      } else {
        this.$store.dispatch("getDateTime");
        this.$store.dispatch("getDay");
        this.$store.dispatch("getMonth");
        this.$store.dispatch("getYear");
        let orderRef = db
          .collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .collection("orders")
          .doc();

        orderRef.set({
          barcode: this.selectedProduct.barcode,
          buyPrice: this.selectedProduct.buyPrice,
          color: this.selectedProduct.color,
          count: payload.count + "",
          day: this.today,
          description: this.selectedProduct.description,
          discount: this.selectedProduct.discount,
          expireDate: this.selectedProduct.expireDate,
          id: orderRef.id,
          images: this.selectedProduct.images,
          itemCode: this.selectedProduct.itemCode,
          itemName: this.selectedProduct.itemName,
          month: this.thisMonth,
          productId: this.selectedProduct.id,
          rating: this.selectedProduct.rating,
          relation: payload.relation + "",
          relationNumber: payload.relationNumber + "",
          kitchenType: this.selectedProduct?.kitchenType || "All",
          printCount: "0",
          sellPrice: payload.sellPrice,
          size: this.selectedProduct.size,
          stock: this.selectedProduct.stock,
          tableId: this.selectedTable.id,
          time: timestamp,
          type: this.selectedProduct.type,
          weight: this.selectedProduct.weight,
          year: this.thisYear,
        });
      }
    },
    confirmMenuItem(payload) {
      console.log(payload);
      this.checkProduct(payload);
      this.changeTableStatus();
    },
    changeTableStatus() {
      if (
        this.selectedTable.categoryType === "Free" ||
        this.selectedTable.categoryType === ""
      ) {
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .update({
            categoryType: "Service",
          });
      }
    },
    changeTableStatusFree() {
      //alert(this.selectedTable.categoryType)
      if (
        this.selectedTable.categoryType === "Service" ||
        this.selectedTable.categoryType === ""
      ) {
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .update({
            categoryType: "Free",
          });
      }
    },
    //selected products
    async selectForCart(product) {
      this.selectedProduct = { printCount: "0", ...product };
      await db
        .collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("products")
        .doc(this.selectedProduct.id)
        .collection("relation")
        .onSnapshot((snapshot) => {
          this.selectedRelations = [];
          snapshot.forEach((doc) => {
            this.selectedRelations.push(doc.data());
          });
          if (this.selectedRelations.length > 0) {
            console.log("relations", this.selectedRelations);
            this.showDialog = true;
          } else {
            if (product.stock !== "") {
              let totalCount = 0;
              if (
                this.selectedSellProduct.some(
                  (selected) => selected.productId === product.id
                )
              ) {
                this.selectedSellProduct.forEach((prod) => {
                  if (prod.productId === product.id) {
                    totalCount +=
                      parseInt(prod.count) * parseInt(prod.relationNumber);
                    if (totalCount <= parseInt(product.stock)) {
                      this.checkProduct({
                        count: "1",
                        relation: this.relation,
                        relationNumber: this.relationNumber,
                        sellPrice: this.selectedProduct.sellPrice,
                      });
                      this.changeTableStatus();
                    } else {
                      this.infoModalActive = true;
                      this.modalMessage = "Out of Stock!";
                    }
                  }
                });
              } else {
                this.checkProduct({
                  count: "1",
                  relation: this.relation,
                  relationNumber: this.relationNumber,
                  sellPrice: this.selectedProduct.sellPrice,
                });
                this.changeTableStatus();
              }
            } else {
              console.log("stock not exist");
              this.checkProduct({
                count: "1",
                relation: this.relation,
                relationNumber: this.relationNumber,
                sellPrice: this.selectedProduct.sellPrice,
              });
              this.changeTableStatus();
            }
          }
        });
    },

    removeFromCart(order) {
      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("tables")
        .doc(this.selectedTable.id)
        .collection("orders")
        .doc(order.id)
        .delete();
    },
    //method for input change
    countChangeInput(order) {
      if (order.stock !== "") {
        if (order.relationNumber === "1") {
          db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection("tables")
            .doc(this.selectedTable.id)
            .collection("orders")
            .doc(order.id)
            .get()
            .then((snapshot) => {
              if (parseInt(snapshot.data().stock) >= order.count) {
                db.collection(this.owner.city)
                  .doc(this.owner.shopId)
                  .collection("tables")
                  .doc(this.selectedTable.id)
                  .collection("orders")
                  .doc(order.id)
                  .update({
                    count: order.count + "",
                  });
              } else {
                this.infoModalActive = true;
                this.modalMessage = "Out of stock!";
              }
            });
        } else {
          let relationCount =
            parseInt(order.count) * parseInt(order.relationNumber);
          db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection("tables")
            .doc(this.selectedTable.id)
            .collection("orders")
            .doc(order.id)
            .get()
            .then((snapshot) => {
              if (parseInt(snapshot.data().stock) >= relationCount) {
                db.collection(this.owner.city)
                  .doc(this.owner.shopId)
                  .collection("tables")
                  .doc(this.selectedTable.id)
                  .collection("orders")
                  .doc(order.id)
                  .update({
                    count: order.count + "",
                  });
              } else {
                this.infoModalActive = true;
                this.modalMessage = "Out of stock!";
              }
            });
        }
      } else {
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .collection("orders")
          .doc(order.id)
          .update({
            count: order.count + "",
          });
      }
    },

    increaseCount(order) {
      let a = parseInt(order.count);
      let totalCount = 0;
      a++;
      if (order.stock === "") {
        db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection("tables")
          .doc(this.selectedTable.id)
          .collection("orders")
          .doc(order.id)
          .update({
            count: a + "",
          });
      } else {
        let relationCount = a * parseInt(order.relationNumber);
        if (
          this.selectedSellProduct.some(
            (selected) => selected.productId === order.productId
          )
        ) {
          this.selectedSellProduct.forEach((prod, i) => {
            if (prod.productId === order.productId) {
              totalCount +=
                parseInt(prod.count) * parseInt(prod.relationNumber);
            }
            const index = i + 1;
            if (this.selectedSellProduct.length === index) {
              if (totalCount <= relationCount) {
                db.collection(this.owner.city)
                  .doc(this.owner.shopId)
                  .collection("tables")
                  .doc(this.selectedTable.id)
                  .collection("orders")
                  .doc(order.id)
                  .update({
                    count: a + "",
                  });
              } else {
                this.infoModalActive = true;
                this.modalMessage = "Out of stock";
              }
            }
          });
        } else {
          db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection("tables")
            .doc(this.selectedTable.id)
            .collection("orders")
            .doc(order.productId)
            .get()
            .then((snapshot) => {
              if (relationCount <= snapshot.data().stock) {
                db.collection(this.owner.city)
                  .doc(this.owner.shopId)
                  .collection("tables")
                  .doc(this.selectedTable.id)
                  .collection("orders")
                  .doc(order.id)
                  .update({
                    count: a + "",
                  });
              } else {
                this.infoModalActive = true;
                this.modalMessage = "Out of stock";
              }
            });
        }
      }
    },

    decreaseCount(order) {
      if (order.count === order.printCount) {
        this.infoModalActive = true;
        this.modalMessage =
          "the current quantity is already Printed! you can only click remove button to clear the selected item.";
      } else {
        let a = parseInt(order.count);
        if (a > 1) {
          a--;
          db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection("tables")
            .doc(this.selectedTable.id)
            .collection("orders")
            .doc(order.id)
            .update({
              count: a + "",
            });
        } else {
          db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection("tables")
            .doc(this.selectedTable.id)
            .collection("orders")
            .doc(order.id)
            .delete();
        }
      }

      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("tables")
        .doc(this.selectedTable.id)
        .collection("orders")
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            this.changeTableStatusFree();
          }
        });
    },

    submitSale() {
      console.log("submit sale");
      this.checkoutItem.casherCode = this.cashierAcc.accountCode;
      let padNumber = this.sellItems.length + 1;
      this.checkoutItem.vouncherId = this.generateVoucherId(padNumber);
      this.selectedSellProduct.forEach((order) => {
        this.checkoutItem.nameList.push(order.itemName);
        this.checkoutItem.sellPriceList.push(order.sellPrice.toString());
        this.checkoutItem.buyPriceList.push(order.buyPrice.toString());
        this.checkoutItem.itemCodeList.push(order.itemCode);
        this.checkoutItem.productUnitList.push(order.relation);
        this.checkoutItem.productIdList.push(order.productId);
        this.checkoutItem.countList.push(order.count.toString());
        this.totalPrice += parseInt(order.sellPrice) * parseInt(order.count);
        this.costPerItem.push(
          parseInt(order.sellPrice) * parseInt(order.count)
        );
        this.checkoutItem.backNumberList.push(order.relationNumber);
        this.pcsList.push(parseInt(order.count));
      });

      let nextTotal = 0;
      if (this.shopInfo.tax !== "0") {
        this.checkoutItem.tax = (
          this.totalPrice *
          (parseInt(this.shopInfo.tax) / 100)
        ).toFixed(0);
        nextTotal = parseInt(this.totalPrice) + parseInt(this.checkoutItem.tax);
        this.checkoutItem.totalPrice = nextTotal.toString();
      } else {
        this.checkoutItem.totalPrice = this.totalPrice.toString();
      }

      this.showCart = true;
      this.$store.commit(
        "SET_SELECTED_VOUCHER_PRODUCT",
        this.selectedSellProduct
      );
      this.$store.commit("SET_VOUCHER", this.checkoutItem);
      console.log(this.checkoutItem);
      this.$router.push({ name: "RestaurantVoucher" });
    },

    generateVoucherId(padNumber) {
      const date = new Date();
      let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
      let twoDigitMonth = new Intl.DateTimeFormat("en", {
        month: "2-digit",
      }).format(date);
      let twoDigitYear = date.getFullYear().toString().slice(-2);
      let vId = "";
      vId =
        this.shopInfo.shopCode +
        this.cashierAcc.accountCode +
        twoDigitYear +
        twoDigitMonth +
        day +
        padNumber.toString().padStart(5, "0");
      return vId;
    },

    //Selecting search item

    clearCart() {
      this.totalPrice = 0;
      this.newDiscount = 0;
      this.focusInput();
    },

    confirm() {
      this.confirmActive = !this.confirmActive;
      db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection("promotionCode")
        .doc(this.deleteId)
        .delete()
        .then(() => {
          this.modalMessage = "Deleted";
          this.infoModalActive = true;
        });
    },

    cancel() {
      this.confirmActive = !this.confirmActive;
      this.focusInput();
    },

    closeModal() {
      this.infoModalActive = !this.infoModalActive;
      this.focusInput();
    },

    closeDialog() {
      this.focusInput();
      this.showDialog = !this.showDialog;
    },

    getRandomStr() {
      const chars = "1234567890";
      let result = "";
      for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
  },
};
</script>

<style scoped>
tr,
td,
th {
  color: var(--text-color) !important;
  background-color: var(--white) !important;
}
.table-bottom {
  background-color: var(--light);
}
.table-bottom td {
  border-radius: 0px;
  border: none !important;
}

.table-voucher thead th,
.table-voucher tbody td {
  border: 2px solid var(--primary) !important;
}

.product-card {
  height: 210px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  border-radius: var(--border-radius);
  background-color: var(--white);
}
.product-card:hover {
  background-color: var(--side-bar-bg) !important;
}

.product-box {
  position: sticky;
  padding-bottom: 20px;
  z-index: 1000;
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.category-list:hover {
  background-color: var(--primary);
}
.modal-body-box {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.voucher-box {
  height: 80vh;
  border-radius: var(--border-radius);
  overflow-x: hidden;
  overflow-y: scroll;
}
.active-btn {
  background-color: var(--btnLight);
}
.selected-box {
  height: inherit;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
}

.content-box {
  top: 100px;
}
.customer-box {
  height: 79vh;
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
  border-radius: var(--border-radius) !important;
}
.discount-box {
  color: var(--text-color) !important;
  background-color: var(--background-color) !important;
  border-radius: var(--border-radius) !important;
}
.category-box {
  padding: 0;
  z-index: 1000;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  color: var(--text-color);
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  height: inherit;
  align-items: center;
}

.dropdown-item {
  cursor: pointer;
}

.category-box ul {
  padding: 5px;
  height: inherit;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}
.category-list {
  cursor: pointer;
  border: none !important;
  border-bottom: 1px solid;
  height: inherit;
  min-width: 60px;
  text-align: center;
  display: inline;
  padding: 5px;
  margin-top: 4px;
  margin-left: 5px;
  border-radius: var(--border-radius);
  background-color: var(--btnLight);
  transition: 0.3s;
}
.category-list-active {
  background-color: var(--softPrimary) !important;
}

.voucher-list-solid {
  border-top: 2px solid black;
}
.voucher-list-top {
  border-top: 2px dotted black;
}
.voucher-list-bottom {
  border-bottom: 2px dotted black;
}
.btn-group {
  width: 100%;
}
.check-btn {
  background-color: var(--secondary);
  border: none !important;
  width: 80px !important;
}
.btn-group .btn {
  border: 1px solid var(--primary);
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0px !important;
}
.btn-check {
  border: none !important;
  border-radius: var(--border-radius) !important;
}

thead,
tbody {
  border: none !important;
  color: var(--dark);
}

.modal-header {
  padding: 2px !important;
  margin: 3px !important ;
}
.modal-body {
  padding: 5px !important;
}
@media only screen and (max-width: 1030px) {
}

.alert-warning {
  border-radius: var(--border-radius) !important;
}
.customer-card {
  background-color: var(--light) !important;
  transition: 0.3s;
  cursor: pointer;
}
.customer-card:hover {
  background-color: var(--softPrimary) !important;
}
.activeCustomer {
  background-color: var(--softPrimary) !important;
}
.voucher-modal {
  height: 70vh;
  overflow-y: scroll;
}
@media only screen and (max-width: 800px) {
  .category-box {
    width: 30%;
  }
  .selected-box {
    height: 70vh;
  }
}
.voucher-modal {
  height: 80vh;
}

@media only screen and (max-width: 500px) {
  .voucher-modal {
    height: inherit !important;
  }
  .voucher-box {
    height: inherit !important;
  }
  .modal-body-box {
    height: inherit !important;
  }

  .modal-body-box {
    height: 75vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .category-box {
    width: 94%;
  }
}
</style>
