<template>
  <div v-if="currentOrder">
    <Loading v-if="loadingActive"/>
    <InfoDialog v-if="infoModalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
    <div class="modal  fade" id="exampleModal" style="z-index: 10005" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" >
        <div class="modal-content bg-light " style="border-radius: var(--border-radius); border: none">
          <div class="modal-header align-items-center">
            <!-- <h5 class="modal-title text-black" id="exampleModalLabel"> <i class="fas fa-shopping-cart"></i> Invoice </h5>-->
           <div class="d-flex">
             <button class="btn" style="background-color: var(--btnLight);" @click="printThis"><i class="fas fa-save"></i></button>
             <button class="btn ms-1" style="background-color: var(--btnLight);" @click="printVoucher"><i class="fas fa-print"></i></button>
           </div>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="w-75 mx-auto p-3" id="printcontent" style="font-size: 14px; font-weight: bold;" ref="printcontent">
              <div class="text-center">
                <div class="d-flex mb-1 align-items-center">
                  <img :src="owner.profileUrl" width="100" class="rounded"/>
                  <span class="fs-5 fw-bold text-start ms-2">{{owner.name}}</span>
                </div>
                <p>{{owner.address}}</p>
                <p>Ph: {{owner.phone}}</p>
                <div class="text-start fw-bold d-flex flex-column">
                  <span class="mb-1">Voucher No: {{sellConfirmedOrder.vouncherId}}</span>
                  <span class="mb-1">Date: {{sellConfirmedOrder.dateTime}}</span>
                  <span class="mb-1" v-if="showName=== 'true'">Customer Name: {{sellConfirmedOrder.customerName}}</span>
                  <span class="mb-1" v-if="showPhone === 'true'">Customer Phone:{{sellConfirmedOrder.customerPhone}}</span>

                </div>
                <div class="d-flex py-1 fw-bold mb-3 justify-content-between" style="border-bottom: 2px dotted black;">
                  <span>Items</span>
                  <span>Price</span>
                </div>
                <div class="voucher-list d-flex text-start align-content-start justify-content-between" v-for="(name,index) in sellConfirmedOrder.nameList" :key="index">
                  <p class="w-50 text-start">{{ name }}({{ sellConfirmedOrder.sellPriceList[index]}} x {{ sellConfirmedOrder.countList[index]}})</p>
                  <p class="w-50 text-end" >{{ costPerItem[index] }} Ks</p>
                </div>
                <div class="d-flex justify-content-between fw-bold mb-1" style="border-top: 2px dotted black;"  v-if="sellConfirmedOrder.discount==='0'" >
                  <span>Total</span>
                  <span>{{totalPrice}} Ks</span>
                </div>

               <div style="border-top: 2px dotted black;"   v-else>
                 <div class="d-flex justify-content-between fw-bold mb-1" >
                   <span>SubTotal</span>
                   <span>{{totalPrice}} Ks</span>
                 </div>
                 <div class="d-flex justify-content-between fw-bold mb-1">
                   <span>Discount</span>
                   <span>{{sellConfirmedOrder.discount }} Ks</span>
                 </div>
                 <div class="d-flex justify-content-between fw-bold align-items-center pb-2 mb-2" style="border-top: 2px dotted black;"  v-if="sellConfirmedOrder.discount>0">
                   <span>Total</span>
                   <span>{{ sellConfirmedOrder.totalPrice}} Ks</span>
                 </div>
               </div>
                <p class="fw-bold">Thank You!</p>
                <img src="../../assets/flash/2.png" width="35" height="35" alt="">
                <p class="mt-1">Power by Light Idea Software Development</p>
              </div>
            </div>
            <!--              <button class="btn btn-primary mx-auto w-50 mt-5">Print</button>-->
          </div>
          <div class="modal-footer">
            <!--                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
            <button class="btn btn-primary mx-auto my-2 w-50" @click="sellProducts" v-if="this.currentOrder.orderStatus==='confirmed'" data-bs-dismiss="modal">Sell Now</button>

          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="background-color: var(--background-color); color: var(--text-color) !important;">
      <div class="row ">
        <div class="col-lg-2 p-0">
          <AdminSidebar/>
        </div>
        <div class="col-12 col-lg-10 mt-5">
         <div class="row">
           <div class="col-12 col-lg-8 p-0">
             <div class="d-flex px-0 mt-2 align-items-center rounded">
               <button class="btn btn--white" style="color: var(--text-color);" @click="$router.back()"><i class="fas fa-chevron-left"> </i></button>
               <span class="fs-3">Order Details</span>
             </div>

             <div class="px-2 m-1 d-flex flex-column flex-lg-row justify-content-lg-between align-items-center" style="background-color: var(--side-bar-bg); border-radius: var(--border-radius);">
               <div class="rounded p-2 ">
                 <div v-if="currentOrder.orderStatus==='pending'" class="d-flex align-items-center">
                   <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                     <span class=" "><i class="fas fa-circle-check "></i></span>
                     <span>Pending</span>
                   </div>
                   <div class="loading-bar" style="background-color: var(--primary);">
                   </div>
                   <div class="d-flex flex-column text-center status-box" >
                     <span><i class="fas fa-spinner "></i></span>
                     <span>Confirmed</span>
                   </div>
                   <div class="loading-bar">
                   </div>
                   <div class="d-flex flex-column text-center status-box">
                     <span><i class="fas fa-spinner "></i></span>
                     <span>Paid</span>
                   </div>
                 </div>
                 <div v-else-if="currentOrder.orderStatus==='confirmed'" class="d-flex align-items-center">
                   <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                     <span class=" "><i class="fas fa-circle-check "></i></span>
                     <span>Pending</span>
                   </div>
                   <div class="loading-bar" style="background-color: var(--primary);">
                   </div>
                   <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                     <span><i class="fas fa-circle-check "></i></span>
                     <span>Confirmed</span>
                   </div>
                   <div class="loading-bar" style="background-color: var(--primary);">
                   </div>
                   <div class="d-flex flex-column text-center status-box">
                     <span><i class="fas fa-spinner "></i></span>
                     <span>Paid</span>
                   </div>
                 </div>
                 <div v-else-if="currentOrder.orderStatus==='paid'" class="d-flex align-items-center">
                   <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                     <span><i class="fas fa-circle-check "></i></span>
                     <span>Pending</span>
                   </div>
                   <div class="loading-bar" style="background-color: var(--primary);">
                   </div>
                   <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                     <span><i class="fas fa-circle-check "></i></span>
                     <span>Confirmed</span>
                   </div>
                   <div class="loading-bar" style="background-color: var(--primary);">
                   </div>
                   <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);" >
                     <span><i class="fas fa-circle-check "></i></span>
                     <span>Paid</span>
                   </div>
                 </div>
                 <div v-else>
                   <span class="badge rounded-pill bg-danger">{{currentOrder.orderStatus}}</span>
                 </div>
               </div>
               <div class="">
                 Date: {{currentOrder.dateTime}}
               </div>
             </div>
             <div class="card p-0">
               <span class="float-end ps-2 fs-5" style="color: var(--text-color-light);">Order ID: {{order_id}}</span>

               <div class="card-body p-0 order-control-box" >
                 <div class="d-flex">
                   <ul>
                     <li class="fw-bold">#</li>
                     <li v-for="(count,index) in currentOrder.countList" :key="index" >
                       <span>{{index+1}}</span>
                       <span class="d-none">{{count}}</span>
                     </li>
                   </ul>
                   <ul>
                     <li class="fw-bold">Photo</li>
                     <li v-for="(image,index) in currentOrder.images" :key="index" >
                       <img :src="image" class="rounded border border-light border-3 shadow-sm" style="width:50px; height:50px; object-fit: cover;" alt="">
                     </li>
                   </ul>

                   <ul>
                     <li class="fw-bold">Name</li>
                     <li v-for="(item,index) in currentOrder.nameList" :key="index">
                       {{item}}
                     </li>
                   </ul>
                   <ul>
                     <li class="fw-bold">In Stock</li>
                     <li  v-for="(product,index) in products" :key="index">
                       {{product.stock}}
                     </li>
                   </ul>

                   <ul>
                     <li class="fw-bold">Amount</li>
                     <li v-for="(price,index) in currentOrder.sellPriceList" :key="index">
                       <span>{{price.toLocaleString()}} x {{currentOrder.countList[index]}}</span>
                     </li>
                   </ul>
                   <ul>
                     <li class="fw-bold">Cost</li>
                     <li v-for="(price,index) in eachPrice" :key="index">
                       <span>{{price.toLocaleString()}}</span>
                     </li>
                   </ul>
                   <ul>
                     <li class="fw-bold">Available</li>
                     <li v-for="(id,index) in currentOrder.productIdList" :key="index">
                       <div class="form-check form-switch">
                         <input class="form-check-input" :disabled="currentOrder.orderStatus==='confirmed' || currentOrder.orderStatus==='paid'" type="checkbox" :checked="this.currentOrder.acceptedList[index]==='available'" :id="id" @change="acceptItem($event,index,currentOrder.countList[index])">
                       </div>
                     </li>
                   </ul>
                 </div>

               </div>
             </div>
             <div class="d-flex p-1 justify-content-between align-items-center my-2 px-5" style="background-color: var(--side-bar-bg); border-radius: var(--border-radius);">
               <span class="fs-5">Promotion Discount</span>
               <span class="fs-5">{{currentOrder.discount.toLocaleString()}} Ks</span>
             </div>
             <div class="d-flex p-1 justify-content-between align-items-center my-2 px-5" style="background-color: var(--side-bar-bg); border-radius: var(--border-radius);">
               <span class="fs-5">Total</span>
               <span class="fs-5">{{currentOrder.totalPrice.toLocaleString()}} Ks</span>
             </div>
             <div class="d-flex justify-content-between p-1">

               <div class="d-flex" v-if="currentOrder.orderStatus!=='paid'">
                 <!--              <button class="btn btn-sm mx-1 btn-primary" @click="askConfirm">Send Confirmation</button>-->
                 <button class="btn btn-sm me-2 btn-success" @click="confirmOrder" v-if="currentOrder.orderStatus==='pending'">Confirm</button>
                 <button class="btn btn-sm btn-danger" @click="cancelOrder" v-if="currentOrder.orderStatus=='pending'">Cancel</button>
               </div>
               <button type="button" v-if="currentOrder.orderStatus==='confirmed' " class="btn btn-sm text-light" style="background-color: var(--secondary);" @click="generateConfirmedOrder" data-bs-toggle="modal" data-bs-target="#exampleModal">
                 Generate Invoice
               </button>
               <button type="button" v-if="currentOrder.orderStatus==='paid'" class="btn btn-sm text-light" style="background-color: var(--secondary);" @click="generateConfirmedOrder" data-bs-toggle="modal" data-bs-target="#exampleModal">
                 Check Sell Record
               </button>

             </div>
           </div>
           <div class="col-12 col-lg-4 p-0">
             <div class="card">
               <span class="fs-4 ps-2">Customer Details</span>

               <div class="card-body table-responsive">
                 <table class="table" >
                   <tbody>
                   <tr>
                     <td>Name</td>
                     <td>:{{customer.name}}</td>
                   </tr>
                   <tr>
                     <td>Phone 1</td>
                     <td><a :href="'tel:+959'+customer.phone">:09 {{customer.phone}}</a></td>
                   </tr>
<!--                   <tr>
                     <td>Phone 2</td>
                     <td><a :href="'tel:+959'+customer.otherPhone">:09 {{customer.otherPhone}}</a></td>
                   </tr>-->
                   <tr>
                     <td>Name</td>
                     <td class="text-wrap">:{{customer.email}}</td>
                   </tr>
                   <tr>
                     <td>Address</td>
                     <td>:{{customer.address}}</td>
                   </tr>
                   </tbody>

                 </table>

               </div>
             </div>
             <div class="card">
               <div class="card-body">
                 <h3>Transaction Record</h3>
                 <p>Payment Type: {{currentOrder.paymentType}}</p>

                 <img :src="currentOrder.transactionImg" v-if="currentOrder.transactionImg!=='default'" class="shadow-sm" width="100" height="150" alt="">
               </div>

             </div>
             <div class="card p-2">
               <span class="fs-3">Delivery Methods</span>
               <div class="card-body">
                 <span class="text-primary fs-5 fw-bold">User Selected Method</span>
                 <div class="d-flex flex-column">
                   <span>Name: {{currentOrder.deliveryServiceName}}</span>
                   <span>City To Deliver: {{currentOrder.cityToDeliver}}</span>
                   <span>Cost: {{currentOrder.deliveryFeeRange}} Ks</span>
                 </div>
               </div>
               <div class="card-body d-flex flex-column" v-if="selectedDeliveryService">
                 <div class="d-flex flex-column" >
                   <span class="text-primary fs-5 fw-bold">Delivery Information</span>
                   <span>Name: {{selectedDeliveryService.name}}</span>
                   <span>Phone: {{selectedDeliveryService.phone}}</span>
                   <span>Available Cities: <span class="badge bg-secondary me-1 d-inline" v-for="(city,index) in selectedDeliveryService.availableCities" :key="index">{{city}}</span></span>
                   <span>Inside City: {{selectedDeliveryService.feeRange}} MMK</span>
                   <span>Outside City: {{selectedDeliveryService.externalFeeRange}} MMK</span>
                 </div>
               </div>
             </div>

             <div class="card p-2">
               <span class="fs-3">History</span>
               <div class="p-2 mt-2" style="background-color: var(--light); border-radius: var(--border-radius);" v-for="(record,index) in recordHistory" :key="index">
                 <div class="d-flex justify-content-between">
                   <div>
                     <span class="badge rounded-pill bg-warning" style="min-width: 100px;font-size: 12px;" v-if="record.orderStatus === 'pending'">Order Date</span>
                     <span class="badge rounded-pill bg-primary" style="min-width: 100px;font-size: 12px;" v-else-if="record.orderStatus === 'confirmed'">Confirmed Date</span>
                     <span class="badge rounded-pill bg-success" style="min-width: 100px;font-size: 12px;" v-else-if="record.orderStatus === 'paid'">Paid Date</span>
                     <span class="badge rounded-pill bg-danger" style="min-width: 100px;font-size: 12px;" v-else>Cancelled Date</span>
                   </div>
                   <span>{{record.statusDateTime}}</span>
                 </div>
               </div>
             </div>
           </div>

         </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import InfoDialog from "@/components/InfoDialog";
import Loading from "@/components/Loading";
import db, {timestamp} from "@/config/FirebaseInit";
import AdminSidebar from "@/components/AdminSidebar";
import html2canvas from "html2canvas";
export default {
  components:{InfoDialog, Loading, AdminSidebar},
  props:['order_id'],
  data(){
    return{
      currentOrder:'',
      //hold when owner click the generate vouncher
      sellConfirmedOrder:'',
      selectedDeliveryService:'',
      casherCode:'',
      finalOrder:'',
      customer:'',
      totalPrice:0,
      eachPrice:[],
      costPerItem:[],
      acceptedItem:[],
      products:[], //with unavailable products
      filterProductStock:[], //stock only without unavailable products
      infoModalActive:false,
      loadingActive:false,
      modalMessage:'',
      sellItems:[],
      cashier:[],
      recordHistory:[],
    }
  },
  computed:{
    showName(){
      return localStorage.getItem('showCustomerName');
    },
    showPhone(){
      return localStorage.getItem('showCustomerPhone');
    },
    owner(){
      return this.$store.getters.getShopOwner;
    },
    today(){
      return this.getToday();
    },
    currentDateTime(){
      return this.$store.getters.getDateTime;
    },
    currentDay(){
      return this.$store.getters.getDay;
    },
    currentMonth(){
      return this.$store.getters.getMonth;
    },
    currentYear(){
      return this.$store.getters.getYear;
    },
    cashierAcc(){
      return this.$store.getters.getCashierAcc;
    }
  },
  created() {
    this.$store.dispatch('getCurrentShop');
  },
  async mounted(){

    this.$store.dispatch("getDateTime");
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getYear");

    /*if(localStorage.getItem('defaultCashier')){
      this.casherCode = localStorage.getItem('defaultCashier');
    }else {
      this.casherCode = 'C1';
    }*/
    this.casherCode = this.cashierAcc.accountCode;

    await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).onSnapshot( (snapshot) => {
        this.currentOrder=[];
        this.eachPrice=[];
        this.products=[];
        this.sellConfirmedOrder=[];
        this.currentOrder = snapshot.data();
        for(let i=0;i<this.currentOrder.sellPriceList.length;i++){
          const price = parseInt(this.currentOrder.sellPriceList[i]);
          const count = parseInt(this.currentOrder.countList[i]);
          const amount = price*count;
          this.eachPrice.push(amount);
        }
      db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).collection('records').orderBy('time','desc').onSnapshot(snapshot=>{
        this.recordHistory = [];
        snapshot.forEach(doc=>{
          this.recordHistory.push(doc.data());
        })
      });

      db.collection('users').doc(snapshot.data().orderUserId).onSnapshot(snapshot=>{
          this.customer = snapshot.data();
          this.currentOrder.productIdList.forEach(productId=>{
            db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(productId).onSnapshot(snapshot1 => {
              this.products.push(snapshot1.data());
            })
          })
        })
      if(snapshot.data().deliveryServiceId!==''){
        db.collection('deliveryServices').doc(snapshot.data().deliveryServiceId).onSnapshot(snapshot=>{
          this.selectedDeliveryService = snapshot.data();
        })
      }

      })
    await db.collection(this.owner.city).doc(this.owner.shopId).collection('sellProducts').where('day','==',this.today).where('casherCode','==',this.casherCode).onSnapshot(snapshot => {
      this.sellItems = [];
      snapshot.forEach(doc=>{
        this.sellItems.push(doc.data());
      })
    })
   /* await db.collection(this.owner.city).doc(this.owner.shopId).collection('casher').onSnapshot(snapshot => {
      this.cashier = [];
      snapshot.forEach(doc => {
        this.cashier.push(doc.data())
      });
    });*/
  },
  methods:{
    closeInfoModal(){
      this.infoModalActive = !this.infoModalActive;

    },
    async acceptItem(event,index, count){
      if(event.target.checked && this.products[index].stock >=count){
        this.currentOrder.acceptedList[index] = 'available';
        this.currentOrder.totalPrice = (parseInt(this.currentOrder.totalPrice) + parseInt(this.eachPrice[index])).toString()
      }else {
          this.currentOrder.acceptedList[index] = 'unavailable';
        this.currentOrder.totalPrice = (parseInt(this.currentOrder.totalPrice) - parseInt(this.eachPrice[index])).toString()

      }
    },
    async confirmOrder(){
      this.loadingActive = true;
      //console.log('accepted list',this.currentOrder.acceptedList)
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).update({
        'acceptedList':this.currentOrder.acceptedList,
        'orderStatus':'confirmed',
        'statusDay':this.currentDay,
        'statusDateTime':this.currentDateTime,
        'totalPrice':this.currentOrder.totalPrice,
      }).then(()=>{
        console.log('order confirmed');
        let recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).collection('records').doc();
        recordRef.set({
          'statusDay':this.currentDay,
          'statusDateTime':this.currentDateTime,
          'orderStatus':'confirmed',
          'id':recordRef.id,
          'time':timestamp,
        }).then(()=>{
          console.log('confirmed recorded');
          db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).update({
            'orderStatus':'confirmed',
            'acceptedList':this.currentOrder.acceptedList,
            'statusDay':this.currentDay,
            'statusDateTime':this.currentDateTime,
            'totalPrice':this.currentOrder.totalPrice,
          }).then(()=>{
            console.log('user order confirmed');
            let userRecordRef = db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).collection('records').doc();
            userRecordRef.set({
              'statusDay':this.currentDay,
              'statusDateTime':this.currentDateTime,
              'orderStatus':'confirmed',
              'id':userRecordRef.id,
              'time':timestamp,

            }).then(()=>{
              console.log('user order confirmed recorded')
              this.loadingActive = false;
            });
          });
        });
      })
    },

    async cancelOrder(){
      this.loadingActive = true;
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).update({
        'acceptedList':this.currentOrder.acceptedList,
        'orderStatus':'cancelled',
        'statusDay':this.currentDay,
        'statusDateTime':this.currentDateTime,
      }).then(()=>{
        console.log('order cancelled');
        let recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).collection('records').doc();
        recordRef.set({
          'statusDay':this.currentDay,
          'statusDateTime':this.currentDateTime,
          'orderStatus':'cancelled',
          'id':recordRef.id,
          'time':timestamp,

        }).then(()=>{
          console.log('cancelled recorded');
          db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).update({
            'orderStatus':'cancelled',
            'acceptedList':this.currentOrder.acceptedList,
            'statusDay':this.currentDay,
            'statusDateTime':this.currentDateTime,
          }).then(()=>{
            console.log('user order cancelled');
            let userRecordRef = db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).collection('records').doc();
            userRecordRef.set({
              'statusDay':this.currentDay,
              'statusDateTime':this.currentDateTime,
              'orderStatus':'cancelled',
              'id':userRecordRef.id,
              'time':timestamp,

            }).then(()=>{
              console.log('user order cancelled recorded')
              this.loadingActive = false;
            });
          });
        });
      })
    },
    getRandomStr(){
      const chars = "1234567890";
      let result = '';
      for ( let i = 0; i < 8; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    printVoucher() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printcontent').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },
    async generateConfirmedOrder(){
      this.costPerItem=[];
      this.filterProductStock=[];
      this.totalPrice = 0;
      const confirmedOrder={
        countList:[],
        nameList:[],
        sellPriceList:[],
        buyPriceList:[],
        productIdList:[],
        itemCodeList:[],
        sellType:'onlineSell',
        day:'',
        time:'',
        month:'',
        year:'',
        statusDay: '',
        statusDateTime: '',
        dateTime:'',
        casherCode:this.casherCode,
        discount:this.currentOrder.discount,
        promoCode:"0",
        tax:"0",
        totalPrice:'',
        vouncherId:'',
        customerName:'',
        customerPhone:'',
        customerId:'',
      };


      confirmedOrder.customerName = this.customer.name;
      if(this.customer.phone!==''){
        confirmedOrder.customerPhone = '09'+this.customer.phone;
      }
      confirmedOrder.customerId = this.customer.id;
      confirmedOrder.month=this.currentMonth;
      confirmedOrder.day =this.currentDay;
      confirmedOrder.year = this.currentYear;
      confirmedOrder.dateTime = this.currentDateTime;
      confirmedOrder.statusDay = this.currentDay;
      confirmedOrder.statusDateTime = this.currentDateTime;

      let padNumber = this.sellItems.length+1;
      confirmedOrder.vouncherId = this.generateVoucherId(padNumber);

      for(let index=0; index <= this.eachPrice.length; index++){
        if(this.currentOrder.acceptedList[index] === "available" ){
          confirmedOrder.nameList.push(this.currentOrder.nameList[index]);
          confirmedOrder.sellPriceList.push(this.currentOrder.sellPriceList[index]);
          confirmedOrder.buyPriceList.push(this.currentOrder.buyPriceList[index]);
          confirmedOrder.countList.push(this.currentOrder.countList[index]);
          confirmedOrder.itemCodeList.push(this.currentOrder.itemCodeList[index]);
          confirmedOrder.productIdList.push(this.currentOrder.productIdList[index]);
          this.costPerItem.push(this.eachPrice[index]);
          this.filterProductStock.push(this.products[index].stock);
        }
        if(index == (this.eachPrice.length-1)){
          //let amount = 0;
          this.costPerItem.forEach(price=>{
            this.totalPrice += parseInt(price);
          })
          if(confirmedOrder.discount>0){
            console.log('yes')
            confirmedOrder.totalPrice = (this.totalPrice - parseInt(confirmedOrder.discount)).toString();
          }else {
            console.log('no')
            confirmedOrder.totalPrice = (this.totalPrice).toString();
          }
          this.sellConfirmedOrder = confirmedOrder;
          console.log(this.sellConfirmedOrder)
        }
     }

    },
    getToday(){
      const date = new Date();
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return day+"-"+month+"-"+year;
    },
    generateVoucherId(padNumber){
      const date = new Date();
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      let twoDigitMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let twoDigitYear = date.getFullYear().toString().slice(-2);
      let vId='';
      vId = this.owner.shopCode+this.casherCode+twoDigitYear+twoDigitMonth+day+padNumber.toString().padStart(5,'0');
      return vId;
    },
    closeModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    async sellProducts(){
      //console.log(this.filterProductStock)
      this.loadingActive=true;
      this.sellConfirmedOrder.time = timestamp;
      //console.log('sell confirm', this.sellConfirmedOrder);
      let newStock = 0;
      this.sellProductDB = await db.collection(this.owner.city).doc(this.owner.shopId).collection('sellProducts').doc(this.currentOrder.id);
      await this.sellProductDB.set(this.sellConfirmedOrder).then(()=>{
        for(let i = 0 ;i < this.filterProductStock.length;i++){
          newStock = this.filterProductStock[i] - this.sellConfirmedOrder.countList[i] ;
          console.log(newStock);
           db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(this.sellConfirmedOrder.productIdList[i]).update({
            stock:newStock.toString()
          }).then(()=>{
            const recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(this.sellConfirmedOrder.productIdList[i]).collection('records').doc();
            recordRef.set({
              'id':this.sellConfirmedOrder.productIdList[i],
              'recordId':recordRef.id,
              'itemCode':this.sellConfirmedOrder.itemCodeList[i],
              'barcode':this.products[i].barcode,
              'itemName':this.sellConfirmedOrder.nameList[i],
              'buyPrice':this.sellConfirmedOrder.buyPriceList[i],
              'sellPrice':this.sellConfirmedOrder.sellPriceList[i],
              'stock':newStock.toString(),
              'description':this.products[i].description,
              'type':this.products[i].type,
              'color':this.products[i].color,
              'size':this.products[i].size,
              'weight':this.products[i].weight,
              'time':timestamp,
              'day':this.currentDay,
              'month':this.currentMonth,
              'year':this.currentYear,
              'dateTime':this.currentDateTime,
              'rating':this.products[i].rating,
              'discount':this.products[i].discount,
              'note':'default',
              'process':'onlineSell',
              'count':'-'+this.sellConfirmedOrder.countList[i]
            });
          })
          if(i==(this.filterProductStock.length-1)){
            //this.loadingActive=false;
            // console.log(i, (this.filterProductStock.length-1))
            db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).update({
              'acceptedList':this.currentOrder.acceptedList,
              'orderStatus':'paid',
              'statusDay':this.currentDay,
              'statusDateTime':this.currentDateTime,
            }).then(()=>{
              console.log('order paid');
              let recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).collection('records').doc();
              recordRef.set({
                'statusDay':this.currentDay,
                'statusDateTime':this.currentDateTime,
                'orderStatus':'paid',
                'id':recordRef.id,
                'time':timestamp,

              }).then(()=>{
                console.log('paid recorded');
                db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).update({
                  'orderStatus':'paid',
                  'acceptedList':this.currentOrder.acceptedList,
                  'statusDay':this.currentDay,
                  'statusDateTime':this.currentDateTime,
                }).then(()=>{
                  console.log('user order paid');
                  let userRecordRef = db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).collection('records').doc();
                  userRecordRef.set({
                    'statusDay':this.currentDay,
                    'statusDateTime':this.currentDateTime,
                    'orderStatus':'paid',
                    'id':userRecordRef.id,
                    'time':timestamp,

                  }).then(()=>{
                    console.log('user order paid recorded')
                    this.loadingActive = false;
                    this.infoModalActive = true;
                    this.modalMessage = "success"
                  });
                });
              });
            })



            /*await this.sellProductDB.set({'id':this.sellProductDB.id,...this.sellConfirmedOrder}).then(()=>{
              db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(this.order_id).update({
                'orderStatus':'paid'
              }).then(()=>{
                db.collection('users').doc(this.customer.id).collection('orders').doc(this.order_id).update({
                  'orderStatus':'paid'
                }).then(()=>{

                  this.loadingActive = false;
                  this.infoModalActive = true;
                  this.modalMessage = "success"
                  //this.$router.push({name:'OrderList'});
                });
                });
            });*/
          }

        }
      })


    },
    async printThis() {
      console.log("printing..");
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute('download', `${this.sellConfirmedOrder.vouncherId}.png`);
      link.setAttribute(
          "href",
          printCanvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
      );
      link.click();

      console.log("done");
    },
  }

}
</script>

<style scoped>
.card{
  background-color: var(--side-bar-bg) !important;
  border-radius: var(--border-radius) !important;

}
td{
  color: var(--text-color) !important;
}
.card-body{
  color: var(--text-color) !important;
  background-color: var(--side-bar-bg) !important;
}
.card ul{
  text-align: start;
  width: inherit;
  padding: 0;
  list-style-type: none;
  background-color: var(--light);
  margin: 2px;
  border-radius: var(--border-radius);
}

.card li{
  height: 60px;
  min-width: 30px;
  max-width: 200px;
  overflow-y: scroll;
  text-align: start;
  margin: 3px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding:10px;
  border-bottom: 2px solid var(--white);
}
.order-control-box{
  height: inherit; overflow-x: scroll; overflow-y: scroll;
}

.loading-bar{
  width: 30px; height: 3px; background-color: var(--softPrimary);
}
.status-box{
  font-size: 10px;
  background-color: var(--light);
  padding: 2px;
  border-radius: var(--border-radius);
  min-width: 45px;
}
.status-icon{
  background-color: var(--softPrimary);
  padding-right: 3px;
  width: 20px;
  height: 20px;
  border-radius:var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-box{
  margin-top: 20px ;
}

@media screen and (max-width: 1030px){
  .content-box{
    margin-top:50px;
  }
  .card li{
    font-size: 12px !important;
    min-width: 30px !important;
    max-width: 200px !important;
  }
  .order-control-box{
    height: 300px; overflow-x: scroll; overflow-y: scroll;
  }
}
</style>