<template>
  <div class="bg-owner">
    <Loading v-show="loadingActive"/>
    <InfoDialog v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
    <!--    <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>-->
    <div class="container-fluid overlay pt-5">
      <div class="row mt-5 mx-auto min-vh-100">
        <div class="col-12 col-md-6 col-lg-5 mx-auto">
          <div class="">
            <div class="card-overlay p-5">
              <div class="text-center">
                <h3 class="text-center text-light">Welcome to Flash Mall</h3>
                <h1 class="text-light"><i class="fa fa-user-lock"></i></h1>

                <h4 class="text-light">Shop Owner Login</h4>
                <!--<button class="btn btn-outline-dark" @click="googleSignIn"><i class="fa fa-circle"></i> Sign in with google</button>-->
                <p class="alert alert-warning" v-if="error" >
                  {{this.errorMsg}}
                </p>
              </div>
              <form class="row">
                <div class="col-12 ">
                  <div class="form-floating mb-3">
                    <input type="text" id="owner-email-login" v-model="form.email" class="form-control" required placeholder="Enter Ms Code">
                    <label for="owner-email-login" class="text-black-50">Owner Email</label>
                  </div>

                </div>
                <div class="col-12 ">
                  <div class="form-floating mb-3">
                    <input type="password" id="password" v-model="form.password" class="form-control" required placeholder="Enter Ms Code">
                    <label for="password" class="text-black-50">Password</label>
                  </div>
                </div>
                <button class="mt-3 w-50 btn text-white mx-auto" style="background-color: var(--primary);" @click.prevent="signIn">Sign in</button>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Loading from "../../components/Loading";
import db from "../../config/FirebaseInit";
import InfoDialog from "@/components/InfoDialog";
// import InfoModal from "@/components/InfoModal";
export default {
  name:'Shop Owner Login',
  components: {InfoDialog, Loading},
  data(){
    return{
      form:{
        email:"",
        password:"",
      },
      deviceId:'',
      loadingActive:false,
      modalActive:false,
      modalMessage:"",
      error:null,
      errorMsg:"",
    }
  },
  computed:{
    user(){
      return this.$store.getters.getCurrentUser;
    },
    owner(){
      return this.$store.getters.getShopOwner;
    },
    fcmToken(){
      return this.$store.getters.getFCM;
    }
  },
  created() {
    this.deviceId = navigator.userAgent;
  },
  methods:{
    closeInfoModal(){
      this.modalActive = !this.modalActive;
      // if(this.owner)
      //   this.$router.push({name:"ShopHome"});
    },
    async signIn(){
      this.loadingActive = true;
      if(this.form.email !== "" && this.form.password !==""){
        if(this.user!==''){
          firebase.auth().signOut().then(()=>{
            sessionStorage.clear();
            window.localStorage.removeItem('vuex');
            this.$store.state.user='';
            console.log('login session cleared')
          })

        }
        if(this.admin!=='') {
          firebase.auth().signOut().then(() => {
            sessionStorage.clear();
            window.localStorage.removeItem('vuex');
            this.$store.state.admin = '';
            console.log('login session cleared')
          })
        }
        await firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
            .then(async (userCredential)=>{
              const user = userCredential.user;
              await db.collection('shopRegistration').where('emailList','array-contains',user.email).get().then((snapshot)=>{
                let userList = [];
                snapshot.forEach(doc=>{
                  userList.push(doc.data());
                })
                console.log('shopReg owner',userList[0]);
                console.log('user id', user.uid)
                let accRef =db.collection(userList[0].city).doc(userList[0].shopId).collection('account').doc(user.uid);
                accRef.get().then(accSnapshot=>{
                  console.log('account',accSnapshot.data());

                  /*console.log('cashier acc after update',accSnapshot.data());
                  this.$store.commit('SET_CASHIER_ACC',accSnapshot.data());
                  this.$store.dispatch('getCurrentShop');
                  this.loadingActive = false;
                  this.modalMessage = 'Login Success!';
                  this.modalActive = true;
                  localStorage.setItem('selectedProductSearchType','barcode');
                  localStorage.setItem('showLogoImage', true);
                  localStorage.setItem('showCustomerPhone', false);
                  localStorage.setItem('showCustomerName', false);
                  localStorage.setItem('voucherStyle', 'listStyle');
                  this.$router.push({name:"ShopHome"});*/


                  //if there is no device id or user first time login
                  if(accSnapshot.data().deviceId ===''){
                    accRef.update({
                      'loginStatus':'yes',
                      'deviceId':this.deviceId,
                      'FCM':this.fcmToken,
                    }).then(()=>{
                      accRef.get().then(snapshot=>{
                        console.log('cashier acc after update',snapshot.data());
                        this.$store.commit('SET_CASHIER_ACC',snapshot.data());
                        this.$store.dispatch('getCurrentShop');
                        this.loadingActive = false;
                        this.modalMessage = 'Login Success!';
                        this.modalActive = true;
                        localStorage.setItem('selectedProductSearchType','barcode');
                        localStorage.setItem('showLogoImage', false);
                        localStorage.setItem('showCustomerPhone', false);
                        localStorage.setItem('showCustomerName', false);
                        localStorage.setItem('voucherStyle', 'listStyle');
                        localStorage.setItem('gridView', 'false');
                        localStorage.setItem('selectedSelection',1)
                        this.$router.push({name:"ShopHome"});
                      });
                    })
                  }else {
                    //if the user did not login and the device id is same
                    if(accSnapshot.data().deviceId === this.deviceId){
                      accRef.update({
                        'FCM':this.fcmToken,
                        'loginStatus':'yes'
                      }).then(()=>{
                        accRef.get().then(snapshot=> {
                          console.log('cashier acc after update', snapshot.data());
                          this.$store.commit('SET_CASHIER_ACC', snapshot.data());
                          this.loadingActive = false;
                          this.$store.dispatch('getCurrentShop');
                          //this.$router.push({name:"ShopHome"});
                          this.modalMessage = 'Login Success!';
                          this.modalActive = true;
                          this.$router.push({name:"ShopHome"})
                        })

                      })
                    }else{
                      firebase.auth().signOut().then(()=>{
                        sessionStorage.clear();
                        window.localStorage.removeItem('vuex');
                        this.$store.state.owner='';
                        console.log('login session cleared')
                      }).then(()=>{
                        this.loadingActive = false;
                        this.modalMessage = "Sorry you already logged in other device.";
                        this.modalActive = true;
                      })
                    }
                  }
                })

                /*if(snapshot){

                  /!*accRef.update({
                    'loginStatus':'yes',
                    'deviceId':this.deviceId
                  }).then(()=>{
                    this.loadingActive = false;
                    this.$store.dispatch('getCurrentShop');
                    // this.$router.push({name:"ShopHome"});
                    this.modalMessage = 'Login Success!';
                    this.modalActive = true;
                  })*!/

                }else {
                  this.loadingActive = false;
                  //alert("There is no related shop with this email")
                }*/
              })
            }).catch((error)=>{
              this.loadingActive = false;
              this.modalActive = true;
              this.form.password = "";
              this.error= true;
              if (error.code === 'auth/email-already-in-use') {
                this.errorMsg = 'Mail already in used!';
              }
              else if(error.code === 'auth/user-not-found'){
                this.errorMsg = "You haven't register with this mail."
              }else if(error.code === 'auth/network-request-failed'){
                this.errorMsg = "No Internet connection"
              }else if(error.code === 'auth/wrong-password'){
                this.errorMsg = "Password is not matched!"
              } else{
                this.errorMsg = error.message;
              }
              this.modalMessage = this.errorMsg;
              setTimeout(()=>{
                this.error = false;
              }, 5000);
              return;
            });
        // return;
      }else {
        this.loadingActive = false;
        this.error = true;
        this.errorMsg ="Please fill email and password!";
        setTimeout(()=>{
          this.error = false;
        }, 3000);
        return;
      }
    },
    closeModal(){
      this.modalActive = !this.modalActive;
    },
  }
}
</script>

<style scoped>
.bg-owner{
  background-color: var(--primary);
  background-image: url("../../assets/flash-shop.svg");
  object-fit: cover;
  min-height: 100vh;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-overlay{
  padding:5px;
  background-color: rgba(157, 186, 252, 0.45);
  border-radius: var(--border-radius);
  backdrop-filter: blur(4px);
}
.overlay{
  background-color: rgba(75, 67, 201, 0.83);
}
input, button, .card{
  border-radius: var(--border-radius) !important;
}
.form-control{
  background-color: var(--light) !important;
}
</style>