<template>
  <div class="container-fluid" style="background-color: var(--background-color);">
    <div class="row">
      <div class="col-lg-2 p-0">
        <AdminSidebar/>
      </div>
      <div class="col-12 col-lg-10 mt-5 p-0" style="background-color: var(--background-color);">
        <div class="header-bar shadow-sm py-1">
          <div class="d-lg-flex justify-content-between align-items-center rounded mb-2">
            <div class="d-flex justify-content-between align-items-center">

              <button @click="$router.back()" class="btn align-items-center fs-5" style="color: var(--text-color);"><i class="fas fa-chevron-left"> </i> Online Orders <span class="badge bg-warning rounded-pill">{{filterOrders.length}}</span>
              </button>

            </div>
            <div class="btn-group py" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" @click="showPending" name="btnradio" id="btnradio1" autocomplete="off" :checked="selectedStatus==='pending'">
              <label class="btn check-btn btn-primary"  for="btnradio1">Pending</label>

              <input type="radio" class="btn-check" @click="showAccepted" name="btnradio" id="btnradio2" autocomplete="off" :checked="selectedStatus==='confirmed'">
              <label class="btn check-btn btn-primary" for="btnradio2">Accepted</label>

              <input type="radio" class="btn-check" @click="showPaid" name="btnradio" id="btnradio3" autocomplete="off" :checked="selectedStatus==='paid'">
              <label class="btn check-btn btn-primary" for="btnradio3">Paid</label>
              <input type="radio" class="btn-check" @click="showCancelled" name="btnradio" id="btnradio4" autocomplete="off" :checked="selectedStatus==='cancelled'">
              <label class="btn check-btn btn-primary" for="btnradio4">Cancelled</label>
            </div>
          </div>

          <div class="d-lg-flex d-md-flex w-100 justify-content-between align-items-center">
            <!--          Day Picker -->
            <span class="mx-2 text-primary">Today: {{selectedDate}}</span>
            <div class="d-flex">
              <button type="button" class="btn me-1 btn-sm d-flex align-items-center border rounded p-2" @click="showVCalendar = !showVCalendar">
                <i class="fas fa-calendar"></i> <span class="ms-1">Choose Date</span>
              </button>
              <div v-if="showVCalendar" class="date-box position-fixed d-flex shadow flex-column rounded m-0 p-2 text-center" style="background-color: var(--light);">
                <div>
                  <button class="btn btn-close float-end" @click="showVCalendar =!showVCalendar"></button>
                </div>
                <Calendar :attributes="attributes" @dayclick="onDayClick" />
                <div class="d-flex">
                  <button class="rounded btn mx-auto text-white btn-sm w-50 mt-1" style="background-color: var(--primary);" @click="dateSelectHandler">OK</button>
                </div>
              </div>
              <vue-excel-xlsx
                  :data="filterExportData"
                  :columns="columns"
                  :file-name="fileName"
                  :file-type="'xlsx'"
                  :sheet-name="fileName"
                  class="btn btn-success d-flex align-items-center p-2"
              >
                <i class="fas fa-table"></i> <span class="ms-1"></span>
              </vue-excel-xlsx>
            </div>

          </div>
          <div>
            <div class="d-flex align-items-center">
              <div>
                <span class="mx-2 badge bg-secondary rounded-pill" v-for="(day,index) in days" :key="index">{{day.formattedDate}}</span>
              </div>
              <button class="btn btn-warning btn-sm" v-if="days.length>0" @click="clearDays">
                Clear days
              </button>
            </div>
          </div>
        </div>

        <div class="card-body table-responsive">

          <!--            type: {{dateTypes}},{{date}},{{type}}-->
          <table class="table table-card table-bordered table-hover ">
            <thead >
            <tr class="border-bottom-0">
              <th class="text-nowrap">#</th>
              <th class="text-nowrap">Status</th>
              <th class="text-nowrap">User Info</th>
              <th class="text-nowrap">Order Id</th>
              <th class="text-nowrap">Order Items</th>
              <th class="text-nowrap">Total Price</th>
              <th class="text-nowrap">Control</th>
              <th class="text-nowrap">Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order,index) in filterOrders"  :key="index" class="">
              <td class="text-nowrap">
                {{index+1}}
              </td>
              <td class="text-nowrap">
                <span class="badge rounded bg-warning" style="min-width: 100px;font-size: 12px;" v-if="order.orderStatus === 'pending'">Pending</span>
                <span class="badge rounded bg-primary" style="min-width: 100px;font-size: 12px;" v-else-if="order.orderStatus === 'confirmed'">Confirmed</span>
                <span class="badge rounded bg-success" style="min-width: 100px;font-size: 12px;" v-else-if="order.orderStatus === 'paid'">Paid</span>
                <span class="badge rounded  bg-danger" style="min-width: 100px;font-size: 12px;" v-else>Canceled</span>
              </td>
              <td class="text-nowrap">
                <div class="d-flex flex-column">
                  <span class="p-1 mb-1 rounded" style="color:var(--text-color);min-width: 100px;background-color: var(--light); font-size: 12px;"><i class="far fa-user"></i> {{order.user.name}}</span>
                  <a :href="'tel:+959'+order.user.phone" class="p-1 rounded text-decoration-none" style="font-size: 12px;min-width: 100px;color:var(--text-color);background-color: var(--light)"><i class="fas fa-phone"></i>09 {{order.user.phone}}</a>
<!--                  <a :href="'tel:+959'+order.user.phone" class="p-1 rounded text-decoration-none d-none mt-1" style="font-size: 12px;min-width: 100px;color:var(&#45;&#45;text-color);background-color: var(&#45;&#45;light)"><i class="fas fa-phone"></i>09 {{order.user.otherPhone}}</a>-->
                </div>
              </td>
              <td class="text-nowrap">{{order.id}}</td>
              <td class="text-nowrap">
                <div class="d-flex" style="height:70px; overflow-y: scroll; overflow-x: hidden">

                  <ul>
                    <li v-for="(item,index) in order.nameList" :key="index" >
                      {{item}}
                    </li>
                  </ul>
                  <ul>
                    <li v-for="(count,index) in order.countList" :key="index" >
                      x{{count}}
                    </li>
                  </ul>
                </div>
              </td>
              <td class="text-nowrap">{{order.totalPrice}}</td>

              <td class="text-nowrap">
                <router-link :to="{name:'OrderDetails',params:{order_id:order.id}}" class="btn btn-sm" style="background-color: var(--softPrimary);"><i class="fas fa-info-circle"></i></router-link>
              </td>

              <td class="text-nowrap">
                <span>{{order.day}}</span><br>
                <span> {{order.time.toDate().toLocaleTimeString()}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AdminSidebar from "@/components/AdminSidebar";
import db from "@/config/FirebaseInit";
export default {
  props:['type'],
  components:{AdminSidebar},
  data(){
    return{
      selectedDate:'',
      selectedStatus:'pending',
      selectedYear:'',
      showVCalendar : false,
      dateType:'day',
      filterData:[],
      days:[],
      loadingActive:false,
      orders:[],
      user:'',
      search:'',
      fileName:'',
      range: {
        start: new Date(),
        end: new Date()
      },
      columns : [
        {
          label: "Order Id",
          field: "order-id"
        },
        {
          label: "Order Status",
          field: "orderStatus",
        },
        {
          label: "Customer Name",
          field: "customer-name",
        },
        {
          label: "Customer Phone",
          field: "customer-phone",
        },
        {
          label:"Item List",
          field: 'order-items'
        },
        {
          label:"Count List",
          field: 'order-counts'
        },
        {
          label:"Price List",
          field: 'order-prices'
        },
        {
          label:"Total Price",
          field: 'total-price'
        },

        {
          label: "Transaction Image",
          field: "transaction-img",
        },
        {
          label: "Delivery",
          field: "delivery-name",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Time",
          field: "time",
        },
      ],
    }
  },
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },
    currentDay(){
      return this.$store.getters.getDay;
    },
    currentMonth(){
      return this.$store.getters.getMonth;
    },
    currentYear(){
      return this.$store.getters.getYear;
    },
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
    filterOrders(){
      if(this.selectedStatus!=='pending'){
        return this.orders.filter(order=>{
          return order.orderStatus === this.selectedStatus
        })
      }else if(this.search !==''){
        return this.orders.filter(order=>{
          return order.id.includes(this.search)|| order.user.name.includes(this.search) || order.user.phone.includes(this.search)
        })
      }
      else {
        return this.orders;
      }
    },
    filterExportData(){
      if(this.selectedStatus!=='pending'){
        return this.filterData.filter(order=>{
          return order.orderStatus === this.selectedStatus
        })
      }else {
        return this.filterData;
      }
    },
  },

  async mounted(){
    await this.$store.dispatch('getCurrentShop');
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getYear");
    this.fileName=this.currentDay+'-orders';
    this.selectedDate = this.currentDay;
    await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('orderStatus','==',this.selectedStatus).where('statusDay','==',this.selectedDate).orderBy('time','desc').onSnapshot(snapshot => {
      this.orders=[];
      this.filterOrders = [];
      this.filterData=[];
      snapshot.forEach(doc=>{
        // console.log(doc.data().orderUserId)
        db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{

          //console.log(userSnap.data())
          if(userSnap.exists){
            this.orders.push({'user':userSnap.data(),...doc.data()})
            // console.log('User Snap',userSnap.data());
            this.addToFilterOrders(doc.data(), userSnap.data())
          }
        })
      })
    })
    /*if(this.dateTypes==='day'){

    }else if (this.dateTypes ==='month'){
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('month','==',this.date).orderBy('time','desc').onSnapshot(snapshot => {
        this.orders=[];
        this.filterOrders = [];
        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            this.orders.push({'user':userSnap.data(),...doc.data()})
            this.addToFilterOrders(doc.data(), userSnap.data())
          })
        })
        console.log(this.orders)
      })
    }else {
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('year','==',this.date).orderBy('time','desc').onSnapshot(snapshot => {
        this.orders=[];
        this.filterOrders = [];

        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            this.orders.push({'user':userSnap.data(),...doc.data()})
            this.addToFilterOrders(doc.data(), userSnap.data())

          })
        })
      })
    }*/
  },
  methods:{
    clearDays(){
      this.days=[];
      // this.filterOrders=[];
      // this.filterData=[];
      // this.orders=[];
    },
    onChange(e){
      this.search = '';
      this.selectedStatus = e.target.value;
      this.$router.push({name:"OrderList", params:{type:this.selectedStatus}})
    },
    /*async yearChange(){
      let date = new Date(this.selectedYear)
      this.selectedDate = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      this.orders=[];
      this.filterOrders = [];
      this.filterData=[];
      this.fileName=this.selectedDate+'-orders';
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('year','==',this.selectedDate).orderBy('time','desc').onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            this.orders.push({'user':userSnap.data(),...doc.data()})
            this.addToFilterOrders(doc.data(), userSnap.data())
          });
        })
      });
      this.$router.push({name:"OrderList", query:{dateTypes:this.dateType,date:this.selectedDate,type:'All'}})

    },
    async filterMonth(e){
      let date = new Date(e.target.value)
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      this.selectedDate = month+"-"+year;
      ////console.log(this.selectedDate);
      this.orders=[];
      this.filterOrders = [];
      this.filterData=[];
      this.fileName=this.selectedDate+'-orders';
     // console.log('empty',this.filterData)
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('month','==',this.selectedDate).orderBy('time','desc').onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            if(userSnap.exists){
              this.orders.push({'user':userSnap.data(),...doc.data()})
              this.addToFilterOrders(doc.data(), userSnap.data())
            }
            //this.orders.push({'user':userSnap.data(),...doc.data()})
          });

        });
        //console.log('month order',this.orders)
      });
      this.$router.push({name:"OrderList", query:{dateTypes:this.dateType,date:this.selectedDate,type:'All'}})

    },*/
    dateSelectHandler(){
      console.log(this.selectedStatus)
      this.filterData=[];
      this.fileName='orders';
      this.showVCalendar = !this.showVCalendar;
      this.orders = [];
      this.filterOrders = [];
      this.days.forEach(day=>{
        db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('orderStatus','==',this.selectedStatus).where('statusDay','==',day.formattedDate).onSnapshot(snapshot => {
          snapshot.forEach(doc=>{
            db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
              this.orders.push({'user':userSnap.data(),...doc.data()})
              this.addToFilterOrders(doc.data(), userSnap.data())
            });
          })
        })
      })
    },
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id:day.id,
          date:day.date,
          formattedDate:this.formatDate(day.date)
        }
        );
      }
    },
    formatDate(date){
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return  day+"-"+month+"-"+year;
    },
    async showPending(){
      this.days=[];
      this.selectedStatus = 'pending';
      this.orders=[];
      this.filterOrders=[];
      this.filterData=[];
      this.fileName=this.selectedStatus+'-orders';
      this.$router.replace({name:"OrderList", params:{type:this.selectedStatus}});
      await this.getOrderByStatus();
    },
    async showAccepted(){
      this.days=[];
      this.selectedStatus = 'confirmed'
      this.orders=[];
      this.filterOrders=[];
      this.filterData=[];
      this.fileName=this.selectedStatus+'-orders';
      this.$router.push({name:"OrderList", params:{type:this.selectedStatus}})
      await this.getOrderByStatus()
    },
    async showPaid(){
      this.days=[];
      this.orders=[];
      this.filterOrders=[];
      this.filterData=[];
      this.fileName=this.currentYear+'-orders';
      this.selectedStatus = 'paid';
      this.$router.push({name:"OrderList", params:{type:this.selectedStatus}});
      await this.getOrderByStatus()
    },

    async showCancelled(){
      this.days=[];
      this.selectedStatus = 'cancelled'
      this.orders=[];
      this.filterOrders=[];
      this.filterData=[];
      this.fileName=this.selectedStatus+'-orders';
      this.$router.push({name:"OrderList", params:{type:this.selectedStatus}})
      await this.getOrderByStatus()

    },
    async getOrderByStatus(){
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').where('orderStatus','==',this.selectedStatus).where('statusDay','==',this.selectedDate).orderBy('time','desc').onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            this.orders.push({'user':userSnap.data(),...doc.data()})
            this.addToFilterOrders(doc.data(), userSnap.data())
          });
        })
      })
    },
    addToFilterOrders(doc, userSnap){
      const exportData ={
        'order-id':doc.id,
        'orderStatus':doc.orderStatus,
        'customer-name':userSnap.name,
        'customer-phone':userSnap.phone+','+userSnap.otherPhone,
        'order-items':doc.nameList.join(),
        'order-counts':doc.countList.join(),
        'order-prices':doc.sellPriceList.join(),
        'total-price':doc.totalPrice,
        'transaction-img':doc.transactionImg,
        'delivery-name':doc.deliveryServiceName,
        'date':doc.time.toDate().toLocaleDateString(),
        'time':doc.time.toDate().toLocaleTimeString()
      }
      this.filterData.push(exportData)
    },

    /*async acceptOrder(event, order){
      if(event.target.checked){
       // console.log(order.orderUserId)
        await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(order.id).update({
          'orderStatus':'accepted'
        }).then(()=>{
          db.collection('users').doc(order.orderUserId).collection('orders').doc(order.id).update({
            'orderStatus':'accepted'
          });
        })
      }else {

       // console.log(order.orderUserId)

        await db.collection(this.owner.city).doc(this.owner.shopId).collection('orders').doc(order.id).update({
          'orderStatus':'pending'
        }).then(()=>{
          db.collection('users').doc(order.orderUserId).collection('orders').doc(order.id).update({
            'orderStatus':'pending'
          });
        })
      }
    }*/
  }
}
</script>

<style scoped>
.date-box .card-header{
  background-color: white;
  box-shadow: none;
  border: none;
}
.date-box{
  right: 10px;
  top:160px;
}
tr{
  cursor: pointer;
  color: var(--text-color) !important;

}
ol, ul{
  list-style-type: none;
  padding: 0;

}
.table-card{
  margin-top: 130px;
}
table td{
  font-size: 12px;
}
thead, tbody{
  border: 1px solid var(--softPrimary) !important;
  color: var(--dark);
}
li{
  height: 20px;
  border-radius: var(--border-radius);
  background-color: var(--light);
  color: var(--text-color) !important;
  color: var(--dark);
  margin: 2px;
  display: flex;
  align-items: center;
  padding: 2px;
  min-width: 40px;
  text-align: start;
}

/*button group*/

.btn-group .btn{
  border-radius: 0px !important;
}
.check-btn{
  border: 2px solid var(--primary);
  box-shadow: none !important;
  outline: none !important;
  width: 100px;
}
.check-btn:hover{
  background-color: var(--primary);
}
.btn-primary{
  background-color: white ;
  color: black;
}
.btn-primary:focus{
  background-color: var(--primary) !important;
}
.header-bar{
  position: fixed;
  background-color: var(--background-color) ;
  width: inherit;
  z-index: 1000;
  padding: 5px;
  width: 83%;
}
.content-box{
  width: 83%;

  margin-left: 220px;
  margin-top: 120px;
}
@media only screen and (max-width: 1200px) {
  .header-bar {
    width: 85%;
    left: 175px;
  }

  .table-card{
    margin-top: 150px;

  }
}
/*@media only screen and (max-width: 1050px) {
  .header-bar {
    width: 83%;
    margin-top: 50px;
    left: 175px;
  }
  .content-box{
    margin-top: 125px;
  }
}*/
@media only screen and (max-width: 1030px){
  .header-bar {
    width: 78%;
    left: 175px;
  }

  .table-card{
    width: 75%;
    margin-top: 125px;
  }
}
  @media only screen and (max-width: 900px) {
    .date-box{
      z-index: 2000;
    }
  .btn-group{
    width: 100%;
  }
  .header-bar{
    width: 100%;
    left: 1px;
  }
    .date-box{
      left: 10px;
      top:200px;
    }
  .table-card{
    width: 100%;
    margin-left: 0;
    margin-top: 200px;
  }
}
</style>