<template>
  <div v-if="currentShop">
    <LoginModal v-show="loginModal" v-on:close-modal="closeModal" />
    <Loading v-if="loadingActive" />
    <InfoDialog
      v-if="infoModalActive"
      :modal-message="modalMessage"
      v-on:close-modal="closeInfoModal"
    />
    <!--    Loading View-->
    <section
      v-if="initialLoading || products.length == 0"
      class="container-xxl"
      style="background-color: var(--background-color)"
    >
      <div class="row min-vh-100">
        <div class="col-12 my-auto mx-auto">
          <Vue3Lottie :animationData="loading" :height="200" :width="200" />
        </div>
      </div>
    </section>
    <!--    main-->
    <section>
      <div
        class="container-xxl min-vh-100"
        style="background-color: var(--background-color)"
        v-if="currentShop.onlineSell !== 'no'"
      >
        <div
          class="header-bar position-sticky h-auto"
          style="top: 45px; z-index: 11000"
        >
          <div class="row">
            <div
              class="d-lg-flex align-items-center justify-content-between search-bar pt-2"
              :class="{ 'search-bar--hidden': !showSearchBar }"
            >
              <!--              Breadcrumb-->
              <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
                <ol class="breadcrumb align-items-center mt-1">
                  <router-link
                    class="breadcrumb-item"
                    :to="{
                      name: 'ShowShopByType',
                      params: { city: currentShop.city, shop_type: 'All' },
                    }"
                    >Shop</router-link
                  >
                  <router-link
                    class="breadcrumb-item"
                    :to="{
                      name: 'ShopInfo',
                      params: {
                        city: currentShop.city,
                        shop_name: $route.params.shop_name,
                        product_type: 'All',
                      },
                    }"
                    >{{ currentShop.name }}</router-link
                  >
                  <router-link
                    class="breadcrumb-item active"
                    :to="{
                      name: 'ShopInfo',
                      params: {
                        city: currentShop.city,
                        shop_name: $route.params.shop_name,
                        product_type: this.$route.params.product_type
                          .split(' ')
                          .join('-'),
                      },
                    }"
                    >{{
                      this.$route.params.product_type.split("-").join(" ")
                    }}</router-link
                  >
                </ol>
              </nav>
              <div class="d-flex align-items-center justify-content-between">
                <!--              add to cart control btn-->
                <button
                  class="btn justify-content-center text-light align-items-center d-flex me-1 p-2 d-lg-none text-center"
                  v-if="!showCategories"
                  style="background-color: var(--primary); width: 50px"
                  @click="showCategories = !showCategories"
                >
                  <i class="fas fa-filter"></i>
                  <span class="ms-2 d-none">{{
                    $t("message.shop_page_categories")
                  }}</span>
                </button>
                <button
                  class="btn justify-content-center text-light align-items-center d-flex me-1 p-2 d-lg-none text-center"
                  v-else
                  style="background-color: var(--primary); width: 50px"
                  @click="showCategories = !showCategories"
                >
                  <i class="fas fa-times"></i>
                  <span class="ms-2 d-none">{{
                    $t("message.shop_page_categories")
                  }}</span>
                </button>
                <div>
                  <div class="d-flex mt-1 mb-2 d-lg-none">
                    <div class="text-center btn-tab" @click="showProfile">
                      <span class="fs-6">{{ $t("message.shop_profile") }}</span>
                      <div class="active-page-div mt-1" v-if="showHome"></div>
                    </div>
                    <div
                      class="mx-2 rounded"
                      style="width: 2px; background-color: var(--btnLight)"
                    ></div>
                    <div
                      class="text-center btn-tab"
                      @click="showProductContainer"
                    >
                      <span class="fs-6">{{
                        $t("message.shop_products")
                      }}</span>
                      <div class="active-page-div mt-1" v-if="!showHome"></div>
                    </div>
                  </div>
                </div>

                <!--              Search Box-->
                <div
                  class="d-flex align-items-center p-1"
                  style="
                    background-color: var(--light);
                    border-radius: var(--border-radius);
                  "
                >
                  <div>
                    <button
                      class="btn btn-sm"
                      @click="searchShow = true"
                      style="color: var(--text-color)"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                    <div class="search-pop-up shadow p-2" v-if="searchShow">
                      <div
                        class="d-flex rounded align-items-center p-2"
                        style="background-color: var(--light)"
                      >
                        <i class="fas fa-search"></i>
                        <input
                          type="text"
                          @input="searchProductsHandler"
                          v-model="search"
                          :placeholder="$t('message.search')"
                          style="
                            background-color: var(--light);
                            border: 0px !important;
                            box-shadow: none !important;
                          "
                          class="ms-1 form-control border-0"
                        />
                        <button class="btn" @click="searchShow = false">
                          <i class="fas fa-times-circle"></i>
                        </button>
                      </div>

                      <div v-if="searchProducts.length > 0">
                        <router-link
                          v-for="(product, index) in searchProducts"
                          :key="index"
                          class="text-decoration-none text-start"
                          :to="{
                            name: 'ProductInfo',
                            params: {
                              city: currentShop.city,
                              shop_name: currentShop.name.split(' ').join('-'),
                              product_id: product.id,
                            },
                          }"
                        >
                          <div class="d-flex mb-1 border-bottom border-1 py-1">
                            <img
                              :src="product.images[0]"
                              class="img-thumbnail"
                              style="
                                object-fit: cover;
                                width: 50px;
                                height: 50px;
                              "
                            />
                            <div class="d-flex justify-content-between w-100">
                              <div class="d-flex flex-column">
                                <span
                                  class="ms-2"
                                  style="color: var(--text-color-light)"
                                  >{{ product.itemName }}</span
                                >
                                <span
                                  class="ms-2"
                                  style="color: var(--secondary)"
                                  >{{ product.sellPrice }} Ks</span
                                >
                              </div>
                              <button class="btn">
                                <i class="fas fa-chevron-right"></i>
                              </button>
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <div v-else>
                        <p v-if="this.search !== ''" class="my-2 text-center">
                          Sorry! There is no related products.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--    Home Page-->

        <div class="container-xxl pt-5">
          <div class="row" v-if="showHome">
            <div class="col-12 align-items-center">
              <div class="d-flex flex-column text-center">
                <img
                  :src="currentShop.profileUrl"
                  width="200"
                  height="200"
                  class="img-thumbnail rounded mx-auto"
                  alt=""
                />
                <div class="my-2 ms-2">
                  <h1 class="text-black-50 fw-bolder">
                    {{ $t("message.welcome") }}
                  </h1>
                  <h2 class="fw-bolder" style="color: var(--primary)">
                    {{ currentShop.name }}
                  </h2>
                  <div>
                    <span
                      class="badge rounded-pill bg-secondary me-1"
                      v-for="(type, index) in currentShop.shopType"
                      :key="index"
                      >{{ type }}</span
                    >
                  </div>
                  <hr />
                </div>
              </div>
              <div class="d-flex flex-column">
                <h3>{{ $t("message.nav_contact") }}</h3>
                <div class="divider"></div>
                <span class="mb-1"
                  ><i class="fas fa-envelope"></i> {{ currentShop.email }}</span
                >
                <span class="mb-1"
                  ><i class="fas fa-phone"></i> {{ currentShop.phone }}</span
                >
                <span
                  ><i class="fas fa-location-dot"></i>
                  {{ currentShop.address }}</span
                >
              </div>
            </div>
          </div>

          <div class="row p-0" v-else>
            <!--            Sidebar -->
            <div
              class="col-12 col-md-4 p-0 col-lg-2 d-lg-block"
              style="z-index: 10000"
              :hidden="!showCategories"
            >
              <div class="category-box">
                <div class="sidebar-item-header">
                  <span class="fs-6"
                    ><i class="fas fa-bars"></i>
                    {{ $t("message.shop_page_categories") }}</span
                  >
                </div>
                <ul class="text-start d-block list-unstyled">
                  <li
                    :class="
                      this.$route.params.product_type === 'All'
                        ? 'list_active'
                        : ''
                    "
                    class="d-inline-block mx-1"
                    @click="showProduct('All')"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center d-none">
                        <img
                          src="../assets/flash/1.png"
                          class="rounded-circle"
                          alt=""
                        />
                      </div>
                      <span>{{ $t("message.all") }}</span>

                      <i class="fas fa-chevron-right"></i>
                    </div>
                  </li>
                  <li
                    :class="
                      this.$route.params.product_type === 'discount'
                        ? 'list_active'
                        : ''
                    "
                    class="d-inline-block mx-1"
                    @click="showProduct('discount')"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center d-none">
                        <img
                          src="../assets/flash/1.png"
                          class="rounded-circle"
                          alt=""
                        />
                      </div>
                      <span>Discount Items</span>

                      <i class="fas fa-chevron-right"></i>
                    </div>
                  </li>
                  <li
                    v-for="(category, index) in categories"
                    :key="index"
                    :class="
                      this.$route.params.product_type.split('-').join(' ') ===
                      category.name
                        ? 'list_active'
                        : ''
                    "
                    class="d-inline-block mx-1"
                    @click="showProduct(category.name)"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center d-none">
                        <img
                          :src="category.url"
                          v-if="category.url !== 'default'"
                          class="rounded"
                          style="width: 20px; height: 20px; object-fit: cover"
                        />
                        <img
                          src="../assets/flash/1.png"
                          v-else
                          class="rounded-circle"
                          alt=""
                        />
                      </div>
                      <span class="text-truncate">{{ category.name }}</span>

                      <i class="fas fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!--main content-->
            <div
              class="col-12 col-md-12 col-lg-8 scrollli"
              v-if="products.length > 0"
            >
              <div class="row">
                <div class="col-12 p-0 mt-2"></div>
                <!-- Live Sale Box-->
                <div class="col-12">
                  <div
                    v-if="liveSaleActive"
                    class="live-wrapper rounded mx-0 text-center"
                  >
                    <router-link
                      :to="{
                        name: 'LiveSales',
                        params: {
                          city: this.currentShop.city,
                          shop_id: currentShop.name.split(' ').join('-'),
                        },
                      }"
                      ><p class="text-white text-decoration-underline p-1">
                        Live Sales is Starting !!!
                      </p></router-link
                    >
                  </div>
                </div>
                <div class="col-12 p-0">
                  <div class="row" v-if="bestSellerItemList.length > 0">
                    <div class="d-flex align-items-center">
                      <div>
                        <Vue3Lottie
                          :animationData="bestSeller"
                          :height="60"
                          :width="60"
                        />
                      </div>
                      <span class="fs-5" style="color: var(--text-color)">{{
                        $t("message.best_seller")
                      }}</span>
                    </div>
                    <div class="col-12 py-2 rounded m-0 p-1">
                      <div class="scrolling-wrapper pb-3 m-0">
                        <div
                          class="d-inline-block me-2 p-1"
                          style="
                            width: 250px;
                            border-radius: 50px !important;
                            background-color: var(--light);
                          "
                          v-for="(product, index) in bestSellerItemList"
                          :key="index"
                        >
                          <router-link
                            class="text-decoration-none"
                            :to="{
                              name: 'ProductInfo',
                              params: {
                                city: currentShop.city,
                                shop_name: currentShop.name
                                  .split(' ')
                                  .join('-'),
                                product_id: product.id,
                              },
                            }"
                          >
                            <div class="d-flex">
                              <img
                                :src="product.images[0]"
                                v-if="product.images.length > 0"
                                width="80"
                                height="80"
                                class="rounded-circle"
                              />
                              <img
                                src="../assets/flash/1.png"
                                v-else
                                width="80"
                                height="80"
                                class="rounded-circle"
                              />

                              <div class="d-flex flex-column ms-1">
                                <p
                                  class="text-wrap m-1"
                                  style="
                                    color: var(--primary);
                                    width: 90%;
                                    font-weight: bold;
                                    height: 40px;
                                  "
                                >
                                  {{ product.itemName.substring(0, 40)
                                  }}{{
                                    product.itemName.length > 40 ? "..." : ""
                                  }}
                                </p>
                                <span
                                  class="badge fs-3 rounded-circle bg-warning text-white fw-bolder d-flex align-items-center justify-content-center"
                                  style="width: 35px; height: 35px"
                                  >{{ index + 1 }}</span
                                >
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-6 col-md-3 p-0"
                  v-for="product in products"
                  :key="product.id"
                >
                  <ProductItemCard
                    :product="product"
                    :city="currentShop.city"
                    :shop_name="currentShop.name"
                  />
                </div>

                <div class="col-12" v-if="bottomLoadingActive">
                  <div>
                    <Vue3Lottie
                      :animationData="bottomLoading"
                      :height="300"
                      :width="300"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6 col-md-6 mx-auto" v-else>
              <Vue3Lottie :animationData="nodata" :height="300" :width="300" />
              <h1 class="text-center" style="color: var(--text-color)">
                Sorry! There is no product currently.
              </h1>
            </div>

            <div
              class="col-12 col-md-2 mt-3 d-lg-block order-0"
              :class="showHome === 'true' ? 'd-block' : 'd-none'"
            >
              <div class="position-fixed shop-side-box">
                <img
                  :src="currentShop.profileUrl"
                  class="img-thumbnail"
                  style="
                    width: 200px;
                    height: 200px;
                    border-radius: var(--border-radius);
                    object-fit: cover;
                  "
                  alt=""
                />
                <div class="my-2" style="color: var(--text-color)">
                  <h5 class="text-black-50">{{ $t("message.welcome") }}</h5>
                  <h5 style="color: var(--primary)">{{ currentShop.name }}</h5>
                  <div>
                    <span
                      class="badge rounded-pill bg-secondary me-1"
                      v-for="(type, index) in currentShop.shopType"
                      :key="index"
                      >{{ type }}</span
                    >
                  </div>
                  <div class="d-flex flex-column">
                    <h3>{{ $t("message.nav_contact") }}</h3>
                    <div class="divider"></div>
                    <span
                      ><i class="fas fa-envelope"></i>
                      {{ currentShop.email }}</span
                    >
                    <span
                      ><i class="fas fa-phone"></i>{{ currentShop.phone }}</span
                    >
                    <span
                      ><i class="fas fa-location-dot"></i>
                      {{ currentShop.address }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xxl" v-else>
        <div class="row min-vh-100 align-items-center justify-content-center">
          <div class="col-12 col-lg-6 col-md-6 mx-auto">
            <Vue3Lottie :animationData="nodata" :height="300" :width="300" />

            <h1 class="text-center" style="color: var(--text-color)">
              Sorry! The shop is temporarily closed now.
            </h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { debounce } from "lodash";

const nodata = require("../assets/lotties/no-item-blue.json");
const loading = require("../assets/lotties/newLoading.json");
const bestSeller = require("../assets/lotties/BestSeller.json");
const bottomLoading = require("../assets/lotties/loading.json");
import InfoDialog from "@/components/InfoDialog";
import db from "../config/FirebaseInit";
import Loading from "@/components/Loading";
import ProductItemCard from "@/components/ProductItemCard";
// import AddToCartBar from "@/components/AddToCartBar";
import LoginModal from "@/components/LoginModal";
export default {
  components: { LoginModal, ProductItemCard, InfoDialog, Loading },
  //props:['city','shop_name','product_type'],
  data() {
    return {
      searchShow: false,
      bestSeller,
      nodata,
      bottomLoading,
      bottomLoadingActive: false,
      initialLoading: false,
      loading,
      showHome: false,
      showCategories: false,
      discountProducts: [],
      loadingActive: false,
      currentShop: "",
      showSearchBar: true,
      lastScrollPosition: 0,
      payment_methods: [],
      firstCategoryProduct: [],
      secondCategoryProduct: [],
      searchProducts: [],
      bestSellerIdList: "",
      bestSellerItemList: [],
      categories: [],
      products: [],
      orders: [],
      totalPrice: 0,
      cartOrders: [],
      selectedCategory: "",
      infoModalActive: false,
      modalMessage: "",
      liveSaleActive: false,
      loginModal: false,
      disableBtn: false,

      //paginate
      lastProduct: null,
      paginate_limit: 18,
      message: "",
      search: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  async mounted() {
    // console.log(this.getLastMonthOfThisYear());
    this.initialLoading = true;
    window.addEventListener("scroll", this.onScroll);
    this.$store.dispatch("getCartInfo");
    //this.$store.dispatch("getCartItems");
    await db
      .collection(this.$route.params.city)
      .where("name", "==", this.$route.params.shop_name.split("-").join(" "))
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          this.currentShop = doc.data();
          console.log("current shop", this.currentShop);
        });

        db.collection(this.currentShop.city)
          .doc(this.currentShop.id)
          .collection("bestSeller")
          .doc(this.getLastMonthOfThisYear())
          .onSnapshot((querySnapshot) => {
            if (querySnapshot.exists) {
              this.bestSellerIdList = querySnapshot.data().productIdList;
              this.bestSellerIdList.forEach((id) => {
                db.collection(this.currentShop.city)
                  .doc(this.currentShop.id)
                  .collection("products")
                  .doc(id)
                  .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                      // console.log(snapshot.data())
                      this.bestSellerItemList.push(snapshot.data());
                    }
                  });
              });
            }
          });

        if (this.currentShop.onlineSell !== "no") {
          if (this.$route.params.product_type === "All") {
            db.collection(this.currentShop.city)
              .doc(this.currentShop.id)
              .collection("products")
              .limit(this.paginate_limit)
              .onSnapshot((querySnapshot) => {
                this.products = [];
                querySnapshot.forEach((doc) => {
                  this.products.push(doc.data());
                });
                if (querySnapshot.size === this.products.length) {
                  setTimeout(() => {
                    this.initialLoading = false;
                  }, 1500);
                }
                this.lastProduct =
                  querySnapshot.docs[querySnapshot.docs.length - 1];
                //console.log(this.lastProduct)
              });
          } else if (this.$route.params.product_type === "discount") {
            db.collection(this.currentShop.city)
              .doc(this.currentShop.id)
              .collection("products")
              .where("discount", ">", "0")
              .limit(this.paginate_limit)
              .onSnapshot((querySnapshot) => {
                this.discount = [];
                if (querySnapshot.exists) {
                  querySnapshot.forEach((doc) => {
                    this.products.push(doc.data());
                  });
                  if (querySnapshot.size === this.products.length) {
                    this.initialLoading = false;
                  }
                  this.lastProduct =
                    querySnapshot.docs[querySnapshot.docs.length - 1];
                }
              });
          } else {
            db.collection()
              .doc(this.currentShop.id)
              .collection("products")
              .where(
                "type",
                "==",
                this.$route.params.product_type.split("-").join(" ")
              )
              .limit(this.paginate_limit)
              .onSnapshot((snapshot) => {
                this.products = [];
                snapshot.forEach((doc) => {
                  if (!this.products.includes(doc.data())) {
                    this.products.push(doc.data());
                  }
                });
                if (snapshot.size === this.products.length) {
                  this.initialLoading = false;
                }
                this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
              });
          }

          db.collection(this.currentShop.city)
            .doc(this.currentShop.id)
            .collection("liveSession")
            .onSnapshot((snapshot) => {
              snapshot.forEach((doc) => {
                if (doc.data().status === "start") {
                  this.liveSaleActive = true;
                }
              });
            });

          db.collection(this.currentShop.city)
            .doc(this.currentShop.id)
            .collection("products")
            .where("discount", "!=", "0")
            .limit(5)
            .onSnapshot((querySnapshot) => {
              this.discountProducts = [];
              querySnapshot.forEach((doc) => {
                this.discountProducts.push(doc.data());
              });
            });
          db.collection(this.currentShop.city)
            .doc(this.currentShop.id)
            .collection("category")
            .get()
            .then((snapshot) => {
              this.categories = [];
              snapshot.forEach((doc) => {
                if (doc.data().name !== "All") {
                  this.categories.push(doc.data());
                }
              });

              if (this.categories.length > 0) {
                db.collection(this.currentShop.city)
                  .doc(this.currentShop.id)
                  .collection("products")
                  .where("type", "==", this.categories[0].name)
                  .limit(5)
                  .onSnapshot((querySnapshot) => {
                    this.firstCategoryProduct = [];
                    querySnapshot.forEach((doc) => {
                      this.firstCategoryProduct.push(doc.data());
                    });
                  });
              }
              if (this.categories.length > 1) {
                db.collection(this.currentShop.city)
                  .doc(this.currentShop.id)
                  .collection("products")
                  .where("type", "==", this.categories[1].name)
                  .limit(5)
                  .onSnapshot((querySnapshot) => {
                    this.secondCategoryProduct = [];
                    querySnapshot.forEach((doc) => {
                      this.secondCategoryProduct.push(doc.data());
                    });
                  });
              }
            });
        }
      });
    await this.loadMore();
  },

  methods: {
    searchProductsHandler: debounce(function (e) {
      if (e.target.value) {
        db.collection(this.currentShop.city)
          .doc(this.currentShop.id)
          .collection("products")
          .where("itemName", ">=", e.target.value)
          .where("itemName", "<=", e.target.value + "\uf8ff")
          .limit(20)
          .onSnapshot((snapshot) => {
            this.searchProducts = [];
            snapshot.docs.filter((e) => {
              const isDuplicate = this.searchProducts.includes(e.id);
              if (!isDuplicate) {
                this.searchProducts.push(e.data());
                console.log(this.searchProducts);
                return true;
              }
              return false;
            });
          });
      } else {
        this.searchProducts = [];
      }
    }, 500),

    getLastMonthOfThisYear() {
      const date = new Date();
      const lastMonth = date.getMonth() - 1;
      date.setMonth(lastMonth);
      const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
        date
      );
      const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        date
      );
      return month + "-" + year;
    },

    showProfile() {
      this.showHome = true;
      window.scrollTo(0, 0);
    },

    showProductContainer() {
      this.showHome = false;
      window.scrollTo(0, 0);
    },

    async loadMore() {
      window.onscroll = () => {
        /*  console.log('scroll',document.documentElement.scrollTop + window.innerHeight + 10);
          console.log('offset height',document.documentElement.offsetHeight)*/
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 10 >
          document.documentElement.offsetHeight;
        // console.log(bottomOfWindow)
        if (bottomOfWindow) {
          if (this.$route.params.product_type === "All") {
            db.collection(this.currentShop.city)
              .doc(this.currentShop.id)
              .collection("products")

              .startAfter(this.lastProduct)
              .limit(this.paginate_limit)
              .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                  if (this.products.some((p) => p.id !== doc.data().id)) {
                    this.products.push(doc.data());
                  }
                });
                this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
              });
            //console.log(this.lastProduct.)
          } else if (this.$route.params.product_type === "discount") {
            db.collection(this.currentShop.city)
              .doc(this.currentShop.id)
              .collection("products")
              .where("discount", "!=", "0")
              .startAfter(this.lastProduct)
              .limit(this.paginate_limit)
              .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                  if (this.products.some((p) => p.id !== doc.data().id)) {
                    this.products.push(doc.data());
                  }
                });
                this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
              });
          } else {
            db.collection(this.currentShop.city)
              .doc(this.currentShop.id)
              .collection("products")
              .where(
                "type",
                "==",
                this.$route.params.product_type.split("-").join(" ")
              )

              .startAfter(this.lastProduct)
              .limit(this.paginate_limit)
              .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                  if (this.products.some((p) => p.id !== doc.data().id)) {
                    this.products.push(doc.data());
                  }
                });
                this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
              });
          }
          // ...
        }
      };
    },

    getRandomStr() {
      const chars = "1234567890";
      let result = "";
      for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },

    async showProduct(category) {
      this.search = "";
      if (this.currentShop.onlineSell !== "no") {
        this.showHome = false;
        this.showCategories = false;
        this.selectedCategory = category;
        if (category === "All") {
          // this.products=this.products.filter((product)=>product.type==='all');
          await db
            .collection(this.currentShop.city)
            .doc(this.currentShop.id)
            .collection("products")
            .limit(this.paginate_limit)
            .onSnapshot((snapshot) => {
              this.products = [];
              snapshot.forEach((doc) => {
                this.products.push(doc.data());
              });
              this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
            });
        } else if (category === "discount") {
          await db
            .collection(this.currentShop.city)
            .doc(this.currentShop.id)
            .collection("products")
            .where("discount", "!=", "0")
            .limit(this.paginate_limit)
            .onSnapshot((snapshot) => {
              this.products = [];
              if (snapshot.exists) {
                snapshot.forEach((doc) => {
                  this.products.push(doc.data());
                });
                this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
              }
            });
        } else {
          // this.products = this.products.filter((product)=>product.type===category);
          await db
            .collection(this.currentShop.city)
            .doc(this.currentShop.id)
            .collection("products")
            .where("type", "==", category)
            .limit(this.paginate_limit)
            .onSnapshot((snapshot) => {
              this.products = [];

              snapshot.forEach((doc) => {
                this.products.push(doc.data());
              });
              this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
            });
        }
        this.$router.replace({
          name: "ShopInfo",
          params: {
            city: this.currentShop.city,
            shop_name: this.$route.params.shop_name,
            product_type: category.split(" ").join("-"),
          },
        });
      }
    },

    closeModal() {
      this.loadingActive = false;
      this.loginModal = !this.loginModal;
    },

    closeInfoModal() {
      this.infoModalActive = !this.infoModalActive;
    },

    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showSearchBar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
<style scoped>
li img {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.search-pop-up {
  border-radius: var(--border-radius);
  background-color: var(--white);
  height: 60vh;
  width: 500px;
  z-index: 10000;
  top: 100px;
  position: fixed;
  right: 100px;
  overflow-y: scroll;
}
@media screen and (max-width: 500px) {
  .search-pop-up {
    right: 0;
    top: 0;
    margin: auto;
    width: 100%;
    height: 100vh;
  }
}
.sidebar-item-header {
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}
body {
  overflow-x: hidden;
}
.shop-side-box {
  width: 200px;
}

.scrolling-wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
li {
  cursor: pointer;
  color: var(--text-color);
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  width: 90%;
  height: inherit;
  border-bottom: 1px solid var(--light);
  transition: 0.3s;
}
li img {
  width: 20px;
  height: 20px;
}
li:hover,
li:focus {
  background-color: var(--light);
}
.btn-tab {
  cursor: pointer;
}
.btn-tab:hover {
  color: var(--primary);
}
.active-page-div {
  cursor: pointer;
  border-bottom: 3px solid var(--primary);
  border-radius: var(--border-radius);
}

.list_active {
  /*background-color: var(--light);*/
  color: var(--secondary) !important;
  font-weight: bold;
}

.category-box {
  padding-top: 20px;
  margin-bottom: 100px;
  background-color: var(--white);
  backdrop-filter: blur(3px);
  height: 90%;
  width: 200px;
  position: fixed;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
}

body::-webkit-scrollbar {
  width: 0.4em;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light);
  outline: 1px solid var(--softPrimary);
}

/*animated gradient*/
.live-wrapper {
  width: 100%;
  background: linear-gradient(
    45deg,
    #f82929,
    #ff4343,
    #c42121,
    #f60202,
    #f82929,
    #f82929
  );
  background-size: 600% 100%;
  animation: gradient 16s linear infinite;
  animation-direction: alternate;
}
.breadcrumb-item {
  text-decoration: none;
  cursor: pointer;
}
.divider {
  margin-top: 10px;
  margin-bottom: 10px;

  width: 200px;
  background-color: var(--secondary);
  height: 3px;
  border-radius: var(--border-radius);
}
@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.search-bar {
  border-bottom: 1px solid var(--light);
  color: var(--text-color);
  background-color: var(--background-color);
}

@media screen and (max-width: 900px) {
  .category-box.search-bar--hidden {
    top: 100px;
  }
  .category-box {
    margin-top: 1px;
    width: 360px;
    padding-top: 50px;
  }
}
@media only screen and (max-width: 500px) {
  .header-bar {
    position: fixed;
    width: 100%;
    padding: 5px;
  }
}
@media only screen and (max-width: 1024px) {
  .category-box {
    width: 150px;
  }
  .shop-side-box {
    width: 150px;
  }
}
@media only screen and (max-width: 850px) {
  li img {
    width: 18px;
    height: 18px;
  }
  .search-bar {
    width: 100%;
    padding: 5px;
    transform: translate3d(0, 0, 0);
    transition: 0.1s all ease-out;
  }
  .search-bar.search-bar--hidden {
    box-shadow: none;
    transform: translate3d(0, -100%, 0);
  }
  .category-box.search-bar--hidden {
    top: 100px;
  }
  .category-box {
    width: inherit;
    padding: 10px;
  }
}
</style>
